import styled from 'styled-components';

const TestimonialStyled = styled.div`
  display: flex;
  background: #fbf1e6;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 800px) {
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    overflow: hidden;
  }

  .container {
    width: 100%;
    display: flex;
    max-width: 1285px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;

    .testimonial-section {
      display: flex;
      width: 100%;
      max-width: 960px;
      flex-direction: column;
      row-gap: 120px;
      margin-top: 120px;
      padding-bottom: 80px;

      @media (max-width: 800px) {
        margin-top: 40px;
        row-gap: 80px;
      }

      .testimonial-row {
          display: flex;
          column-gap: 120px;
          
          &:nth-child(2n) {
              flex-direction: row-reverse;
          }

          @media (max-width: 800px) {
              flex-direction: column;
              align-items: center;
              row-gap: 40px;

              &:nth-child(2n) {
                  flex-direction: column;
              }
          }
          
          .quote-container {
            display: flex;
            row-gap: 40px;
            width: 100%;
            justify-content: center;
            flex-direction: column;

              .image-container {
                  width: 160px;
                  height: 160px;
                  position: relative;

                  .image {
                      width: 160px;
                      height: 160px;
                      border-radius: 50%;
                      object-fit: cover;
                  }
              }
              
              .text {
                  display: flex;
                  flex-direction: column;
                  row-gap: 24px;
              }

              svg {
                  top: 0;
                  right: -40px;
                  width: 80px;
                  height: 80px;
                  transform: rotate(-16deg);
                  position: absolute;
              }
          }
          
          .video-container {
              width: 100%;
              max-width: 464px;
              flex: 0 0 auto;
              display: flex;
              height: auto;
              aspect-ratio: 4/5;
              border-radius: 10px;
              overflow: hidden;
          }
      }
    }
  }
`;

const LeftArrow = styled.img`
  width: 42px !important;
  position: absolute !important;
  left: -20px;
  @media (min-width: 800px) {
    display: none;
  }
`;

const RightArrow = styled.img`
  right: -20px;
  width: 42px !important;
  position: absolute !important;
  @media (min-width: 800px) {
    display: none;
  }`;

export { TestimonialStyled, RightArrow, LeftArrow };
