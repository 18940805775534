import React from 'react';
import Header from '../Header';
import { TestimonialStyled } from './style';
import VimeoPlayer from '../VimeoPlayer';
import michael from '../../assets/testimonials/michael.jpg';
import brandon from '../../assets/testimonials/brandon.jpg';
import hjordys from '../../assets/testimonials/hjordys.jpg';
import marcus from '../../assets/testimonials/marcus.jpg';
import { ReactComponent as Badge } from '../../assets/testimonials/badge.svg';

function Testimonials() {
  const testimonials = [
    {
      author: 'Michael R.',
      photo: michael,
      video: 'https://player.vimeo.com/video/947520167',
      quote: '“The club makes so many fantastic single barrel picks, and I have not been disappointed by any of them.”',
    },
    {
      author: 'Brandon W.',
      photo: brandon,
      video: 'https://player.vimeo.com/video/947521285',
      quote: '“The Prime Barrel is the first club where I feel I have made real personal connections and true friends.”',
    },
    {
      author: 'Hjordys P.',
      photo: hjordys,
      video: 'https://player.vimeo.com/video/947522097',
      quote: '“The club made me feel like family!”',
    },
    {
      author: 'Marcus M.',
      photo: marcus,
      video: 'https://player.vimeo.com/video/947528174',
      quote: '“A super unpretentious and inclusive club. It\'s a great place to learn about all forms of whiskey that you might not have known about before.”',
    },
  ];

  return (
    <TestimonialStyled id='Testimonials'>
      <div className='container'>
        <Header
          headingOne='Testimonials'
          headingTwo='What Real People Are Saying'
          rowGap='25px'
          headerWidth='100%'
          headerMaxWidth='940px'
        />
        <div className='testimonial-section'>
          {testimonials.map((user) => (
            <div className='testimonial-row' key={user.author}>
              <div className='quote-container'>
                <div className='image-container'>
                  <div className='image'>
                    <img
                      src={user.photo}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <Badge />
                </div>
                <div className='text ff-CG fw-400'>
                  <span>{user.quote}</span>
                  <span className='fw-700 ff-BO'>{user.author}</span>
                </div>
              </div>
              <div className='video-container'>
                <VimeoPlayer videoId={user.video} config={{ controls: false }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </TestimonialStyled>
  );
}

export default Testimonials;
