import { createContext, useContext } from 'react';
import WalletStore from './wallet/wallet-store';
import ContractStore from './contract/contract-store';

class RootStore {
  constructor() {
    this.walletStore = new WalletStore(this);
    this.contractStore = new ContractStore(this);
  }
}

const rootStore = new RootStore();

export default {
  walletStore: rootStore.walletStore,
  contractStore: rootStore.contractStore,
};

export const StoreContext = createContext(rootStore);

export const useStore = () => useContext(StoreContext);
