export default [
  {
    item: 1,
    name: 'tier-one-plus',
    title: 'Tier 1 Plus: Single Barrel Savant +',
    presalePrice: 10000,
    regularPrice: 11500,
    availability: 7,
    description: 'You’ll be one of only seven people to pick a first-ever New Riff 9-Year-Old Bourbon barrel AND Four Roses 13-16 Year Old Bourbon barrel. One bottle from each selection will be yours to keep. 3 days/2 nights of luxury lodging, meals, and transportation are also included. Oh, and did we mention you still get all of the benefits of our Tier 1?',
  },
  {
    item: 2,
    name: 'tier-one',
    title: 'Tier 1: Single Barrel Savant',
    presalePrice: 1275,
    regularPrice: 1500,
    availability: 193,
    description: 'Our premium tier includes the best club has to offer, including an exclusive Sagamore Spirits single barrel bottle at launch, first access to new drops, chance to select barrels with us in person and virtually, spot in allocated bottle randomizer, and much more.',
  },
  {
    item: 3,
    name: 'tier-two',
    title: 'Tier 2: Single Barrel Advocate',
    presalePrice: 625,
    regularPrice: 750,
    availability: 1800,
    description: 'Our premium tier includes the best club has to offer, including an exclusive Sagamore Spirits single barrel bottle at launch, first access to new drops, chance to select barrels with us in person and virtually, spot in allocated bottle randomizer, and much more.',
  },
];
