import { makeAutoObservable } from 'mobx';
import Swal from 'sweetalert2';
import { isJoined } from '../../service/api';

export default class WalletStore {
  rootStore;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  currentProvider = null;

  chainId = '0x1';

  userAccount = null;

  isWaitListed = false;

  setIsWaitListed = (value) => {
    this.isWaitListed = value;
  };

  setUserAccount = (value) => {
    this.userAccount = value;
  };

  setChainId = (value) => {
    this.chainId = value;
  };

  onConnect = async ({ chainId }) => {
    if (this.currentProvider?.isConnected()) {
      const accounts = await this.currentProvider.request({
        method: 'eth_requestAccounts',
      });
      this.onAccountsChanged(accounts);
      this.onChainChanged(chainId);
    }
  };

  onAccountsChanged = (accounts) => {
    if (accounts && accounts.length > 0) {
      this.setUserAccount(accounts[0]);
      this.isUserJoined(accounts[0]);
    } else {
      this.setUserAccount(null);
    }
  };

  onChainChanged = (newChainId) => {
    this.setChainId(newChainId);
  };

  connectToWallet = async (provider) => {
    if (provider.name === 'metamask') {
      try {
        await provider.wallet.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: this.chainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          // eslint-disable-next-line no-useless-catch
          try {
            await provider.wallet.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x1',
                  chainName: 'Ethereum Network',
                  nativeCurrency: {
                    name: 'Ether',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  rpcUrls: ['https://rpc.ankr.com/eth_rinkeby'],
                  blockExplorerUrls: ['https://rinkeby.etherscan.io/'],
                },
              ],
            });
          } catch (addError) {
            throw addError;
          }
        } else {
          throw switchError;
        }
      }

      const accounts = await provider.wallet.request({
        method: 'eth_requestAccounts',
      });
      this.setUserAccount(accounts[0]);
      this.isUserJoined(accounts[0]);
    } else if (provider.name === 'coinbase') {
      try {
        await provider.wallet.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: this.chainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          // eslint-disable-next-line no-useless-catch
          try {
            await provider.wallet.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x1',
                  chainName: 'Ethereum Network',
                  nativeCurrency: {
                    name: 'Ether',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  rpcUrls: ['https://rpc.ankr.com/eth_rinkeby'],
                  blockExplorerUrls: ['https://rinkeby.etherscan.io/'],
                },
              ],
            });
          } catch (addError) {
            throw addError;
          }
        } else {
          throw switchError;
        }
      }
      const accounts = await provider.wallet.request({
        method: 'eth_requestAccounts',
        params: [{ chainId: this.chainId }],
      });
      this.setUserAccount(accounts[0]);
      this.isUserJoined(accounts[0]);
    } else {
      Swal.fire({
        text: 'Please install Metamask or Coinbase',
        icon: 'error',
        title: '',
      });
    }
  };

  isUserJoined = async (address) => {
    const resp = await isJoined(address);
    if (resp.status === 200 && resp.data.exist) {
      this.setIsWaitListed(true);
    }
  };
}
