import styled from 'styled-components';

const NavStyle = styled.div`
  display: flex;
  padding: 20px 105px 20px 26px;
  justify-content: space-between;
  align-items: center;

  .logo-wrapper {
    width: 116px;
    height: 116px;
    padding-left: 0px;
  }

  .logo-image {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    width: 70px;
    height: 40px;
    cursor: pointer;
  }

  .bar-top,
  .bar-middle,
  .bar-bottom {
    width: 100%;
    height: 7px;
    background: #fd7835;
    border-radius: 5px;
    margin: 3px 0;
    transition: all 300ms ease-in;
  }

  .bar-top-rotated {
    transition: all 300ms ease-in;
    transform: rotate(45deg) translateX(9px) translateY(9px);;
  }

  .bar-bottom-rotated {
    transition: all 300ms ease-in;
    transform: rotate(135deg);
  } 

  .bar-top {
    margin-bottom: 5px;
  }

  .bar-middle {
    margin-bottom: 5px;
  }

  .drop-down-menu {
    width: 280px;
    position: fixed;
    top: 0px;
    right: 0px;
    padding: 50px 21px 28px 20px;
    background: #fbf1e6;
    box-shadow: 0px 12px 12px rgba(12, 36, 51, 0.5);
    z-index:10;

    .close-btn {
      position: absolute;
      width: 37.8px;
      height: 37.8px;
      left: 9px;
      top: 11.67px;
      background: #fd7835;
      box-shadow: 0px 5.60018px 5.60018px rgb(10 48 71 / 50%);
      border: none;
      border-radius: 50%;
      color: #ffffff;
      font-family: 'Century Gothic';
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;

      .x-text {
        position: relative;
        transform: scale(1.4);
        font-size: 18px;
      }
    }

    .drop-down-links {
      width: 240px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 275%;
      text-align: center;
      color: #4c0d18;

      a {
        font-family: 'Century Gothic BO';
        color: #4c0d18;
        text-decoration: none;
      }

      .underline {
        border-bottom: 2px solid #4c0d18;
      }
    }
  }

  }


  @media screen and (max-width: 800px) {
    height: auto;
    padding: 20px 20px 20px 26px;


    .logo-wrapper {
      width: 87px;
      height: 87px;
      padding-left: 0px;
    }
  
    .logo-image {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }

    .wallet-btn button {
      font-size: 12px;
    }
  
    .hamburger-menu {
      width: 50px;
    }
  
    .bar-top,
    .bar-middle,
    .bar-bottom {
      width: 100%;
      border-radius: 5px;
      margin: 3px 0;
    }

    .drop-down-menu {
      padding-top: 10px;
      width: 100%;
      top: 127px;
      left: 0px;
      box-shadow: none;
  
      .close-btn {
        display: none;
      }
  
      .drop-down-links {
        width: 100%;
      }
    }
  
    }
  }
`;

export default NavStyle;
