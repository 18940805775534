import React from 'react';
import PopUpHeaderStyled from './style';

function PopUpHeader({
  headingOne,
  headingTwo,
  rowGap,
  alignItems,
  bottomTextAlign,
  borderBottom,
}) {
  return (
    <PopUpHeaderStyled
      rowGap={rowGap}
      alignItems={alignItems}
      bottomTextAlign={bottomTextAlign}
      borderBottom={borderBottom}
    >
      <>
        <div className='top-text'>{headingOne}</div>
        <div className='yellow-line' />
        <div
          className='bottom-text'
          style={{
            width:
              headingTwo === 'Please Review Your Selections:' ? '365px' : '',
          }}
        >
          {headingTwo}
        </div>
      </>
    </PopUpHeaderStyled>
  );
}

export default PopUpHeader;
