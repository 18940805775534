/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import SagaMoreStyled from './style';
import FourRosesLogo from '../../assets/reelVideo/four-roses-logo.png';
import { ReactComponent as NewRiffLogo } from '../../assets/reelVideo/new-riff.svg';
import { ReactComponent as PlayIcon } from '../../assets/reelVideo/play.svg';

import { OuterCarousel } from './style';
import Glide from '@glidejs/glide';
import { ReactComponent as Arrow } from '../../assets/carousel-arrow.svg';

import FourRoses1 from '../../assets/reelVideo/fr-images/four-roses-01.jpeg';
import FourRoses2 from '../../assets/reelVideo/fr-images/four-roses-02.jpeg';
import FourRoses3 from '../../assets/reelVideo/fr-images/four-roses-03.jpeg';
import FourRoses4 from '../../assets/reelVideo/fr-images/four-roses-04.jpeg';
import FourRoses5 from '../../assets/reelVideo/fr-images/four-roses-05.jpeg';
import FourRoses6 from '../../assets/reelVideo/fr-images/four-roses-06.jpeg';
import FourRoses7 from '../../assets/reelVideo/fr-images/four-roses-07.jpeg';
import FourRoses8 from '../../assets/reelVideo/fr-images/four-roses-08.jpeg';
import FourRoses9 from '../../assets/reelVideo/fr-images/four-roses-09.jpeg';

import NewRiff1 from '../../assets/reelVideo/nr-images/new-riff-01.jpeg';
import NewRiff2 from '../../assets/reelVideo/nr-images/new-riff-02.jpeg';
import NewRiff3 from '../../assets/reelVideo/nr-images/new-riff-03.jpeg';
import NewRiff4 from '../../assets/reelVideo/nr-images/new-riff-04.jpeg';

function ReelVideo() {
  const [onePlayed, setOnePlayed] = useState(false);
  const [twoPlayed, setTwoPlayed] = useState(false);

  const fourRosesImages = [FourRoses1, FourRoses2, FourRoses3, FourRoses4, FourRoses5, FourRoses6, FourRoses7, FourRoses8, FourRoses9];
  const newRiffImages = [NewRiff1, NewRiff2, NewRiff3, NewRiff4];

  const sliderConfiguration = {
    gap: 70,
    perView: 2,
    startAt: 0,
    autoplay: 3000,
    type: 'carousel',
    breakpoints: {
      800: {
        perView: 1,
      },
    },
  };

  const slider = new Glide('.four-roses-carousel', sliderConfiguration);
  const sliderTwo = new Glide('.new-riff-carousel', sliderConfiguration);

  useEffect(() => {
    slider.mount();
    sliderTwo.mount();

    return () => {
      slider.destroy();
      sliderTwo.destroy();
    };
  }, [slider]);

  // const detailArray = [
  //   {
  //     heading: 'Sagamore Spirits NFT Barrel Selection',
  //     description:
  //       'Earlier this year, we visited the beautiful grounds of Sagamore Spirits to pick the barrel for this project. When you buy our membership, you will receive a complimentary bottle of an exclusive collaboration bottling between The Prime Barrel and Sagamore Spirits!',
  //     thumbNail: ReelVideo,
  //     videoUrl: 'https://www.youtube.com/embed/PiO4cREBO84',
  //   },
  //   {
  //     heading: 'Knob Creek Bourbon & Rye Barrel Selections',
  //     description:
  //       'This is one of many trips we took in 2022 to select single barrels in person at multiple distilleries. We always involve our members, and this perk will be available to Tier 1 and Tier 1 PLUS members!',
  //     thumbNail: ReelVideo,
  //     videoUrl: 'https://www.youtube.com/embed/t9adnd2dmlE  ',
  //   },
  // ];

  // const handleVideoPlay = (event) => {
  //   const customPlayButton = event.target.previousElementSibling;
  //   const video = event.target;

  //   const showPlayButton = () => {
  //     customPlayButton.style.visibility = 'visible';
  //     customPlayButton.style.opacity = '1';
  //   };

  //   const hidePlayButton = () => {
  //     customPlayButton.style.visibility = 'hidden';
  //     customPlayButton.style.opacity = '0';
  //   };

  //   video.removeEventListener('ended', showPlayButton);
  //   video.addEventListener('ended', showPlayButton);

  //   if (video.paused) {
  //     hidePlayButton();
  //     video.play();
  //   } else {
  //     showPlayButton();
  //     video.pause();
  //   }
  // };

  return (
    <SagaMoreStyled>
      <div className='container'>
        <div className='section'>
          <div className='content-wrapper'>
            <div className='section-left'>
              <div className='desc'>
                <div className='heading'>
                  Four Roses 16 Year Old Charity Barrel Selection
                </div>
                <div className='text'>
                  We had the honor of selecting two 16-Year-Old Charity Barrels, with proceeds
                  supporting victims of the 2022 Eastern KY floods and tornadoes. Receive a
                  complimentary bottle of this exceptional whiskey when you purchase our membership.
                </div>
              </div>
              <a className='find-more' href='#Benifits-Pricing'>
                Find Out More
              </a>
            </div>
            <div className='section-right'>
              {/* <img src={PlayIcon} className='controls' alt='' /> */}
              {onePlayed ? (
                <iframe
                  width='508'
                  height='508'
                  src='https://www.youtube.com/embed/T37f0LPyOVM?autoplay=1'
                  title='Sagamore Spirits Farm Visit and Barrel Selection'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              ) : (
                <div className='play-image-wrapper' onClick={() => setOnePlayed(true)}>
                  <img src={FourRosesLogo} alt='' width='350px' />
                  <PlayIcon className='play-icon' />
                </div>
              )}
            </div>
          </div>
          <OuterCarousel>
            <div className='glide four-roses-carousel'>
              <div className='glide__track' data-glide-el='track'>
                <ul className='glide__slides'>
                  {fourRosesImages.map((image, index) => (
                    <li className='glide__slide' key={`four-roses-experience-img-${index}`}>
                      <img src={image} loading='lazy' className='image-container' alt='' />
                    </li>
                  ))}
                </ul>
              </div>
              <div className='glide__arrows' data-glide-el='controls'>
                <button className='glide__arrow glide__arrow--left' data-glide-dir='<'><span
                  className='visually-hidden'>Previous</span><Arrow /></button>
                <button className='glide__arrow glide__arrow--right' data-glide-dir='>'><span
                  className='visually-hidden'>Next</span><Arrow />
                </button>
              </div>
              <div className='glide__bullets' data-glide-el='controls[nav]'>
                {fourRosesImages.map((_, index) => (
                  <button className='glide__bullet' aria-label={`slide number ${index+1}`} key={`four-roses-experience-bullet-${index}`} data-glide-dir={`=${index}`}></button>
                ))}
              </div>
            </div>
          </OuterCarousel>
        </div>

        <div className='section'>
          <div className='content-wrapper'>
            <div className='section-left'>
              <div className='desc'>
                <div className='heading'>
                  New Riff 9 year Old Charity Barrel Selection
                </div>
                <div className='text'>
                  We are proud to be the only club in the world to have selected a single barrel of
                  9-year-old New Riff bourbon, a special initiative part of a charity auction to aid
                  victims of the 2022 Eastern KY floods and tornadoes. We are thrilled to offer this
                  exclusive bourbon alongside Four Roses. When you purchase our Tier 1 membership,
                  choose between a complimentary bottle of New Riff or Four Roses. For Tier 2
                  members,
                  opt for a $400 credit towards this exceptional New Riff selection.
                </div>
              </div>
              <a className='find-more' href='#Benifits-Pricing'>
                Find Out More
              </a>
            </div>
            <div className='section-right'>
              {/* <img src={PlayIcon} className='controls' alt='' /> */}
              {twoPlayed ? (
                <iframe
                  width='508'
                  height='508'
                  src='https://www.youtube.com/embed/f0PHD_Ya-78?autoplay=1'
                  title='Sagamore Spirits Farm Visit and Barrel Selection'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              ) : (
                <div className='play-image-wrapper' onClick={() => setTwoPlayed(true)}>
                  <NewRiffLogo />
                  <PlayIcon className='play-icon' />
                </div>
              )}
            </div>
          </div>
          <OuterCarousel>
            <div className='glide new-riff-carousel'>
              <div className='glide__track' data-glide-el='track'>
                <ul className='glide__slides'>
                  {newRiffImages.map((image, index) => (
                    <li className='glide__slide' key={`new-riff-experience-img-${index}`}>
                      <img src={image} loading='lazy' className='image-container' alt='' />
                    </li>
                  ))}
                </ul>
              </div>
              <div className='glide__arrows' data-glide-el='controls'>
                <button className='glide__arrow glide__arrow--left' data-glide-dir='<'><span
                  className='visually-hidden'>Previous</span><Arrow /></button>
                <button className='glide__arrow glide__arrow--right' data-glide-dir='>'><span
                  className='visually-hidden'>Next</span><Arrow />
                </button>
              </div>
              <div className='glide__bullets' data-glide-el='controls[nav]'>
                {newRiffImages.map((_, index) => (
                  <button className='glide__bullet' aria-label={`slide number ${index+1}`} key={`new-riff-experience-bullet-${index}`} data-glide-dir={`=${index}`}></button>
                ))}
              </div>
            </div>
          </OuterCarousel>
        </div>
      </div>
    </SagaMoreStyled>
  );
}

export default ReelVideo;
