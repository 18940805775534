import styled from 'styled-components';

export default styled.div`
  text-align: center;
  margin-top: 2rem;
  font-family: 'Century Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #0a3047;
  max-width: 894px;
  height: 68px;
  width: 100%;

  @media (max-width: 800px) {
    font-size: 14px;
    max-width: 405px;
    padding: 0 1rem;
  }
`;
