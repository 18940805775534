import React, { useState, useEffect } from 'react';
import ReviewSelectionStyled, {
  DropDownContainer,
  DropDownListContainer,
  DropDownList,
  ListItem,
  DropDownHeader,
} from './style';
import PopUpHeader from '../PopUpHeader';
import DropDownIcon from '../../assets/drop-down-arrow.svg';
import Button from '../Button';
import addons from '../../models/addons';
// import Modal from '../Modal';
// import TierSelector from '../TierSelector';

import tier from '../../models/tiers';

function ReviewSelection({
  selectedTier,
  setSelectedTier,
  selectedAddOns,
  setSelectedAddOns,
  onConfirm,
  // total,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState(addons[0]);

  // const [editSelectionShown, setEditSelectionShown] = useState(false);

  const [editSelectionShownNew, setEditSelectionShownNew] = useState(false);

  const [remainingTiers, setRemainingTiers] = useState(
    tier.filter((i) => i.name !== selectedTier.name)
  );

  // const [selectedNewTier, setSelectedNewTier] = useState(selectedTier);

  const [newTotal, setNewTotal] = useState(0);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const handleCountIncrement = (addOn) => {
    const newAddOnArray = [...selectedAddOns];
    const index = newAddOnArray.findIndex((x) => x.item === addOn.item);
    newAddOnArray[index].count += 1;
    setSelectedAddOns(newAddOnArray);
  };

  const handleCountDecrement = (addOn) => {
    const newAddOnArray = [...selectedAddOns];
    const index = newAddOnArray.findIndex((x) => x.item === addOn.item);
    if (!newAddOnArray[index].count <= 0) {
      newAddOnArray[index].count -= 1;
      setSelectedAddOns(newAddOnArray);
    }
  };

  const handleOptionAdd = () => {
    const alreadyExist = !!selectedAddOns.find(
      (x) => x.item === selectedOption.item
    );
    if (alreadyExist) {
      handleCountIncrement(selectedOption);
    } else {
      setSelectedAddOns([...selectedAddOns, { ...selectedOption, count: 1 }]);
    }
  };

  // const handleTierEdit = (name) => {
  //   setSelectedTier(tier.find((t) => t.name === name));
  //   setEditSelectionShown(false);
  // };

  const handleTSelectedTier = (item) => {
    setRemainingTiers(tier.filter((i) => i.title !== item.title));
    setSelectedTier(item);
  };

  const updateTotalNew = () => {
    setNewTotal(
      selectedTier.presalePrice +
        selectedAddOns.reduce((sum, item) => sum + item.price * item.count, 0)
    );
  };

  useEffect(() => {
    updateTotalNew();
  }, [selectedAddOns, selectedTier]);

  return (
    <ReviewSelectionStyled>
      <PopUpHeader
        headingOne='Nice!'
        headingTwo='Please Review Your Selections:'
        rowGap='25px'
      />

      <div className='section selected-tier'>
        <div className='tier-title'>{selectedTier.title}</div>
        <div className='tier-availability'>
          Availability: {selectedTier.availability}
        </div>
        <div className='tier-benefits'>
          <span className='fw-700'>Benefits</span>:{' '}
          {selectedTier.description}
        </div>
        <div className='tier-price'>
          Presale Price:{' '}
          <span className='orange-text'>
            ${selectedTier.presalePrice.toLocaleString()}
          </span>
        </div>
        <div id='selection'>
          <div
            className='tier-edit'
            role='button'
            onClick={() => setEditSelectionShownNew(!editSelectionShownNew)}
          >
            Edit Selection
          </div>
          <img
            style={{
              transform: editSelectionShownNew
                ? 'rotate(180deg)'
                : 'rotate(0deg)',
            }}
            src={DropDownIcon}
            alt=''
            onClick={() => setEditSelectionShownNew(!editSelectionShownNew)}
          />
        </div>
      </div>

      {editSelectionShownNew && (
        <div className='selection-container'>
          <div className='info'>
            *Please note: you cannot reserve multiple tiers
          </div>
          <div className='tier-selection'>
            {remainingTiers.map((item) => (
              <div className='option top' key={item.name}>
                <div className='option-name'>{item.title}</div>
                <button
                  type='button'
                  className='add-btn'
                  onClick={() => handleTSelectedTier(item)}
                >
                  Add Now
                </button>
              </div>
            ))}

            {/* <div className='option bottom '>
              <div className='option-name'>Tier 1: Single Barrel Advocate</div>
              <button type='button' className='add-btn'>
                Add Now
              </button>
            </div> */}
          </div>
        </div>
      )}

      <div className='section-seperator' />
      {selectedAddOns.map((addOn) => (
        <>
          <div className='section selected-addon'>
            <div className='tier-title'>{addOn.title}</div>
            <div className='tier-availability'>
              Available Starting: {addOn.availability}
            </div>
            <div className='tier-price'>
              Reservation Price:{' '}
              <span className='orange-text'>
                ${addOn.price.toLocaleString()}
              </span>
            </div>
            <div className='addon-count-changer'>
              <button type='button' onClick={() => handleCountDecrement(addOn)}>
                -
              </button>
              <span>{addOn.count}</span>
              <button type='button' onClick={() => handleCountIncrement(addOn)}>
                +
              </button>
            </div>
          </div>
          <div className='section-seperator' />
        </>
      ))}
      {selectedAddOns.length > 0 && <div className='section-seperator' />}

      <div className='section selected-addon-more'>
        <div className='label'>Add More</div>
        <DropDownContainer>
          <DropDownHeader onClick={toggling}>
            {selectedOption?.title || addons[0].title}
            <img
              src={DropDownIcon}
              alt=''
              style={{ transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)' }}
            />
          </DropDownHeader>
          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
                {addons.map((option) => (
                  <ListItem
                    onClick={onOptionClicked(option)}
                    key={Math.random()}
                  >
                    {option.title}
                  </ListItem>
                ))}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
        <div onClick={handleOptionAdd} className='addon-more-save'>
          Add On Now
        </div>
      </div>

      <div className='section-seperator' />

      <div className='section selected-confirm'>
        <div className='total'>
          <b>Total:</b> ${newTotal.toLocaleString()}.00 USD{' '}
        </div>
        <Button onClick={onConfirm}>Confirm Selection</Button>
      </div>

      {/* <Modal
        show={editSelectionShown}
        onClose={() => setEditSelectionShown(false)}
      >
        <TierSelector selectTier={handleTierEdit} />
      </Modal> */}
    </ReviewSelectionStyled>
  );
}

export default ReviewSelection;
