import styled from 'styled-components';

const ProfileFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 55px 36px 55px;
  background: #fbf1e6;
  width: 600px;
  color: #0a3047;

  a {
    margin-top: 25px;
  }

  .error-text {
    color: red !important;
  }

  .info {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 17.2114px;
    line-height: 24px;
    color: #000000;
    margin-top: 15px;
  }

  .form-wrapper {
    width: 291.88px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 27px;
    margin-bottom: 30px;

    .form-group {
      width: 100%;

      label {
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 17.2114px;
        color: #4c0d18;
        display: block;
      }

      input {
        box-sizing: border-box;
        background: #4c0d18;
        border-radius: 7.17141px;
        height: 42.31px;
        padding: 0px 15px;
        border: none;
        width: 100%;
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 17.2114px;
        color: #fff;
      }

      input::placeholder {
        color: #fff;
      }
    }
  }

  .submit {
    font-family: 'Century Gothic';
    font-weight: 700;
    font-size: 18px;
    text-decoration-line: underline;
    color: #fd7835;
    cursor: pointer;
    margin-top: 0px;
    margin-bottom: 26px;
  }

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px 50px 25px 50px;
    background: #fbf1e6;
    width: 90vw;
    color: #0a3047;

    .error-text {
      color: red !important;
    }

    .info {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 17.2114px;
      line-height: 25px;
      color: #000000;
      margin-top: 15px;
    }

    .form-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin-top: 25px;
      margin-bottom: 20px;

      .form-group {
        width: 100%;

        label {
          font-family: 'Century Gothic';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          color: #4c0d18;
          display: block;
        }

        input {
          box-sizing: border-box;
          background: #4c0d18;
          border-radius: 7.17141px;
          height: 30px;
          padding: 0px 15px;
          border: none;
          width: 100%;
          font-family: 'Century Gothic';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #fff;
        }

        input::placeholder {
          color: #fff;
        }
      }
    }

    .submit {
      font-family: 'Century Gothic';
      font-weight: 700;
      font-size: 12px;
      text-decoration-line: underline;
      color: #fd7835;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
`;

export default ProfileFormStyled;
