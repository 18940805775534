import styled from 'styled-components';

const WPDStyled = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8rem 0rem;

  .container {
    width: 100%;
    max-width: 1110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content {
      display: flex;
      column-gap: 55px;
      margin-top: 50px;

      .text-area {
        width: 340px;
        margin-top: 30px;
        height: fit-content;

        .heading {
          font-style: normal;
          font-size: 24px;
          line-height: 90%;
          color: #0a3047;
        }

        .desc {
          font-family: 'Century Gothic';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
          color: #0a3047;
        }

        button {
          padding: 10px 28px;
          height: 45px;
          background: #fd7835;
          border-radius: 12px;
          border: none;
          cursor: pointer;

          .btn {
            font-style: normal;
            font-size: 18px;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
          }
        }
      }

      .image-area {
        .maroon-bg {
          width: 710px;
          height: 530px;
          background: #4c0d18;
          border-radius: 25px;
          padding: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .img-fg {
          display: flex;
          position: relative;
          flex-direction: column;
          row-gap: 20px;

          .row {
            width: 100%;
            display: flex;
            column-gap: 20px;

            img {
              width: 180px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;

    .container {
      width: 80vw;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .content {
        display: flex;
        column-gap: 55px;
        width: 100%;
        height: auto;
        align-items: center;
        margin-top: 30px;
        justify-content: center;

        .text-area {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: fit-content;

          .heading {
            font-style: normal;
            font-size: 24px;
            line-height: 90%;
            color: #0a3047;
            text-align: center;
          }

          .desc {
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            color: #0a3047;
            text-align: center;
          }

          button {
            padding: 10px 28px;
            height: 45px;
            background: #fd7835;
            border-radius: 12px;
            border: none;
            cursor: pointer;

            .btn {
              font-style: normal;
              font-size: 18px;
              line-height: 140%;
              text-align: center;
              color: #ffffff;
            }
          }
        }

        .image-area {
          display: none;

          .maroon-bg {
            width: 100%;
            height: 70%;
            background: #4c0d18;
            border-radius: 25px;
          }

          .img-fg {
            position: relative;
            top: -255px;
            left: 65px;
            width: 746px;
            height: 393px;
            display: flex;
            flex-direction: column;
            row-gap: 9px;

            .row {
              width: 100%;
              display: flex;
              column-gap: 9px;

              img {
                width: 180px;
              }
            }
          }
        }
      }
    }
  }
`;

export default WPDStyled;
