import styled from 'styled-components';

const AutoCarouselBGStyled = styled.div`
  display: flex;
  height: 100%;
  padding: 30px 0 45px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  background-image: url(${(props) => props.bg});
  background-size: ${(props) => props.bgFit || 'cover'};
  background-position: center;

  @media screen and (max-width: 800px) {
    padding: 30px 6vw 25px;
  }
`;

const Outer = styled.div`
  .dot {
    background: #fd7835 !important;
    opacity: 1 !important;
    width: 10px !important;
    height: 10px !important;
    box-shadow: none !important;
  }

  .control-dots .selected {
    background: #fbf1e6 !important;
  }

  .carousel .control-dots {
    margin: 10px -22px;
  }

  @media screen and (max-width: 800px) {
    .carousel .control-dots {
      margin: 0px 0px;
    }
  }

  .thumbs-wrapper {
    margin: 0px !important;
  }

  .carousel.carousel-slider .control-arrow {
    opacity: 1;
    top: 50%;
    padding: 0px;
    transform: scale(2);
    position: absolute;
    background: #fbf1e6;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  }

  .carousel.carousel-slider .control-arrow.control-prev {
    left: 10%;
  }

  .carousel.carousel-slider .control-arrow.control-next {
    right: 10%;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 16px solid #fd7835;
    margin-right: 10px;
    cursor: pointer;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 16px solid #fd7835;
    margin-left: 8px;
    cursor: pointer;
  }
`;
export { AutoCarouselBGStyled, Outer };
