import styled from 'styled-components';

const AddOnSelectorStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 60px 65px 60px;
  background: #fbf1e6;

  .sub-part {
    display: flex;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 75px;
    column-gap: 25px;

    .add-on-item {
      width: 355px;
      height: 515px;
      background: #4C0D18;
      border-radius: 22px;
      padding: 45px 40px;
    }

    .add-on-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }

    .add-on-title {
      font-family: 'Nielmaden';
      font-weight: 700;
      font-size: 36px;
      text-decoration-line: underline;
      text-align: center;
      color: white; 
      line-height: 140%;
    }

    .add-on-availability {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      text-align: center;
      color: #FFFFFF;
    }

    .add-on-price-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 0.9px solid #FBF1E6;
      border-radius: 22px;
      width: 100%;
      padding: 35px;
      row-gap: 20px;
    }

    .add-on-price {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      color: #FD7835;
    }

  }

  .skip-link {
    font-family: 'Century Gothic';
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #FD7835;
    cursor: pointer;
  }

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 35px 30px;
    background: #fbf1e6;
    width: 83vw;

    .sub-part {
      display: flex;
      align-items: center;
      margin-top: 35px;
      margin-bottom: 33px;
      column-gap: 25px;
      flex-direction: column;
      row-gap: 25px;

      .add-on-item {
        width: 250px;
        height: 330px;
        background: #4C0D18;
        border-radius: 22px;
        padding: 30px 24px;
      }

      .add-on-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
      }

      .add-on-title {
        font-family: 'Nielmaden';
        font-weight: 700;
        font-size: 24px;
        text-decoration-line: underline;
        text-align: center;
        color: white; 
        line-height: 140%;
      }

      .add-on-availability {
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
      }

      .add-on-price-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.9px solid #FBF1E6;
        border-radius: 22px;
        width: 100%;
        padding: 15px;
        row-gap: 12px;
      }

      .add-on-price {
        font-family: 'Nielmaden';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        color: #FD7835;
      }

    }

    .skip-link {
      font-family: 'Century Gothic';
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      text-decoration-line: underline;
      color: #FD7835;
      cursor: pointer;
    }
  }
`;
export default AddOnSelectorStyled;
