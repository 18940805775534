import styled from 'styled-components';

const WalletSelectorStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4C0D18;
  padding: 48px 48px 62px 48px;

  .selector-title {
    font-family: 'Century Gothic';
    font-weight: 700;
    font-size: 27.5429px;
    color: #FFFFFF;
    margin-bottom: 43px;
  }

  .wallet-list {
    position: relative;
    width: 100%;
    pointer-events: auto;
    display: grid;
    row-gap: 35px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  .wallet-item {
    transition: background-color 0.2s ease-in-out 0s;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FBF1E6;
    border-radius: 4px;
    padding: 12px 18px;
    cursor: pointer;

    &:hover {
      background-color: rgb(245, 245, 245);
    }

    &:first-child {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: 1px solid #d7d7d7;
    }
  }

  .wallet-logo {
    width: 40px;
    height: 40px;
    overflow: visible;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .wallet-title {
    font-family: 'Century Gothic';
    font-weight: 400;
    font-size: 20px;
    color: #4C0D18;
    text-transform: capitalize;
  }

  @media screen and (max-width: 800px) {
    padding: 31px 41px 50px 41px;
    
    .wallet-list {
      position: relative;
      width: 100%;
      pointer-events: auto;
      display: grid;
      row-gap: 35px;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
`;

export default WalletSelectorStyle;
