/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { ContentStyled } from './style';


function BrandPartnersSubSlide({ subSlide, mobile = false }) {
  return (
    <ContentStyled
      contentWidth={subSlide.contentWidth}
      fontSize={subSlide.fontSize}
      justifyContent='center'
    >
      <div className='brand-partner-container'>
        <div className='partner-one'>
          <div className='stickers'>
            {subSlide.brandPartnerLogos.firstLine.map((logo) => (
              <img
                key={`logo-firstline-img-${logo.name}`}
                src={logo.sticker}
                alt=''
                style={{
                  width: logo.firstImageWidth,
                  height: logo.firstImageHeight,
                }}
              />
            ))}
          </div>
          <div className='name-one'>
            {subSlide.brandPartnerLogos.firstLine.map((logo) => (
              <div key={`logo-firstline-name-${logo.name}`} style={{ width: logo.firstImageWidth }}>
                {logo.name}
              </div>
            ))}
          </div>
        </div>
        <div className='partner-two'>
          <div className='stickers'>
            {subSlide.brandPartnerLogos.secondLine.map((logo) => (
              <React.Fragment key={`logo-secondline-img-${logo.name}`}>
                <img
                  src={logo.sticker}
                  alt=''
                  style={{
                    width: logo.firstImageWidth,
                    height: logo.firstImageHeight,
                  }}
                />
              </React.Fragment>
            ))}
          </div>
          <div className='name-two'>
            {subSlide.brandPartnerLogos.secondLine.map((logo) => (
              <div key={`logo-secondline-name-${logo.name}`} style={{ width: logo.firstImageWidth }}>
                {logo.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='partners-mobile'>
          <div className='Array-one-div'>
            {subSlide.brandPartnerLogosMobile.firstLine.map((logo) => (
              <div key={`partner-logo-firstline-${logo.name}`}>
                <img
                  src={logo.sticker}
                  alt=''
                  style={{
                    width: logo.firstImageWidth,
                    height: logo.firstImageHeight,
                  }}
                />
                <div style={{ fontSize: '7px' }}>{logo.name}</div>
              </div>
            ))}
          </div>
          <div className='Array-two-div'>
            {subSlide.brandPartnerLogosMobile.secondLine.map((logo) => (
              <div key={`partner-logo-secondline-${logo.name}`}>
                <img
                  src={logo.sticker}
                  alt=''
                  style={{
                    width: logo.firstImageWidth,
                    height: logo.firstImageHeight,
                  }}
                />
                <div style={{ fontSize: '7px' }}>{logo.name}</div>
              </div>
            ))}
          </div>
          <div className='Array-three-div'>
            {subSlide.brandPartnerLogosMobile.thirdLine.map((logo) => (
              <div key={`partner-logo-thirdline-${logo.name}`}>
                <img
                  src={logo.sticker}
                  alt=''
                  style={{
                    width: logo.firstImageWidth,
                    height: logo.firstImageHeight,
                  }}
                />
                <div style={{ fontSize: '7px' }}>{logo.name}</div>
              </div>
            ))}
          </div>
      </div>
      <a
        href={subSlide.linkAddress}
        style={!mobile? { marginTop: '25px', marginLeft: '-36px' } :  { marginTop: '15px', marginLeft: '0px', fontSize: '14px' }}
      >
        {subSlide.link}
      </a>
    </ContentStyled>
  );
}

export default BrandPartnersSubSlide;
