/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable indent */
import { observer } from 'mobx-react-lite';
import React from 'react';
import AboutNFTs from '../AboutNFTs';
import AboutPB from '../AboutPB';
import AutoCarouselBG from '../AutoCarouselBG';
import BenefitsAndPricing from '../Benefits&Pricing';
import ExclusiveBottles from '../ExclusiveBottles';
import FAQ from '../FAQ';
import Footer from '../Footer';
import MeetTheTeam from '../MeetTheTeam';
import Nav from '../Nav';
import NFTArt from '../NFTArt';
import Partnerships from '../Partnerships';
import ReelVideo from '../ReelVideo';
import Testimonials from '../Testimonials';
import TierBreakDown from '../TierBreakDown';
import ToolsAndResources from '../Tools&Resources';
import CurrentSelection from '../CurrentSelection';
import FutureSelection from '../FutureSelection';
import MintNFTS from '../MintNFTs';
import PastSelection from '../PastSelection';
import MintPage from '../MintPage';
import WhitePaperDownload from '../WhitePaperDownload';

// Global styles for Glide Carousel
import './glide-carousel-styles.scss';

const Home = observer(() => (
  <>
    <Nav />
    <AutoCarouselBG />
    {process.env.REACT_APP_ENABLE_MINT === 'true' && <MintPage />}
    {process.env.REACT_APP_ENABLE_MINT === 'true' && <MintNFTS />}
    <AboutPB />
    <ReelVideo />
    <AboutNFTs />
    <TierBreakDown />
    <Testimonials />
    <BenefitsAndPricing />
    <NFTArt />
    <Partnerships />
    <ExclusiveBottles />
    <MeetTheTeam />
    <FAQ />
    <ToolsAndResources />
    <WhitePaperDownload />
    <CurrentSelection />
    <FutureSelection />
    <PastSelection />
    <Footer />
  </>
));
export default Home;
