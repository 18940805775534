/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable radix */
/* eslint-disable indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useAccount, usePublicClient } from 'wagmi';
import { observer } from 'mobx-react-lite';
import {
  MintContainerStyled,
  MCContentStyled,
  MCBenefitsStyled,
  ModalStyled,
  MCHeaderStyled,
} from './style';

import IncludedCheck from '../IncludedCheck';
import EthMint from '../EthMint';
import DollarMint from '../DollarMint';
import stores from '../../store';
import WalletButton from '../WalletButton';
import Timer from '../Timer';

import { getTier, isJoined, saveReferralCode } from '../../service/api';
import tierList from '../../utils/tier-list';
import { ETHPriceContext } from '../../context/ETHPriceContext';
import { ReferralCodeContext } from '../../context/ReferralCodeContext';
import { CsvCMSDataContext } from '../../context/CsvCMSDataContext';

const headers = [
  {
    heading: 'Buy Now',
    text: `You can buy your membership by using a credit card or ETH. We’ve
          pre-selected the tier you were interested in, but you can change your
          selection if you wish.`,
  },
  {
    heading: 'Review Your Mint',
    text: 'Please review your selections below',
  },
  { heading: 'Error', text: 'Request Invalid' },
  {
    heading: 'Congrats!',
    text: 'Thanks! Your purchase/mint was successful.',
  },
  {
    heading: 'Pre-sale event by prior reservation only.',
    subHeading: 'Enrollment opens in: ',
  },
];

const MintContainer = observer(() => {
  const { ethUSDPrice, loading } = useContext(ETHPriceContext);
  const { referralCode } = useContext(ReferralCodeContext);
  const { tierOnePerks, tierTwoPerks } = useContext(CsvCMSDataContext);

  const { contractStore, walletStore } = stores;

  const { address, isConnected } = useAccount();
  const provider = usePublicClient();
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  const [showErrorSection, setShowErrorSection] = useState(false);
  const [showBenefits, setShowBenefits] = useState(true);
  const [showMintContent, setShowMintContent] = useState(true);
  const [showReviewSection, setShowReviewSection] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [preSaleNonWaitListed, setPreSaleNonWaitListed] = useState(true);
  //const [reservedTier, setReservedTier] = useState(null); // Removed reserved because presale is finished
  const [selectedCount, setSelectedCount] = useState(1);
  const [currentUSD, setCurrentUSD] = useState(0);
  const [selectedUSD, setSelectedUSD] = useState(0);

  // For testing purpose
  const preSale = process.env.REACT_APP_PRESALE === 'true';
  const preSaleDate = new Date('2023-03-08T21:30:00+05:30');
  const [selectedTier, setSelectedTier] = useState(tierList[1]); //Tier 2 is the default selected tier

  const checkIfPresaleDone = () => {
    const today = new Date();
    return preSaleDate > today;
  };

  useEffect(() => {
    if (!loading) {
      setCurrentUSD(ethUSDPrice);
      setSelectedUSD(parseInt((ethUSDPrice * selectedTier.ethPrice).toFixed(0)));
    }
  }, [loading, ethUSDPrice, selectedTier]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    isJoined(address).then((res) => {
      if (
        res.status === 200 &&
        !res.data.exist &&
        process.env.REACT_APP_PRESALE === 'true'
      ) {
        setPreSaleNonWaitListed(true);
      } else {
        setPreSaleNonWaitListed(false);
      }
    });

    getTier(address).then((res) => {
      const reserved = res && res.data.reserved;
      const tierObj =
        /*reserved === 1
          ? tierList[0] // Tier 1 Plus was removed May 2024, so tierList indexes got shifted
          :*/
        reserved === 2
          ? tierList[0]
          : reserved === 3
            ? tierList[1]
            : null;

      if (tierObj) {
        setSelectedTier(tierObj);
      }
    });
  }, [address, contractStore.userMintedCount]);

  const fetchInitData = useCallback(() => {
    contractStore.fetchContractData('primebarrel', (status) => {
      if (status) {
        contractStore
          .getInitialData()
          .then(() => {
          })
          .catch(() => {
          });
      }
    });
  }, [contractStore.userMintedCount]);

  const handleSelectedTier = (tier) => {
    const selected = tierList.find((t) => t.tierName === tier.tierName);
    setSelectedTier(selected);
    setSelectedCount(1);
    setSelectedUSD((currentUSD * selected.ethPrice).toFixed(0));
  };

  const handleEthClick = () => {
    setShowCloseBtn(true);
    setShowBenefits(false);
    setShowMintContent(false);
    setShowReviewSection(true);
  };

  const handleDollarClick = () => {
    setShowCloseBtn(true);
    setShowBenefits(false);
    setShowMintContent(false);
    setShowReviewSection(true);
  };

  const handleCloseBtn = () => {
    setShowBenefits(true);
    setShowCloseBtn(false);
    setShowMintContent(true);
    setShowReviewSection(false);
    setShowErrorSection(false);
    setShowSuccessModal(false);
    setSelectedCount(1);
    contractStore.setMintResponse(null);
    contractStore.setMintReceipt(null);
  };

  const handleDecreaseCount = (tier) => {
    if (tier === 'Tier 1 Plus') {
      setSelectedCount(selectedCount);
    } else if (selectedCount > 1) {
      setSelectedCount(selectedCount - 1);
    }
  };

  const handleIncreaseCount = (tier) => {
    if (tier === 'Tier 1 Plus') {
      setSelectedCount(selectedCount);
    } else {
      setSelectedCount(selectedCount + 1);
    }
  };

  const reviewSection = (
    <div className='review-section'>
      <div className='tier-heading'>{selectedTier?.tierHeading}</div>
      <div className='count font'>
        {selectedTier?.tierName === 'Tier 1 Plus'
          ? contractStore.tierOnePlusCount
          : selectedTier?.tierName === 'Tier 1'
            ? contractStore.tierOneCount
            : contractStore.tierTwoCount}
        /{selectedTier?.availability}
      </div>
      <div className='eth-price font price'>{selectedTier?.ethPrice} ETH</div>
      <div className='dollar-price font price'>
        {/* ${selectedTier?.dollarPrice.toLocaleString()} USD */}
        ${selectedUSD} USD
      </div>
      <div className='selected-count'>{selectedCount}</div>
      {/* {preSale ? (
        <div className='selected-count'>1</div>
      ) : (
        <div className='selected-count public-sale'>
          {selectedTier?.tierName !== 'Tier 1 Plus' ? (
            <>
              <button
                className='decrease'
                onClick={() => handleDecreaseCount(selectedTier.tierName)}
                disabled={selectedCount === 1 || selectedCount === 0}
              >
                -
              </button>{' '}
              {selectedCount}{' '}
              <button
                className='increase'
                onClick={() => handleIncreaseCount(selectedTier.tierName)}
                disabled={selectedCount === 2}
              >
                +
              </button>
            </>
          ) : (
            selectedCount
          )}
        </div>
      )} */}

      <EthMint
        action='CONFIRM'
        setShowTransactionModal={setShowTransactionModal}
        tier={selectedTier}
        count={selectedCount}
        onEthClick={handleEthClick}
      />
    </div>
  );

  const errorSection = (
    <div className='review-section'>
      <div className='error'>{contractStore.mintResponse}</div>
    </div>
  );

  const successSection = (
    <div className='success-section'>
      <div className='text-one'>
        You can now see your purchased membership NFT in the gallery below. Your
        membership NFT is also accessible through your crypto wallet browser
        extension or a mobile app.
      </div>
      <div className='text-two'>
        * there might be a small delay before you see it in the wallet
      </div>
      <div>
        <a
          href={`https://goerli.etherscan.io/tx/${contractStore.mintReceipt?.transactionHash}`}
          target='_blank'
          rel='noreferrer'
        >
          Transaction Details
        </a>
      </div>
    </div>
  );

  const mintContent = (
    <MCContentStyled tierSelected={selectedTier}>
      <div className='mint-details'>
        <div className='switch-section'>
          {tierList.map((tier) => (
            <div
              key={tier.tierName}
              className={`tier-indicator ${
                selectedTier?.tierName === tier.tierName && 'selected'
              }`}
            />
          ))}
        </div>
        <div className='tier-selection'>
          {tierList.map((tier) => (
            <div
              key={tier.tierName}
              className={`tier ${
                tier.tierName === 'Tier 1 Plus' || tier.tierName === 'Tier 1'
                  ? 'white-margin'
                  : ''
              } ${selectedTier?.tierName === tier.tierName && 'selected'}`}
              onClick={() => handleSelectedTier(tier)}
            >
              {tier.tierName}
            </div>
          ))}
        </div>

        <div className='tier-details'>
          {/* Presale is finished, so it never enters here, but leaving it for now */}
          {preSaleNonWaitListed && preSale ? (
            <div className='left-section'>
              <div className='top-section'>
                <div className='tier font'> {selectedTier?.tierHeading}</div>
                <div className='yellow-line' />
                <div className='access'>Exclusive Pre-sale Access</div>
              </div>
              <div
                className='bottom-section'
                style={{ justifyContent: 'center' }}
              >
                <div className='price-section'>
                  <div className='count font'>
                    {selectedTier?.tierName === 'Tier 1 Plus'
                      ? contractStore.tierOnePlusCount
                      : selectedTier?.tierName === 'Tier 1'
                        ? contractStore.tierOneCount
                        : contractStore.tierTwoCount}
                    /{selectedTier?.availability}
                  </div>
                  <div className='prices'>
                    <div className='eth font'>{selectedTier?.ethPrice} ETH</div>
                    <span className='slash'>{'   '}</span>
                    <div className='dollar font'>
                      {/* ${selectedTier?.dollarPrice.toLocaleString()} USD */}
                      ${selectedUSD} USD
                    </div>
                  </div>
                </div>
                {!preSale &&
                  isConnected &&
                  selectedTier.tierName !== 'Tier 1 Plus' && (
                    <div className='select-count'>
                      <button
                        className='decrease'
                        onClick={() =>
                          handleDecreaseCount(selectedTier.tierName)
                        }
                        disabled={selectedCount <= 1}
                      >
                        -
                      </button>
                      <div className='count'>{selectedCount}</div>
                      <button
                        className='increase'
                        onClick={() =>
                          handleIncreaseCount(selectedTier.tierName)
                        }
                        disabled={selectedCount === 2}
                      >
                        +
                      </button>
                    </div>
                  )}
                {!isConnected && (
                  <div className='buttons'>
                    <WalletButton />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className='left-section'>
              <div className='top-section'>
                <div className='tier font'> {selectedTier?.tierHeading}</div>
                {!preSale && <div className='yellow-line' />}
                {(selectedTier?.tierName === 'Tier 1') ?
                  <div className='access'>Our most complete package</div> :
                  <div className='access'>Our Gateway Package</div>}
              </div>
              <div className={`bottom-section ${isConnected ? 'connected' : ''}`}>
                <div className='price-section'>
                  <div className='count font'>
                    {selectedTier?.tierName === 'Tier 1 Plus'
                      ? contractStore.tierOnePlusCount
                      : selectedTier?.tierName === 'Tier 1'
                        ? contractStore.tierOneCount
                        : contractStore.tierTwoCount}
                    /{selectedTier?.availability}
                  </div>
                  <div className='prices'>
                    <div className='eth font'>{selectedTier?.ethPrice} ETH</div>
                    <span className='slash'>{'   '}</span>
                    <div className='dollar font'>
                      ${selectedUSD} USD
                      {/* ${selectedTier?.dollarPrice.toLocaleString()} USD */}
                    </div>
                  </div>
                </div>
                {!preSale &&
                  isConnected &&
                  selectedTier.tierName !== 'Tier 1 Plus' && (
                    <div className='select-count'>
                      <button
                        className='decrease'
                        onClick={() =>
                          handleDecreaseCount(selectedTier.tierName)
                        }
                        disabled={selectedCount === 1 || selectedCount === 0}
                      >
                        -
                      </button>
                      <div className='count'>{selectedCount}</div>
                      <button
                        className='increase'
                        onClick={() =>
                          handleIncreaseCount(selectedTier.tierName)
                        }
                        disabled={selectedCount === 2}
                      >
                        +
                      </button>
                    </div>
                  )}
                <div className='buttons'>
                  {isConnected ? (
                    <>
                      <EthMint
                        tier={selectedTier}
                        count={selectedCount}
                        setShowTransactionModal={setShowTransactionModal}
                        onEthClick={handleEthClick}
                      />
                      <DollarMint
                        tier={selectedTier}
                        count={selectedCount}
                        setShowTransactionModal={setShowTransactionModal}
                        onDollarClick={handleDollarClick}
                      />
                    </>
                  ) : (
                    <WalletButton />
                  )}
                </div>
              </div>
            </div>
          )}
          <div className='right-section'>
            <img src={selectedTier?.badge} alt='' className='badge' />
            <img src={selectedTier?.image} alt='' className='image' />
          </div>
        </div>
      </div>
    </MCContentStyled>
  );

  const tierBenefits = useMemo(() => {
    let perksToDisplay = selectedTier?.tierName === 'Tier 1' ? tierOnePerks : tierTwoPerks;

    return (
      <div className='benefits'>
        {perksToDisplay.map((perk, index) => (
          <div className='items' key={`perk-list-${index}`}>
            {perk['Icon'] === 'Green Check' ?
              <IncludedCheck /> : perk['Icon'] === 'Yellow Check' ?
                <IncludedCheck partialPerk /> : <span className='item-icon excluded'>x</span>}
            <div className='item-text'>
              {perk['Highlighted'] !== '' ?
                <span className='bold'>{`${perk['Highlighted']} `}</span> : null}
              {perk['Perk Text'] !== '' ? `${perk['Perk Text']} ` : null}
              {perk['Perk Note'] !== '' ? <i>{`${perk['Perk Note']} `}</i> : null}
              {(perk['Link Text'] !== '' && perk['Link URL'] !== '') ?
                <a href={perk['Link URL']} target='_blank' className='bold'
                   rel='noreferrer'>{perk['Link Text']}</a> : null}
            </div>
          </div>
        ))}
      </div>
    );
  }, [selectedTier, tierOnePerks, tierTwoPerks]);

  const benefitSection = (
    <MCBenefitsStyled>
      <div className='tier top-text'>
        What you get with {'  '}
        {selectedTier?.headName
          ? selectedTier?.headName
          : 'Tier 1 Plus: Single Barrel Savant Plus'}
      </div>
      <div className='sub-text'>
        You can compare memberships with the tabs above
      </div>
      {tierBenefits}
      {!preSale && isConnected && selectedTier.tierName !== 'Tier 1 Plus' && (
        <div className='select-count'>
          <button
            className='decrease'
            onClick={() => handleDecreaseCount(selectedTier.tierName)}
            disabled={selectedCount <= 1}
          >
            -
          </button>
          <div className='count'>{selectedCount}</div>
          <button
            className='increase'
            onClick={() => handleIncreaseCount(selectedTier.tierName)}
            disabled={selectedCount === 2}
          >
            +
          </button>
        </div>
      )}
      {!preSaleNonWaitListed ? (
        <div className='buttons'>
          {isConnected ? (
            <>
              <EthMint
                onEthClick={handleEthClick}
                tier={selectedTier}
                count={selectedCount}
              />
              <DollarMint tier={selectedTier} count={selectedCount} />
            </>
          ) : (
            <WalletButton />
          )}
        </div>
      ) : (
        <div className='buttons'>{!isConnected && <WalletButton />}</div>
      )}
    </MCBenefitsStyled>
  );

  const modalSection = (
    <ModalStyled>
      <div className='outer'>
        <div className='heading'>Please Wait...</div>
        <div className='text'>
          Your transaction has been forwarded to the network
        </div>
      </div>
    </ModalStyled>
  );

  // Switch cases for setting headers
  let heading;
  let text;

  switch (true) {
    case showReviewSection:
      heading = headers[1].heading;
      text = headers[1].text;
      break;

    case showErrorSection:
      heading = headers[2].heading;
      text = headers[2].text;
      break;

    case showSuccessModal:
      heading = headers[3].heading;
      text = headers[3].text;
      break;

    default:
      heading = headers[0].heading;
      text = headers[0].text;
      break;
  }

  const header =
    preSaleNonWaitListed && preSale && checkIfPresaleDone() && isConnected ? (
      <MCHeaderStyled>
        {showCloseBtn && (
          <div className='close-btn' onClick={handleCloseBtn}>
            X
          </div>
        )}
        <div className='preSaleNonWaitListedSection'>
          <div className='preSaleNonWaitListedHeading'>
            Pre-sale event by prior reservation only.
          </div>
          <div className='sub-heading'>Enrollment opens in:</div>
          <Timer targetDate={preSaleDate} />
        </div>
      </MCHeaderStyled>
    ) : (
      <MCHeaderStyled>
        {showCloseBtn && (
          <div className='close-btn' onClick={handleCloseBtn}>
            X
          </div>
        )}
        <div
          className='section'
          style={{ marginTop: `${showCloseBtn ? '-2rem' : '0rem'}` }}
        >
          <div className='heading'>{heading}</div>
          <div className='text'>{text}</div>
        </div>
      </MCHeaderStyled>
    );

  useEffect(() => {
    contractStore._userAddr = address;
    fetchInitData();
    if (contractStore.mintResponse) {
      setShowTransactionModal(false);
      setShowErrorSection(true);
      setShowMintContent(false);
      setShowBenefits(false);
      setShowReviewSection(false);
    }
    if (contractStore.mintReceipt) {
      setShowTransactionModal(false);
      setShowErrorSection(false);
      setShowMintContent(false);
      setShowBenefits(false);
      setShowReviewSection(false);
      setShowSuccessModal(true);

      // Save referral code in some backend
      console.log(referralCode);
      if(referralCode){
        void saveReferralCode({ referral_code: referralCode, wallet_address: address });
      }
    }
  }, [
    address,
    provider,
    isConnected,
    contractStore.mintResponse,
    contractStore.mintReceipt,
    walletStore.isWaitListed,
    // Need to add reserved API also
  ]);

  return (
    <>
      <MintContainerStyled
        style={{
          filter: `${showTransactionModal ? 'opacity(0.5)' : 'opacity(1)'}`,
        }}
      >
        {header}
        {showMintContent && mintContent}
        {showReviewSection && reviewSection}
        {showErrorSection && errorSection}
        {showBenefits && benefitSection}
        {showSuccessModal && successSection}
      </MintContainerStyled>
      {showTransactionModal && modalSection}
    </>
  );
});

export default React.memo(MintContainer);
