/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import styled from 'styled-components';
import { NFTArtStyled, PopUpArtStyled } from './style';
import Header from '../Header';
import tierOnebadge from '../../assets/tier-1-badge.png';
import tierTwobadge from '../../assets/tier-2-badge.png';
import tierOnePlusbadge from '../../assets/tier-1-plus-badge.png';
import Modal from '../Modal';
import WalletButton from '../WalletButton';

function NFTArt() {
  const nftList = [
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier+1/GrainDelivery_Tier1_Rare.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier+1/GrainDelivery_Tier1_Rare_thumb.jpg',
      tier: 1,
      name: 'Grain Delivery',
      rarity: 'Rare',
      description: `The grain delivery truck rolled up to the whiskey distillery, and the driver hopped out to unload the sacks of corn. "Hey, Mac," he called to the distillery worker who was waiting for him. "I've got your order all ready for you.",

    Mac nodded in appreciation. He always enjoyed working with this particular driver; he was witty and charming, and always had a good story to tell. "Thanks, Tom," Mac said as he took the sacks of corn from the truck. "I'll get these unloaded right away."

    Tom smiled and hopped back in his truck. As he drove away, he couldn't help but wonder what new concoction Mac would come up with using this latest shipment`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier+1/TheBarrelRaising_Tier1_Rare.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier+1/TheBarrelRaising_Tier1_Rare_thumb.jpg',
      tier: 1,
      name: 'The Barrel Raising',
      rarity: 'Rare',
      description:
        'This is the heart piece of the barrel production. Every barrel raiser assembles 31 to 33 wide and narrow staves into a temporary steel ring that holds the staves into place. They have to ensure that the broad and narrow staves are distributed evenly around the circle. Otherwise, the forces that hold the barrel together will also be uneven. In that case, the areas with less pressure are likely to leak.',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier+1/TheBarrelFinishing_Tier1_Rare.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier+1/TheBarrelFinishing_Tier1_Rare_thumb.jpg',
      tier: 1,
      name: 'The Barrel Finishing',
      rarity: 'Rare',
      description:
        'During this stage, the barrel takes on its definitive shape, both on the outside and inside. The wood’s silky sheen is acquired after a first sand-papering. The hoops used for the raising process, and also used to help the cooper in almost all of the barrel-making steps, are removed to make way for the definitive hoops that are made of galvanised steel. The beauty and the grain pattern of the wood, the nobility of American oak in all its quintessence, brings the final hand-crafted touch once the final sand-papering has been done. In the manner of a master-craftsman, proud of his work of art, the cooper adds his initials on the chimb of the barrel. The second life of these precious American oak essences can now begin - in contact with the best whiskey.',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier+1/TheBarrelCharring_Tier1_Rare.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier+1/TheBarrelCharring_Tier1_Rare_thumb.jpg',
      tier: 1,
      name: 'The Barrel Charring',
      rarity: 'Rare',
      description:
        "During the charring of the barrels, they are burned from the inside with an open flame. The process only burns the very top of the wooden surface, and the charcoal layer acts as a filter. The charcoal reacts with the sharp substances of the white dog (raw whiskey), taking out unwanted flavors of the whiskey. Charring has different levels, all of which contribute differntly to the whiskey's final flavor profile.",
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier+1/Malting_Mashing_Tier1_Rare.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier+1/Malting_Mashing_Tier1_Rare_thumb.jpg',
      tier: 1,
      name: 'Malting & Mashing',
      rarity: 'Rare',
      description:
        'All whisky starts as raw grain, which has to be specially treated to access its sugars. The process is called malting. Then sugars contained in the grain must be extracted before fermentation, which is done through mashing. The grains, like corn, wheat, or rye, are ground up, put in a large tank with hot water, and agitated. Even if the distiller isn’t making malt whisky, some ground malted barley is typically added to help catalyze the conversion of starches to sugars. The resulting mixture resembles porridge. Once as much sugar as possible has been extracted, the mixture - now known as a mash - moves on to the fermentation stage.',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier+1/Fermentation_Tier1_Rare.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier+1/Fermentation_Tier1_Rare_thumb.jpg',
      tier: 1,
      name: 'Fermentation',
      rarity: 'Rare',
      description:
        'Fermentation occurs when the mash meets yeast, which gobbles up all the sugars in the liquid and converts them to alcohol. This takes place in giant vats. The process can take anywhere from 48 to 96 hours, with different fermentation times and yeast strains resulting in a spectrum of diverse flavors. The resulting beer-like liquid - called distiller’s beer -clocks in at around 7%-10% ABV before it goes into the still',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier+1/Distillation_Tier1_Rare.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier+1/Distillation_Tier1_Rare_thumb.jpg',
      tier: 1,
      name: 'Distillation',
      rarity: 'Rare',
      description: `The process of distilling increases the alcohol content of the liquid and brings out volatile components, both good and bad. Stills are usually made of copper, which helps strip spirits of unwanted flavor and aroma compounds. The two most common stills - pot and column still - function differently.

        Pot stills (depicted) are used in the production of whiskies—usually, though not always, malt whiskies—from Scotland, Ireland, the United States, Canada, Japan, and elsewhere. Pot still distillation is a batch process. Some styles use double-distillation, while others are distilled three times.

        Column stills, also known as continuous or Coffey stills, are typically used to produce bourbon, rye, and other American whiskeys, as well as grain whiskies from Scotland, Ireland, Canada, Japan, and elsewhere. The column still works continuously and efficiently, removing the need for the batch process of pot stills.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier+1/BarrelRolling_Tier1_Common.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier+1/BarrelRolling_Tier1_Common_thumb.jpg',
      tier: 1,
      name: 'Barrel Rolling',
      rarity: 'Common',
      description:
        'Nearly all whiskies are aged in wood—usually oak—containers. One notable exception is corn whiskey, which may be aged or unaged. Bourbon, rye, and other types of American whiskey must be aged in new charred oak barrels, while for other countries’ styles, the type of oak and its previous use are generally left up to the producer. Barrels are stored in warehouses, and as the whisky matures, some of the alcohol evaporates: This is known as the angels’ share, and it creates a distinct (and lovely) smell in the warehouse. Some whiskies, such as scotch, have a required minimum age.',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier+1/GettingReady_Tier1_Rare.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier+1/GettingReady_Tier1_Rare_thumb.jpg',
      tier: 1,
      name: 'Getting Ready',
      rarity: 'Rare',
      description:
        'Once matured, whisky is bottled at a minimum of 40% ABV. The whisky may be chill-filtered or filtered in another way to prevent it from becoming cloudy when cold water or ice is added. For most large whisky brands, a bottling run combines a number of barrels—anywhere from a few dozen to hundreds—from the distillery’s warehouses. When only one barrel is bottled at a time, it’s labeled as single cask or single barrel, which is what we taste and select as a club!',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier+1/IntoTheWild_Tier1_Rare.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier+1/IntoTheWild_Tier1_Rare_thumb.jpg',
      tier: 1,
      name: 'Into The Wild',
      rarity: 'Rare',
      description:
        'It was a hot day, and the whiskey bottles were loaded on the truck for distribution. The driver, Joe, had been doing this for years and knew the route well. He cracked a window to let in some air, but it wasn\'t enough. The sweat beaded on his forehead, and he wiped it with his sleeve. The bottles clinked together as he drove, making a soothing noise that helped him focus on the road. "Soon, " Joe thought, "someone will be forging life-long relationships over a sip of this precious cargo.',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ProcessScene12_Retail.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ProcessScene12_Retail_thumb.jpg',
      tier: 2,
      name: 'Ready for Showtime',
      rarity: 'Rare',
      description:
        "After a labourous process of making the whiskey, the final product is fianlly ready to meet it's fans as it arrives at rtetail shops, bars, and restaurants. Once purchased, it is ready to serve as a conduit to forming life-long erlationships.",
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene1_Distiller-Apprentice.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene1_Distiller-Apprentice_thumb.jpg',
      tier: 2,
      name: 'First Dibs',
      rarity: 'Rare',
      description: `The distiller and apprentice are standing in the rickhouse enjoying a few glasses of whiskey. The distiller tells the apprentice about the process of making whiskey and how much work goes into it. The apprentice is fascinated by everything the distiller tells him, and he is excited to learn more.

        The two of them sat there for hours, talking and sipping whiskey. They shared stories and laughed together. It was a warm evening, and they were both contented just sitting in the rickhouse, enjoying a dram toghether.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene2_Speakeasy.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene2_Speakeasy_thumb.jpg',
      tier: 2,
      name: 'Speakeasy',
      rarity: 'Rare',
      description: `It was a dark and stormy night, and three friends had gathered to drink and smoke cigars in a speakeasy. The room was dimly lit, with only a few candles providing light. The air was thick with the smell of whiskey and cigar smoke.

        The friends were all talking and laughing, enjoying each other's company. They had known each other for years, and they were like family. They shared stories and jokes, and they laughed until their bellies hurt.
        
        Eventually, they grew quiet as they sipped their whiskey and smoked their cigars. There was a comfortable silence between them, one that was filled with fond memories and good company.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene3-FarmSetting.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene3-FarmSetting_thumb.jpg',
      tier: 2,
      name: 'Taking a break',
      rarity: 'Rare',
      description:
        'It was a hot day, and the farmers were out in the field enjoying a nice pour of whiskey. As they relaxed in the shade of the trees, they talked and laughed about their lives. They recounted old stories and shared new ones. They enjoyed the peace of the farm and the company of their friends.',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene4_UpNorth.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene4_UpNorth_thumb.jpg',
      tier: 2,
      name: 'Snowy Cabin',
      rarity: 'Rare',
      description:
        'A couple sits in the cabin, drinking whiskey by the fire. They are both warm and happy. Outside, the snow falls gently on the trees, making everything look like a winter wonderland. She closes her eyes and let out a contented sigh. This is perfect.',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene5_Campfire.png',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene5_Campfire.png',
      tier: 2,
      name: 'Campfire',
      rarity: 'Rare',
      description: `The fire crackling was soothing, and the friends enjoyed their whiskey around the campfire. It was a cool fall evening; they were all content to relax and chat.

      Some funny stories from their past, while others shared exciting plans for the future. They all laughed and joked around, enjoying each other's company.
      
      As the night fell, the conversation slowed, but everyone remained seated around the fire. There was a feeling of warmth and comfort that came from being together like that. They sat in silence for a while before finally getting up to go back inside. They had all had a good time and would remember this night for years to come.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene6_HotTub.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene6_HotTub_thumb.jpg',
      tier: 2,
      name: 'Hot Tub Whiskey Machine',
      rarity: 'Rare',
      description: `The sun had just set, and the cool breeze was a welcome relief from the day's heat. Jim and Mary were sitting in their hot tub, enjoying a few glasses of whiskey. They had both had a long day at work, and they were looking forward to relaxing together.

      They sipped their drinks and talked about their day. They shared stories and jokes, and soon they were both laughing. The warm water and the alcohol made them feel relaxed and happy.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene7_LakeBoat.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene7_LakeBoat_thumb.jpg',
      tier: 2,
      name: 'Cruising',
      rarity: 'Rare',
      description: `The sun was setting in the sky, and the cool breeze was blowing across the lake. The three friends were all relaxing on the boat, enjoying their whiskey. They had been out on the lake for hours, and they were just about to head back home.

        Suddenly, a storm started brewing. The wind started picking up speed, the rain started pouring down. The friends were all getting scared, and they didn't know what to do.
        
        Fortunately, they managed to make it back to shore before the boat capsized. They were all soaking wet, but they were safe. They quickly went inside and dried off, then they sat around the fire and laughed about their close call.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene8_Golf.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene8_Golf_thumb.jpg',
      tier: 2,
      name: 'Links',
      rarity: 'Rare',
      description: `The four friends were all in their early-20s and loved  nothing more than spending a Saturday afternoon out the golf course, enjoying playing for a now on the 18th hole.

      The final hole was a one, and it wasn't long before they were all tied up. It came down to the last shot, and who would win?
      
      In the end, it was John who won with a great putt. As they walked off the green, John cracked open a special bottle of whisky for his friends he was saving just for such an occasion.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene9_SouthernBrothers.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene9_SouthernBrothers_thumb.jpg',
      tier: 2,
      name: 'American Dream',
      rarity: 'Rare',
      description: `The brothers sat on the porch, enjoying the peace and quiet. The sun was setting and the sky was ablaze with colors They each took a sip of whiskey, savoring the smooth taste.

      "It's good to just sit here and relax," one of the brothers said.
      
      "Yeah," the other replied. "I can't remember the last time we just chilled like this."
      
      They rocked back and forth in their chairs, taking in the beauty of the world around them. A cool breeze blew through, rustling the leaves in the trees. The sound was soothing, a reminder that everything was okay.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene11_Skiiers.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene11_Skiiers_thumb.jpg',
      tier: 2,
      name: 'Radical Skis',
      rarity: 'Rare',
      description: `The skiers were all taking a break from the slopes, enjoying a dram at the ski lodge. One of them, a young woman, said happily, "I love skiing in the wintertime - serenity of the fresh powder, great company, and fresh air. This is my heaven."

      Her friend nodded knowingly. "Yeah, I know what you mean. It's hard not to love skiing with friends during the holidays."`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene12_Reading.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene12_Reading_thumb.jpg',
      tier: 2,
      name: 'The Study',
      rarity: 'Rare',
      description:
        'Brandon sits in his leather armchair by the fireplace, reading a book and sipping whiskey. The fire crackles and pops, providing him warmth and light on this cold winter night. He closes the book, takes another sip of whiskey, and leans back in his well-worn-out leather chair to relax. As he does, he notices the smoke from the fire swirling around the room like ghosts. He watches them for a while, and they disappear into the air.',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene13_BBQ.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene13_BBQ_thumb.jpg',
      tier: 2,
      name: 'BBQ',
      rarity: 'Rare',
      description: `"Hey, pass me another one of those," Bill called out to his friend as he flipped the burgers on the grill. It was a perfect day for a BBQ, and they were both enjoying the company and some good whiskey.

      "You got it," Harry replied as he handed his friend the bottle. They continued to chat and drink, making small talk about work and life.
      
      Suddenly, Bill's wife called out from inside that dinner was ready. "I guess it's time to eat," Harry said as he stood up. "It was great catching up with you."
      
      "Yeah, same here," Bill replied. "We should do this again soon."`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene14_EamesMusic.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene14_EamesMusic_thumb.jpg',
      tier: 2,
      name: 'Audiophile',
      rarity: 'Rare',
      description:
        'Casey sits in her Eames chair, listening to music and sipping whiskey. The sun is setting, and the light shines through the window, casting a warm glow on everything in the room. She closes her eyes and lets the music wash over her, transporting her to another place. She sips her whiskey and enables the warmth to spread through her. Everything is perfect.',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene16_CrescentBeachjpg.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene16_CrescentBeachjpg_thumb.jpg',
      tier: 2,
      name: 'Secret Mission',
      rarity: 'Rare',
      description: `I sit on the beach, looking out at the ocean. I'm on a secret military mission, and I'm not supposed to be here. The sand is warm, the sun is shining, and I have my whiskey flask hanging next to my gun. I'm too worried about what's going on back at base.

      Suddenly, I hear a noise. I tense up, ready to run or fight if necessary. But it's just a seagull. The bird lands nearby and starts pecking at the sand on my feet.
      
      I watch it for a while, then reach down and pet its head. It coos softly and looks up at me with its bright blue eyes. Suddenly, all my worries seem to fade away`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene17_Dad%2BSon_Lake.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene17_Dad%2BSon_Lake_thumb.jpg',
      tier: 2,
      name: 'Generations',
      rarity: 'Rare',
      description: `"I don't know, son. I just don't know." 

        The words echoed in the forest, the only other company being the occasional ripple on the lake's surface. They'd been sitting there for hours since they'd finished their bottle of whiskey. It started as a conversation about what to do next in life, but it quickly turned into a debate that neither could win.
        
        "Dad, you've been saying that for years now. We can't just keep living like this," his son pleaded. "It's not fair to Mom or me." 
        The man took a long sip from his glass, letting the liquid burn down his throat before responding.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene10_YumaAZ.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene10_YumaAZ_thumb.jpg',
      tier: 2,
      name: 'River Bend',
      rarity: 'Rare',
      description: `The sun was setting in the sky, painting the world in a beautiful orange hue. Randall sat on the beach chair with his feet in the river, sipping whiskey and enjoying the view. He had always loved Southwest settings- there was something about the vastness of the landscape that called to him.

      Suddenly, he heard a rustling in the bushes. He tensed up, but then relaxed when he saw a small rabbit hop out from behind the rocks. The rabbit looked at Randall for a moment before hopping away again. Randall took another sip of whiskey and smiled; it was good to be alive.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/01.Tier%201/GrainHarvest_Tier1_Rare.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/01.Tier%201/GrainHarvest_Tier1_Rare_thumb.jpg',
      tier: 1,
      name: 'Grain Harvest',
      rarity: 'Rare',
      description:
        'In today’s modern age, it’s easy to forget that distillation depends on agriculture at its core. With regards to whiskey, we are talking about grain. While the rye grown back east was being turned into rye whiskey, Kentucky-grown corn nourished bourbon’s nascent days. Farmers initially distilled the excess grains they grew, then switched to source grain from other farms, often industrial-sized. Lately, however, the grain-to-glass concept has taken flight again with estate-grown grain that adds uniqueness of terror on which it is grown to the final product (i.e., whiskey). This is where our art journey begins as well. ',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/00.Tier+1+Plus/Whiskey+Thief+Iterations/V1.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/00.Tier+1+Plus/Whiskey+Thief+Iterations/V1_thumb.jpg',
      tier: '1-PLUS',
      name: 'Whiskey Thief',
      rarity: 'Rare',
      description:
        'The group uses a whiskey "thief" to taste the whiskey straight from the barrels, looking for the best ones to pick. We have been doing this for years, and we know which barrels are the most flavorful. Join us, and you could be the next one tasting these along with us!',
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ProcessSceneUltimate2_BarrelTasting.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ProcessSceneUltimate2_BarrelTasting_thumb.jpg',
      tier: 2,
      name: 'Tasting Committee',
      rarity: 'Common',
      description: `The Prime Barrel Single Barrel Whiskey ClubTasting is one of the most exclusive clubs in the country. Membership is by invitation only, and you have to be a whiskey connoisseur to join. The club meets at different venues to taste and discuss different whiskeys.

      Tonight, however, was different. Michael organized a trip to the distillery to taste various whiskey barrels, hoping to find one that all members would love/agree on. He had arranged for a few rare barrels he had been saving for this occasion. The other members started arriving one by one, and soon the tasting room was filled with men and women sipping on whiskey and discussing its nuances.
      
      As they tasted each barrel, Michael could see the approval on their faces when they got to one particular barrel. This was his moment of glory; it was the star of the show.`,
      popUp: false,
    },
    {
      img: 'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene15_Poolside.jpg',
      thumbImg:
        'https://assets.primebarrel.com/Scenes/02.Tier+2/ConsumpScene15_Poolside_thumb.jpg',
      tier: 2,
      name: 'Family Time',
      rarity: 'Rare',
      description:
        'The sun was beating down on the pool, and the sound of children\'s laughter rang the air. Parents were sitting around in lawn chairs, watching their kids swim and play. In one corner, a dad was smoking cigar and sipping from his favorite rocks glass. He smiled as he watched his son swim laps. Suddenly, she came up for and shouted, "Dad! I\'m going to race you!" The dad chuckled and said, "You\'re on!" He quickly finished his drink and jumped into the pool. His daughter squealed with delight as they raced across the surface of the water.',
      popUp: false,
    },
  ];

  const [showNFTList, setShowNFTList] = useState(nftList);
  const [currentImage, setCurrentImage] = useState(nftList[0]);
  const [showPopUp, setShowPopUp] = useState(false);

  const handlePopUp = (index) => {
    // const newArray = [...showNFTList];
    // newArray[index] = {
    //   ...newArray[index],
    //   popUp: !newArray[index].popUp,
    // };
    // setShowNFTList(newArray);
    setCurrentImage(showNFTList[index]);
    setShowPopUp(true);
  };

  return (
    <NFTArtStyled id='NFT-Art'>
      <div className='container'>
        <Header
          headingOne='The Artwork'
          headingTwo='Club Art'
          rowGap='25px'
          headerWidth='898px'
          headerHeight='210px'
        />
        <div className='sub-heading'>
          <div className='heading ff-NL fw-700'>
            Overview benefits: What you get
          </div>
          <div className='text ff-CG fw-400'>
            While the value proposition of this membership will be mainly in its
            perks (see{' '}
            <a href='#Benifits-Pricing' className='ff-BO CTA'>
              Benefits & Pricing
            </a>{' '}
            section), we want art to be still beautiful and appealing to both
            consumers and distilleries alike. You’ll find our art very different
            from “traditional” NFT art, which is usually the same design done in
            various permutations.
            <br />
            <br />
            The idea is to depict the laborious process of whiskey making and
            highlight whiskey's unique property as a relationship-building
            conduit. We are developing a “grain-to-glass”, Where-Is-Waldo type
            art canvas, which will showcase the entire process of making,
            consuming, and enjoying the whiskey.
          </div>
        </div>
        <div className='image-gallery-test'>
          {nftList.map((obj, index) => (
            <img
              key={obj.thumbImg}
              src={obj.thumbImg}
              alt={obj.name}
              className='image'
              loading='lazy'
              onClick={() => handlePopUp(index)}
            />
          ))}
        </div>
        {/* <div className='image-gallery'>
          <div className='four-image-row row-one'>
            {rowOne.map((obj, index) => (
              <img
                src={obj.img}
                alt={obj.name}
                className='image'
                onClick={() => handleRowOnePopUp(index)}
              />
            ))}
          </div>
          <div className='four-image-row row-two'>
            {rowTwo.map((i, index) => (
              <img
                src={i.img}
                alt={i.name}
                className='image'
                onClick={() => handleRowTwoPopUp(index)}
              />
            ))}
          </div>
          <div className='four-image-row row-three'>
            {rowThree.map((i, index) => (
              <img
                src={i.img}
                alt={i.name}
                className='image'
                onClick={() => handleRowThreePopUp(index)}
              />
            ))}
          </div>
          <div className='four-image-row row-four'>
            {rowFour.map((i, index) => (
              <img
                src={i.img}
                alt={i.name}
                className='image'
                onClick={() => handleRowFourPopUp(index)}
              />
            ))}
          </div>
          <div className='four-image-row row-five'>
            {rowFive.map((i, index) => (
              <img
                src={i.img}
                alt={i.name}
                className='image'
                onClick={() => handleRowFivePopUp(index)}
              />
            ))}
          </div>
          <div className='four-image-row row-six'>
            {rowSix.map((i, index) => (
              <img
                src={i.img}
                alt={i.name}
                className='image'
                onClick={() => handleRowSixPopUp(index)}
              />
            ))}
          </div>
          <div className='four-image-row row-seven'>
            {rowSeven.map((i, index) => (
              <img
                src={i.img}
                alt={i.name}
                className='image'
                onClick={() => handleRowSevenPopUp(index)}
              />
            ))}
          </div>
          <div className='three-image-row row-eight'>
            {rowEight.map((i, index) => (
              <img
                src={i.img}
                alt={i.name}
                className='image'
                onClick={() => handleRowEightPopUp(index)}
              />
            ))}
          </div>
        </div> */}
      </div>
      {/*
      {showNFTList.map(
        (obj, index) =>
          obj.popUp && (
            <div className='popup-modal'>
              <button
                type='button'
                className='close-btn'
                onClick={() => handlePopUp(index)}
              >
                <span className='text ff-CG ff-400'>X</span>
              </button>
              <div className='image-holder'>
                <img src={obj.img} alt='' />
                {obj.tier === 1 && (
                  <img
                    src={tierOnebadge}
                    alt=''
                    className='badge'
                    style={{ width: '123px', height: '123px' }}
                  />
                )}
                {obj.tier === 2 && (
                  <img
                    src={tierTwobadge}
                    alt=''
                    className='badge'
                    style={{ width: '123px', height: '123px' }}
                  />
                )}
                {obj.tier === '1-PLUS' && (
                  <img
                    src={tierOnePlusbadge}
                    alt=''
                    className='badge'
                    style={{ width: '123px', height: '123px' }}
                  />
                )}
              </div>
              <div className='desc-holder'>
                <div className='name'>{obj.name}</div>
                <div className='tier'>
                  Tier {obj.tier}: {obj.rarity}
                </div>
                <div className='desc'>{obj.description}</div>
              </div>
            </div>
          )
      )} */}

      <Modal show={showPopUp} onClose={() => setShowPopUp(false)}>
        <PopUpArtStyled>
          <div className='popup-modal'>
            <div className='image-holder'>
              <img src={currentImage.img} alt='' />
              {currentImage.tier === 1 && (
                <img
                  src={tierOnebadge}
                  alt=''
                  className='badge'
                  style={{ width: '123px', height: '123px' }}
                />
              )}
              {currentImage.tier === 2 && (
                <img
                  src={tierTwobadge}
                  alt=''
                  className='badge'
                  style={{ width: '123px', height: '123px' }}
                />
              )}
              {currentImage.tier === '1-PLUS' && (
                <img
                  src={tierOnePlusbadge}
                  alt=''
                  className='badge'
                  style={{ width: '123px', height: '123px' }}
                />
              )}
            </div>
            <div className='desc-holder'>
              <div className='name'>{currentImage.name}</div>
              <div className='tier'>
                Tier {currentImage.tier}: {currentImage.rarity}
              </div>
              <div className='desc'>{currentImage.description}</div>
              <WalletButton
                action='CONNECT_AND_WHITELIST'
                defaultText='Join Now'
              />
            </div>
          </div>
        </PopUpArtStyled>
      </Modal>
    </NFTArtStyled>
  );
}

export default NFTArt;
