/* eslint-disable react/no-danger */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Carousel } from 'react-responsive-carousel';
import { TestimonialsStyled } from './style';
import React, { useMemo } from 'react';
import VimeoPlayer from '../VimeoPlayer';

function TestimonialsSlide({ slide }) {

  const videoPlayerList = useMemo(() => {
    return (slide.testimonials.map((testimonial) => (
      <div className='testimonials-container' key={`testimonials-slider-${testimonial.author}`}>
        <VimeoPlayer videoId={testimonial.video} config={{controls: false}} overlay={testimonial.author} />
      </div>
    )));
  }, [slide.testimonials]);

  return (
    <TestimonialsStyled fontSize={slide.fontSize} contentWidth={slide.contentWidth}>
      <div className='head-shot-container'>
        {videoPlayerList}
      </div>
      <div className='head-shot-container-mobile'>
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          swipeable={false}
        >
          {videoPlayerList}
        </Carousel>

      </div>
      <a
        href={slide.linkAddress}
        style={{ marginTop: '40px', fontSize: '14px' }}
      >
        {slide.link}
      </a>
    </TestimonialsStyled>
  );
}

export default TestimonialsSlide;
