/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useAccount } from 'wagmi';
import { observer } from 'mobx-react-lite';
import tier1Badge from '../../assets/tier-1-badge.png';
import tier1plusBadge from '../../assets/tier-1-plus-badge.png';
import tier2Badge from '../../assets/tier-2-badge.png';
import Header from '../Header';
import AddMore from './AddMore';
import FreeBottle from './FreeBottle';
import ShippingModal from './modal/shippingModal';
import ThanksModal from './modal/ThanksModal';
import { useStore } from '../../store/index';
import { shippingAddressExists } from '../../service/api';
import opensea from '../../assets/opensea.png';

const MintNFTS = observer(() => {
  const [nfts, setNfts] = useState([]);
  const [fetchedTokens, setTokens] = useState([]);
  const [modal, setModal] = useState(false);
  const [thanksModal, setThanksModal] = useState(false);
  const [shippingDataExist, setShippingDataExist] = useState(true);
  const { contractStore } = useStore();
  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (!isConnected) return;

    shippingAddressExists(address).then((res) => {
      if (res.status === 200 && res.data.exist) {
        setShippingDataExist(true);
      } else {
        setShippingDataExist(false);
      }
    });

    contractStore.getTokensOfOwner(address).then(async (tokens) => {
      setTokens(tokens);
      const metadataPromises = tokens.map((tokenID) =>
        fetch(`https://assets.primebarrel.com/Metadata/jsons/${tokenID}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        })
          .then((res) => res.json())
          .then((res) => ({ ...res, id: parseInt(tokenID, 10) }))
      );

      const data = await Promise.all(metadataPromises);

      setNfts(data);
    });
  }, [address, contractStore.userMintedCount]);

  return (
    fetchedTokens.length > 0 && (
      <Container>
        <Header
          headingOne='Thank you for joining our club!'
          headingTwo={nfts.length > 0 ? 'Your NFT Gallery' : ''}
          headerWidth='100%'
          rowGap='25px'
        />
        {nfts.length > 0 && (
        <SubHeading>
          Below you can see your personal NFT gallery. This is where you can
          view your membership NFT and see additional options below. Enjoy!
        </SubHeading>
        )}

        <HrLine />
        {nfts.length > 0 && (
        <MainDiv>
          {nfts.map((e, index) => (
            <MainBox nftCount={nfts.length} key={`nft-mint-${index}`}>
              <BorderBox>
                <Head nftCount={nfts.length}>
                  {e.id >= 0 && e.id <= 7
           ? 'Tier 1 Plus: Single Barrel Savant Plus'
           : e.id >= 8 && e.id <= 200
           ? 'Tier 1: Single Barrel Savant'
           : e.id >= 201 && e.id <= 2000
           ? 'Tier 2: Single Barrel Advocate'
           : null}
                </Head>
                <SubHead nftCount={nfts.length}>Quantity: 1</SubHead>
                <Content>
                  <Badge
                    nftCount={nfts.length}
                    src={
             e.id >= 0 && e.id <= 7
               ? tier1plusBadge
               : e.id >= 8 && e.id <= 200
               ? tier1Badge
               : e.id >= 201 && e.id <= 2000
               ? tier2Badge
               : null
           }
                  />
                  <Image nftCount={nfts.length} src={e.image} />
                </Content>
                <OpenSea>
                  <Text>See it now on:</Text>
                  <OpenSeaImage
                    onClick={() => {
             window.open(
               `https://opensea.io/assets/ethereum/0x8198D85fC1Bd0D6B626Fe58cc93d8CDb2347915A/${e.id}`
             );
           }}
                    src={opensea}
                  />
                </OpenSea>
              </BorderBox>
            </MainBox>
 ))}
        </MainDiv>
        )}

        {!shippingDataExist && <FreeBottle setModal={setModal} />}
        {modal && (
          <ShippingModal setModal={setModal} setThanksModal={setThanksModal} />
        )}
        {thanksModal && (
          <ThanksModal
            setThanksModal={setThanksModal}
            setShippingDataExist={setShippingDataExist}
          />
        )}
        <AddMore />
      </Container>
    )
  );
});

export default MintNFTS;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const SubHeading = styled.div`
  font-family: 'Century Gothic';
  line-height: 2rem;
  font-size: 24px;
  text-align: center;
  color: #0a3047;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 890px;
  @media (max-width: 800px) {
    font-size: 14px;
    width: 90vw;
  }
`;

const HrLine = styled.div`
  width: 1053px;
  background-color: #4c0d18;
  height: 0.8px;
  margin: auto;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    width: 80%;
  }
`;

const MainBox = styled.div`
  width: 80%;
  margin: auto;
  background-color: #fbf1e6;
  border-radius: 20px;
  padding: 20px;
  @media (max-width: 800px) {
    width: 90%;
    ${(props) =>
      props.nftCount > 1 &&
      css`
        width: 178px;
        padding: 8px;
        margin-bottom: 15px;
      `}
  }
  ${(props) =>
    props.nftCount === 2 &&
    css`
      width: 512px;
    `}
  ${(props) =>
    props.nftCount >= 3 &&
    css`
      width: 429px;
      margin-top: 20px;
    `}
`;

const BorderBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Head = styled.h1`
  font-family: Nielmaden;
  font-weight: Bold;
  font-size: ${(props) => (props.nftCount >= 3 ? '34px' : '40.62px')};
  line-height: 120%;
  color: #0a3047;
  text-align: center;
  @media (max-width: 800px) {
    font-size: ${(props) => (props.nftCount > 1 ? '15px' : '16.4px')};
  }
`;

const SubHead = styled.h3`
  font-family: Century Gothic;
  font-weight: Bold;
  font-size: ${(props) => (props.nftCount >= 3 ? '26.82px' : '32.08px')};
  text-align: center;
  color: #fd7835;
  margin-top: -10px;
  @media (max-width: 800px) {
    font-size: ${(props) => (props.nftCount > 1 ? '11.88px' : '12.94px')};
  }
`;

const Content = styled.div`
  position: relative;
  margin-top: 85px;
  z-index: 0;
  @media (max-width: 800px) {
    margin-top: 41px;
  }
`;

const Image = styled.img`
  width: ${(props) => (props.nftCount >= 3 ? '307.19px' : '382px')};
  height: ${(props) => (props.nftCount >= 3 ? '307.19px' : '382px')};
  border-radius: 33px;
  position: relative;
  @media (max-width: 800px) {
    width: ${(props) => (props.nftCount > 1 ? '141.44px' : '154px')};
    height: ${(props) => (props.nftCount > 1 ? '141.44px' : '154px')};
  }
`;

const Badge = styled.img`
  width: ${(props) => (props.nftCount >= 3 ? '98.11px' : '122px')};
  height: ${(props) => (props.nftCount >= 3 ? '98.11px' : '122px')};
  position: absolute;
  z-index: 1;
  top: ${(props) => (props.nftCount >= 3 ? '-79px' : '-87px')};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 800px) {
    width: ${(props) => (props.nftCount > 1 ? '45.11px' : '49.5px')};
    height: ${(props) => (props.nftCount > 1 ? '45.11px' : '49.5px')};
    top: -33px;
  }
`;

const MainDiv = styled.div`
  display: flex;
  width: 1310px;
  flex-wrap: wrap;
  @media (max-width: 800px) {
    flex-wrap: wrap;
    width: auto;
  }
`;

const OpenSea = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;
const Text = styled.p`
  font-family: Century Gothic;
  font-weight: Bold;
  font-size: 17.21px;
  line-height: 100%;
  color: #0a3047;
  text-align: center;
`;
const OpenSeaImage = styled.img`
  cursor: pointer;
`;
