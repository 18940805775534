/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './style.css';
import { CrossmintPayButton } from '@crossmint/client-sdk-react-ui';
import { observer } from 'mobx-react-lite';
import stores from '../../store';

const DollarMint = observer((props) => {
  const { contractStore } = stores;
  const { count } = props;

  const tierName = props.tier?.tierName;
  let clientId;
  if (tierName === 'Tier 1 Plus') {
    clientId = '38f84485-b485-426c-92cc-fdca644661e5';
  } else if (tierName === 'Tier 1') {
    clientId = 'e40110b7-0d9f-45a0-8ea9-3940d73e5c60';
  } else {
    clientId = 'a49e4249-658d-492f-810e-db27815adf2f';
  }

  const updateSupply = () => {
    setTimeout(() => {
      const isExist = document.getElementById('__crossmint-overlay__');
      if (!isExist) {
        document.documentElement.removeEventListener(
          'DOMNodeRemoved',
          updateSupply,
          false
        );
        contractStore.getDataFromContract(contractStore.contractData);
      }
    }, 500);
  };

  const isCrossMintClosed = () => {
    document.documentElement.addEventListener(
      'DOMNodeRemoved',
      updateSupply,
      false
    );
  };

  return (
    <div className='cross-mint' onClick={isCrossMintClosed}>
      <CrossmintPayButton
        collectionTitle='The Prime Barrel'
        collectionDescription='Single Barrel Picking Society'
        collectionPhoto='https://cdn.shopify.com/s/files/1/0565/2162/1686/products/c335197b7893b522da4c209b2ba7e766f3aa20d1_640x.jpg'
        clientId={clientId}
        // environment='staging'
        environment='production'
        mintConfig={{
          type: 'erc-721',
          totalPrice: `${contractStore.getReadableMintPrice(tierName) * count}`,
          _count: `${count}`,
        }}
        mintTo={contractStore.userAddr}
        className='buy-dollar'
      />
    </div>
  );
});

export default React.memo(DollarMint);
