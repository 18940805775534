import { partnersFirstLine, partnersSecondLine } from './partnerStickers/partnersDesktop';
import {
  partnersFirstLine as partnersFirstLineMobile,
  partnersSecondLine as partnersSecondLineMobile,
  partnersThirdLine as partnersThirdLineMobile,
} from './partnerStickers/partnersMobile';


export default [
  {
    id: 1,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs/BG-1.png',
    headingOne: 'The Prime Barrel Premium Membership',
    headingTwo: 'Join The First Web3 Whiskey Club',
    headingOneFontSize: '18px',
    headingTwoFontSize: '64px',
    headerWidth: '898px',
    paraOne:
      'The Prime Barrel’s Web 3 Single Barrel Whiskey Club™ is the first whiskey club to offer our memberships in the form of NFTs. The lifetime membership will be uniquely yours, cannot be replicated, easy to transfer, and entitles you to unique benefits.',
    paraTwo:
      '<br/>Don’t worry - you don’t need to own (or know) crypto to join!',
    fontSize: '24px',
    contentWidth: '840px',
    showWalletButton: true,
    TM: true,
    rowGap: '25px',
    info: '*You will be able to change selection later.',
  },
  {
    id: 2,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs/BG-5.png',
    subCarousel: [
      {
        id: 21,
        headingOne: 'Savor Every Sip with Our Virtual Whiskey Tasting Kits',
        headingTwo: 'Taste the World from Home',
        headerWidth: '960px',
        paraOne:
          'Join our monthly virtual tastings to explore entire portfolios of renowned brands without splurging on full bottles. It’s your gateway to a personal whiskey journey.',
        subtitle: 'Explore Current Tasting Kit',
        fontSize: '19px',
        contentWidth: '780px',
        rowGap: '8px',
        showTastingKits: 'current',
      },
      {
        id: 22,
        headingOne: 'Savor Every Sip with Our Virtual Whiskey Tasting Kits',
        headingTwo: 'Taste the World from Home',
        headerWidth: '960px',
        paraOne:
          'Join our monthly virtual tastings to explore entire portfolios of renowned brands without splurging on full bottles. It’s your gateway to a personal whiskey journey.',
        subtitle: 'Explore Past Tasting Kits',
        fontSize: '19px',
        contentWidth: '780px',
        rowGap: '8px',
        showTastingKits: 'past',
        pastTastingKitsBtn: 'See More',
        pastTastingKitsAddress: 'https://primebarrel.com/collections/tasting-kits',
      },
    ],
  },
  {
    id: 3,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs/BG-3.jpg',
    headingOne: 'Exclusive Bottles Only For Members',
    headingTwo: 'Exclusive Whiskey Access Awaits',
    headerWidth: '880px;',
    paraOne:
      'Join The Prime Barrel Club for direct access to coveted whiskeys. Enjoy the privilege of purchasing allocated bourbons twice a year at or near MSRP and get first dibs on limited releases like the Found North High Altitude Series. Experience rarity without the markup.',
    link: 'See More',
    linkAddress: '#Exclusive-Bottles',
    fontSize: '18px',
    contentWidth: '760px',
    showWalletButton: true,
    rowGap: '8px',
  },
  {
    id: 4,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs/BG-4.png',
    subCarousel: [
      {
        id: 41,
        headingOne: 'Join Our Barrel Picking Expeditions',
        headingTwo: 'Barrel Selections: Discover, Bond, Savor',
        headerWidth: '805px',
        paraOne:
          'You\'d be invited on single barrel picking expeditions, offering VIP, behind-the-scenes distillery tours and the chance to bond with fellow enthusiasts over a shared whiskey passion. It\'s an immersive experience that blends discovery, camaraderie, and the finest spirits.',
        fontSize: '18px',
        contentWidth: '805px',
        rowGap: '8px',
        videoEmbed: 'https://player.vimeo.com/video/925326564',
      },
    ],
  },
  {
    id: 5,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs/BG-5.png',
    headingOne: 'Join Like-Minded Whiskey Enthusiasts',
    headingTwo: 'What Real People Are Saying',
    link: 'Read More',
    linkAddress: '#Testimonials',
    fontSize: '18px',
    showWalletButton: false,
    rowGap: '8px',
    contentWidth: '900px',
    testimonials: [
      {
        author: 'Michael R.',
        video: 'https://player.vimeo.com/video/947520167',
      },
      {
        author: 'Brandon W.',
        video: 'https://player.vimeo.com/video/947521285',
      },
      {
        author: 'Hjordys P.',
        video: 'https://player.vimeo.com/video/947522097',
      },
      {
        author: 'Marcus M.',
        video: 'https://player.vimeo.com/video/947528174',
      },
    ],
  },
  {
    id: 6,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs/Bg-6.png',
    subCarousel: [
      {
        id: 61,
        headingOne: 'And More Are Joining',
        headingTwo: 'Brand Partners For Exclusive Releases',
        rowGap: '8px',
        link: 'See More',
        linkAddress: '#Brand-Partners',
        fontSize: '18px',
        contentWidth: '578px',
        showWalletButton: false,
        brandPartnerLogos: {
          firstLine: partnersFirstLine,
          secondLine: partnersSecondLine,
        },
        brandPartnerLogosMobile: {
          firstLine: partnersFirstLineMobile,
          secondLine: partnersSecondLineMobile,
          thirdLine: partnersThirdLineMobile,
        },
      },
      {
        id: 62,
        headingOne: 'Current List & Growing',
        headingTwo: 'Barrel-Picking Partners',
        rowGap: '8px',
        fontSize: '18px',
        contentWidth: '853.95px',
        showWalletButton: true,
        partnerList: true,
      },
    ],
  },
  {
    id: 7,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs/Bg-6.png',
    subCarousel: [
      {
        id: 71,
        headingOne: 'Present, Past and Future Club Selections',
        headingTwo: 'Shop Current Selections',
        rowGap: '8px',
        headingThree: '(and envy past ones)',
        fontSize: '18px',
        contentWidth: '700.54px',
        showWalletButton: false,
        showShopifyProducts: true,
        shopNowBtn: true, // Shows Current Selections
      },
      {
        id: 72,
        headingOne: 'Present, Past and Future Club Selections',
        headingTwo: 'Check Out Past Selections',
        rowGap: '8px',
        fontSize: '18px',
        contentWidth: '700.54px',
        showWalletButton: false,
        showShopifyProducts: true,
        getNotifiedBtn: true, // Shows Past Selections
      },
      {
        id: 73,
        headingOne: 'Present, Past and Future Club Selections',
        headingTwo: 'Sign Up For Future Selections',
        rowGap: '8px',
        headingThree: 'Coming Soon Exclusively For Our Members!',
        fontSize: '18px',
        contentWidth: '700.54px',
        showWalletButton: false,
        futureSelectionsList: true,
        getNotifiedBtn: true,
      },
    ],
  },
];
