import styled from 'styled-components';

const NFTArtStyled = styled.div`
  height: 2570px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .popup-modal {
    width: 1087px;
    // height: 1501px;
    position: absolute;
    top: 12240px;
    border-radius: 25px;
    background: #e9c8c8;

    button {
      width: 66.27px;
      height: 66.27px;
      background: #fd7835;
      border-radius: 50%;
      border: none;
      box-shadow: 0px 9.81818px 9.81818px rgba(10, 48, 71, 0.5);
      position: relative;
      left: -10px;
      top: -10px;
      cursor: pointer;
      z-index: 1;

      .text {
        font-size: 43.2px;
        line-height: 250%;
        color: #ffffff;
        position: relative;
        top: -21px;
        left: -1px;
      }
    }

    .image-holder {
      width: 1087px;
      height: 1087px;
      position: relative;
      top: -78px;
      border-radius: 25px;

      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }
    }

    .badge {
      width: 123px;
      height: 123px;
      border-radius: 50%;
      position: relative;
      top: -50px;
      left: 129px;
    }

    .desc-holder {
      position: relative;
      top: -44px;
      width: 1086px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .name {
        font-family: 'Nielmaden';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 200%;
        text-align: center;
        text-decoration-line: underline;
        color: #0a3047;
      }

      .tier {
        font-family: 'Nielmaden';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 200%;
        text-align: center;
        color: #0a3047;
      }

      .desc {
        width: 950px;
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #0a3047;
      }
    }
  }

  .container {
    width: 994px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sub-heading {
      width: 994px;
      height: 254px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 2rem;

      .heading {
        font-style: normal;
        font-size: 24px;
        line-height: 90%;
        text-align: center;
        color: #0a3047;
      }

      .text {
        font-style: normal;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #0a3047;
      }
    }

    .image-gallery-test {
      height: 1971px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 25px;

      .image {
        width: 224.09px;
        height: 224.09px;
        border-radius: 25px;
        cursor: pointer;
      }
    }

    .image-gallery {
      margin-top: 25px;
      width: 954px;
      height: 1971px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .four-image-row {
        width: 953px;
        height: 228px;
        display: flex;
        justify-content: space-between;
      }

      .three-image-row {
        width: 716px;
        height: 223px;
        display: flex;
        justify-content: space-between;
      }

      .image {
        width: 224.09px;
        height: 224.09px;
        border-radius: 25px;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 800px) {
    height: auto;
    .container {
      width: 100%;

      .sub-heading {
        width: 80vw;
        height: auto;

        .heading {
          font-size: 21px;
          margin-top: 1.5rem;
          margin-bottom: 1.3rem;
        }
        .text {
          font-size: 14px;
        }
      }

      .image-gallery-test {
        height: auto;
        column-gap: 12px;
        row-gap: 12px;
        width: 90vw;
        margin-bottom: 4rem;

        img {
          width: calc(30vw - 8px) !important;
          height: calc(30vw - 8px) !important;
        }
      }
    }
  }
`;

const PopUpArtStyled = styled.div`
  height: auto;
  background: #FBF1E6;

  .image-holder {
    width: 1087px;
    height: 1087px;
    position: relative;
    top: -78px;
    border-radius: 25px;

    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    }
  }

  .badge {
    width: 123px;
    height: 123px;
    border-radius: 50%;
    position: relative;
    top: -50px;
    left: 129px;
  }

  .desc-holder {
    position: relative;
    top: -44px;
    width: 1086px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: 2rem;
    }

    .name {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 200%;
      text-align: center;
      text-decoration-line: underline;
      color: #0a3047;
    }

    .tier {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 200%;
      text-align: center;
      color: #0a3047;
    }

    .desc {
      width: 950px;
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: #0a3047;
    }
  }

  @media screen and (max-width: 800px) {
    width: 90vw;

    .image-holder {
      width: auto;
      height: auto;
      position: relative;
      top: unset;
      border-radius: 25px;

      img {
        width: 92vw;
        height: 90vw;
      }
    }

    .badge {
      width: 48px !important;
      height: 48px !important;
      top: -30px !important;
      left: 50px !important;
    }

    .desc-holder {
      width: auto;

      .name {
        font-size: 14px;
      }

      .tier {
        font-size: 9px;
      }

      .desc {
        font-size: 7px;
        width: 90%;
        margin-top: 1rem;
      }

      button {
        margin-top: 1rem;
      }
    }
  }
  `;

export { NFTArtStyled, PopUpArtStyled };
