import styled from 'styled-components';

const MPStyled = styled.div`
  background: #fbf1e6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 89px 256px 121px 263px;

  @media (max-width: 800px) {
    padding: 16px;
  }
`;

export default MPStyled;
