/* eslint-disable no-confusing-arrow */
import Web3 from 'web3';

export const getProvider = () =>
  window.ethereum?.providers
    ? window.ethereum.providers.find((provider) => provider.isMetaMask)
    : window.ethereum;

const getExaggeratedLimitInHex = (provider, value) => {
  const web3Utils = new Web3(provider).utils;

  const exaggeratedGasLimit = web3Utils.toBN(parseInt(value * 1.2, 10));

  const exaggeratedGasLimitInHex = web3Utils.toHex(exaggeratedGasLimit);

  return exaggeratedGasLimitInHex;
};

export default getExaggeratedLimitInHex;
