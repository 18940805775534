import React from 'react';
import styled from 'styled-components';
import closebutton from '../../../assets/close-button.png';

function ThanksModal({ setThanksModal, setShippingDataExist }) {
  return (
    <Modal>
      <ModalContent>
        <Head>Thanks! </Head>
        <SubHead>You’ll get an email confirmation when your bottle ships.</SubHead>
        <CloseButton
          src={closebutton}
          onClick={() => {
            setThanksModal(false);
            setShippingDataExist(true);
          }}
        />
      </ModalContent>
    </Modal>
  );
}

export default ThanksModal;

const Modal = styled.div`
position: fixed;
left: 0;
right: 0;
top: 0;
bottom: 0;
background-color: rgba(0,0,0,0.5);
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
overflow: auto;
`;

const ModalContent = styled.div`
width: 614px;
height: 204px;
background-color: #0A3047;
border-radius: 32px;
text-align: center;
position: relative;

@media(max-width: 800px){
  width: 95vw;
  height: 145px;
}
`;

const Head = styled.h1`
font-family: Nielmaden;
font-weight: Bold;
font-size: 40px;
line-height: 120%;
color: #FBF1E6;
@media(max-width: 800px){
  font-size: 28.4px;
}
`;

const SubHead = styled.p`
font-family: Century Gothic;
font-weight: Regular;
font-size: 24px;
line-height: 140%;
color: #FBF1E6;
@media(max-width: 800px){
  font-size: 17.4px;
}
`;

const CloseButton = styled.img`
top: -20px;
left: -20px;
position: absolute;
cursor: pointer;
`;
