import styled from 'styled-components';

const StyledModal = styled.div`
  width: 614.24px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #fff;
  position: absolute;
  padding: 9px;
  left: 332px;
  top: 159px;

  .pb-logo {
    max-width: 400px;
    margin-top: 40px;
  }

  .close-text {
    color: #4c0d18;
    cursor: pointer;
    transform: rotateX(44deg);
    float: right;
    background: none;
    border: 0;
  }
  form {
    width: 100%;
    padding: 50px 25px 25px 25px;
    top: 300px;
    color: #111;
    font-family: 'Nielmaden';

    p {
      font-family: 'Century Gothic';
      color: #404756;
      font-size: 16px;
    }

    input {
      width: 100%;
      font-family: 'Century Gothic';
      padding: 7px 10px;
      border: 1px solid #8e96a9;
      border-radius: 4px;
      outline: none;
    }
  }

  .submit-button {
    display: block;
    width: 100%;
    background: #fd7835;
    border-radius: 5px;
    border: 0;
    padding: 7px 167px;
    color: #fff;
    font-weight: 600;
    font-family: 'Century Gothic';
    cursor: pointer;
    margin-top: 26px;
  }
`;

export default StyledModal;
