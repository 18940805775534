import React, { useState } from 'react';
import MeetTheTeamStyled from './style';
import Header from '../Header';
import Michael from '../../assets/Michael.png';
import Leslie from '../../assets/Leslie.png';
import DropDownImg from '../../assets/drop-down-arrow.svg';

function MeetTheTeam() {
  const [readMoreShown, setReadMoreShown] = useState(false);

  return (
    <MeetTheTeamStyled id='Meet-The-Team'>
      <div className='container'>
        <Header
          headingOne='About Us'
          headingTwo='Meet the Team'
          rowGap='25px'
          headerWidth='898px'
          headerHeight='240px'
        />
        <div className='content'>
          <div className='member'>
            <div className='image-container'>
              <img src={Michael} alt='' className='image' />
              <div className='name'>
                Michael Nagdi <br /> Co-Founder
              </div>
            </div>
            <div className='line' />
            <div className='desc ff-CG fw-400'>
              After spending 20+ years working for two Fortune 100 companies in
              a myriad of roles - sales, marketing, GTM, strategy, business
              intelligence/big data - and getting to a Senior Director level
              with my last employer, I decided that “working for a man” was no
              longer something that brought joy. In August 2020 (the middle of
              the pandemic), I left my cushy corporate gig and decided to pursue
              my passion and go all-in on a boutique wine and whiskey shop I
              co-founded in 2014. <br />
              <br />I fell in love with whiskey after an uncle introduced me to
              a bottle of Dalmore 15-Year-Old Scotch at his 50th birthday
              celebration in 2007. The reason was not even about the whiskey
              itself, which was great. It was about spending quality, unrushed
              time in the company of very close family. That Dalmore bottle was
              a perfect conduit for building life-changing relationships on that
              snowy day. <br />
              <br />
              { readMoreShown &&
              `The experience put me on the path to eventually being in the
              position to help others to do the same. The Prime Barrel club is a
              logical extension of that mission. With this project, we are
              strengthening an already established community of people
              passionate about whiskey for the same reasons I fell in love with
              it. Good whiskey does not mean anything in a vacuum or by itself.
              It is merely a tool to facilitate life-long memories with people
              you love, and I am passionate about bringing you the best to help
              you do that.`}
            </div>
            <button
              onClick={() => setReadMoreShown(!readMoreShown)}
              type='button'
              className='ff-BO ff-700 read-less'
            >
              {readMoreShown ? 'Read Less' : 'Read More'}
              <img
                style={{ transform: readMoreShown ? 'rotate(180deg)' : 'rotate(0deg)' }}
                src={DropDownImg}
                className='drop-down-img'
                alt=''
              />
            </button>
          </div>
          <div className='member'>
            <div className='image-container'>
              <img src={Leslie} alt='' className='image' />
              <div className='name'>
                Leslie Osmont <br />
                Artist & Designer
              </div>
            </div>
            <div className='line' />
            <div className='desc ff-CG fw-400'>
              Leslie is a freelance illustrator and designer for editorial,
              pattern, packaging, and motion. Leslie currently resides in
              Denver, CO, a place that continues to inspire her work through
              access to the great outdoors. Leslie has worked with clients such
              as Venmo, Left Hand Brewing, Sound Sparkling Water, and
              Alltruists. She is passionate about all things art and design and
              is currently pursuing her MFA at the Savannah College of Art and
              Design. <br />
              <br /> After six years of working as an in-house designer at
              various advertising agencies, particularly Verizon Media, Leslie
              ventured out on her own and has been freelancing full-time ever
              since. She is proud to have many clients and collaborators across
              the country. Lately, Leslie has emphasized illustration work, but
              she will always love working on branding, identity, and design
              projects. <br />
              <br />
              { readMoreShown && `Leslie was drawn to the Grain to Glass NFT project with
              Prime Barrel because of the chance to illustrate rich and complex
              lifestyle scenes. Whiskey has been a large part of human culture
              for centuries, contributing to a way of life that embodies
              tradition, connection to nature, and overall excellence. By
              illustrating the whiskey-related scenes, Leslie enjoyed capturing
              and contributing to this rich history. The project was also an
              entry to the NFT space, which is unique and exciting artistic
              challenge she could not pass up.`}
            </div>
            <button
              onClick={() => setReadMoreShown(!readMoreShown)}
              type='button'
              className='ff-BO ff-700 read-less'
            >
              {readMoreShown ? 'Read Less' : 'Read More'}
              <img
                style={{ transform: readMoreShown ? 'rotate(180deg)' : 'rotate(0deg)' }}
                src={DropDownImg}
                className='drop-down-img'
                alt=''
              />
            </button>
          </div>
        </div>
      </div>
    </MeetTheTeamStyled>
  );
}

export default MeetTheTeam;
