import styled from 'styled-components';

const CurrentSelectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 8rem;

  .container {
    width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sub-heading {
      width: 986px;
      height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      .heading {
        font-style: normal;
        font-size: 24px;
        line-height: 90%;
        text-align: center;
        color: #0a3047;
      }

      .desc {
        font-style: normal;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #0a3047;
        margin-bottom: 10px;

        a {
          color: #fd7835;
        }
      }
    }

    .bottles {
      width: 1140.48px;
      display: flex;￼
      align-items: center;
      justify-content: flex-start;
      margin-top: 25px;
      flex-wrap: wrap;
      row-gap: 25px;
      column-gap: 1.4rem;

      .shopify-buy-frame {
        border: 1px solid #4c0d18;
        border-radius: 25px;
        height: 441px;
        width: 268px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        iframe {
          width: 75% !important;
        }
      }

      .bottle-container {
        width: 268px;
        height: 441px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 0.541938px solid #4c0d18;
        border-radius: 13.5485px;
        margin-top: 35px;
      }
    }

    .read-less {
      align-self: center;
      color: #fd7835;
      background: transparent;
      text-decoration: underline;
      border: none;
      outline: none;
      font-size: 1.1rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 0.5rem;
      margin-top: 40px;
    }

    .bottle-carousal {
      width: 100vw;
      margin-top: 2rem;

      .shopify-buy-frame {
        border: 1px solid #4c0d18;
        border-radius: 25px;
        height: 290px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        iframe {
          width: 75% !important;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 6rem;

    .container {
      width: 100%;
      height: auto;

      .sub-heading {
        display: none;
      }

        .bottle-carousal {
            width: 100%;
        }
    }

  }
`;

const Outer = styled.div`
  .dot {
    background: #fd7835 !important;
    opacity: 1 !important;
    width: 10px !important;
    height: 10px !important;
    box-shadow: none !important;
  }

  .control-dots .selected {
    background: #fbf1e6 !important;
  }

  .thumbs-wrapper {
    margin: 0px !important;
  }

  .carousel.carousel-slider .control-arrow {
    background: transparent;
    opacity: 1;
    top: 125px;
    padding: 0px;
    transform: scale(1.2);
    position: absolute;
    background: #fbf1e6;
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }

  .carousel.carousel-slider .control-arrow.control-prev {
    left: 2%;
  }

  .carousel.carousel-slider .control-arrow.control-next {
    right: 2%;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 16px solid #fd7835;
    margin-right: 10px;
    cursor: pointer;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 16px solid #fd7835;
    margin-left: 8px;
    cursor: pointer;
  }

  .carousel .slider .slide {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
  }

  .carousel .slider>.selected:first-child {
    margin-left: 30%;
  }

  .carousel .slider>.selected:last-child {
    margin-left: -30%;
    float: right;
    background-color: white;
  }


  .carousel .slider .selected {
    transform: scale(1);
    opacity: 1;
  }

  .carousel .slide iframe {
    margin-bottom: 0px;
  }
`;

export { Outer, CurrentSelectionStyled };
