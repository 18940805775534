import tierOneBadge from '../assets/tier-1-badge.png';
import tierTwoBadge from '../assets/tier-2-badge.png';
import tierTwoImage from '../assets/tier-2-image.png';
import tierOneImage from '../assets/tier-1-image.png';

const tierList = [
  /* {
    tierName: 'Tier 1 Plus',
    tierHeading: 'Tier 1 Plus: Single Barrel Savant Plus',
    headName: 'Single Barrel Savant +',
    availability: 7,
    ethPrice: 7.37,
    dollarPrice: 11500,
    badge: tierOnePlusBadge,
    image: tierOnePlusImage,
  }, */
  {
    tierName: 'Tier 1',
    tierHeading: 'Tier 1: Single Barrel Savant',
    headName: 'Single Barrel Savant',
    availability: 193,
    ethPrice: 0.96,
    dollarPrice: 1500,
    badge: tierOneBadge,
    image: tierOneImage,
  },
  {
    tierName: 'Tier 2',
    tierHeading: 'Tier 2: Single Barrel Advocate',
    headName: 'Single Barrel Advocate',
    availability: 1800,
    ethPrice: 0.48,
    dollarPrice: 750,
    badge: tierTwoBadge,
    image: tierTwoImage,
  },
];

export default tierList;
