const getCollectionData = (slug) => {
  if (slug === 'primebarrel') {
    return {
      status: 200,
      data: {
        site_name: 'Prime Barrel',
        slug: 'primebarrel',
        archived: false,
        address: '0x8198D85fC1Bd0D6B626Fe58cc93d8CDb2347915A',
        erc20_addr: '',
        abi: [
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'previousAdmin',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'address',
                name: 'newAdmin',
                type: 'address',
              },
            ],
            name: 'AdminChanged',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'approved',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Approval',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
              },
            ],
            name: 'ApprovalForAll',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'beacon',
                type: 'address',
              },
            ],
            name: 'BeaconUpgraded',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'uint8',
                name: 'version',
                type: 'uint8',
              },
            ],
            name: 'Initialized',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
              },
            ],
            name: 'OwnershipTransferred',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Transfer',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'implementation',
                type: 'address',
              },
            ],
            name: 'Upgraded',
            type: 'event',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
            ],
            name: 'balanceOf',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'getApproved',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
            ],
            name: 'isApprovedForAll',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'isMintEnabled',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'isWhitelistActive',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'name',
            outputs: [
              {
                internalType: 'string',
                name: '',
                type: 'string',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'owner',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'ownerOf',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'proxiableUUID',
            outputs: [
              {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'bytes',
                name: 'data',
                type: 'bytes',
              },
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
              },
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'bytes4',
                name: 'interfaceId',
                type: 'bytes4',
              },
            ],
            name: 'supportsInterface',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [
              {
                internalType: 'string',
                name: '',
                type: 'string',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            name: 'tier1PlusMintCount',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'tier1PlusPrice',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'tier1Price',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'tier2Price',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'index',
                type: 'uint256',
              },
            ],
            name: 'tokenByIndex',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'index',
                type: 'uint256',
              },
            ],
            name: 'tokenOfOwnerByIndex',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'tokenURI',
            outputs: [
              {
                internalType: 'string',
                name: '',
                type: 'string',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
              },
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'newImplementation',
                type: 'address',
              },
            ],
            name: 'upgradeTo',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'newImplementation',
                type: 'address',
              },
              {
                internalType: 'bytes',
                name: 'data',
                type: 'bytes',
              },
            ],
            name: 'upgradeToAndCall',
            outputs: [],
            stateMutability: 'payable',
            type: 'function',
            payable: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            name: 'userMintCount',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            name: 'whitelist',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'string',
                name: '_uri',
                type: 'string',
              },
              {
                internalType: 'address payable',
                name: '_treasury',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: '_tier1Price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: '_tier2Price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: '_tier1PlusPrice',
                type: 'uint256',
              },
            ],
            name: 'initialize',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [],
            name: 'withdraw',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'bool',
                name: '_state',
                type: 'bool',
              },
            ],
            name: 'setWhitelistState',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address[]',
                name: '_adds',
                type: 'address[]',
              },
            ],
            name: 'addWhitelist',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address[]',
                name: '_adds',
                type: 'address[]',
              },
            ],
            name: 'removeWhitelist',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'bool',
                name: '_state',
                type: 'bool',
              },
            ],
            name: 'setMintState',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'string',
                name: '_uri',
                type: 'string',
              },
            ],
            name: 'setBaseURI',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: '_tier1Price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: '_tier2Price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: '_tier1PlusPrice',
                type: 'uint256',
              },
            ],
            name: 'setSalePrice',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [],
            name: 'getTierPrice',
            outputs: [
              {
                internalType: 'uint256[3]',
                name: '',
                type: 'uint256[3]',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'getTierCount',
            outputs: [
              {
                internalType: 'uint256[3]',
                name: '',
                type: 'uint256[3]',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'getRecoveryAddress',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_user',
                type: 'address',
              },
            ],
            name: 'mintTier1Plus',
            outputs: [],
            stateMutability: 'payable',
            type: 'function',
            payable: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_user',
                type: 'address',
              },
            ],
            name: 'mintTier1',
            outputs: [],
            stateMutability: 'payable',
            type: 'function',
            payable: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_user',
                type: 'address',
              },
            ],
            name: 'mintTier2',
            outputs: [],
            stateMutability: 'payable',
            type: 'function',
            payable: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_owner',
                type: 'address',
              },
            ],
            name: 'tokensOfOwner',
            outputs: [
              {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_from',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
              },
            ],
            name: 'recoverToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_newRecoveryAddress',
                type: 'address',
              },
            ],
            name: 'setRecoveryAddress',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
        chain: '0x1',
        rpc_url:
          'https://eth-mainnet.g.alchemy.com/v2/XCmezpcIMM9xWiC3X-S_m96TsdzyczyT',
        explorer_url: 'https://etherscan.io/',
        collection_url: '',
        discord_url: '',
        contract_url:
          'https://etherscan.io/address/0x8198D85fC1Bd0D6B626Fe58cc93d8CDb2347915A',
        max_per_wallet: 0,
        max_per_transaction: 2,
        mint_price_tier_one_plus: '7370000000000000000',
        mint_price_tier_one: '960000000000000000',
        mint_price_tier_two: '480000000000000000',
        total_supply: 2000,
        whitepaper_url: '',
        mint_price_decimal_points: 2,
        background_image: '',
        logo_image: [],
        character_image: '',
        font_name: '',
        font_url: '',
        sentry_url: '',
        is_erc20: false,
        is_mintpass: false,
        has_biconomy: false,
        biconomy_dname: '',
        biconomy_apikey: '',
        erc20_dname: '',
        erc20_currency: '',
      },
    };
  }

  if (slug === 'primebarrel-goerli') {
    return {
      status: 200,
      data: {
        site_name: 'Prime Barrel',
        slug: 'primebarrel',
        archived: false,
        address: '0x1FA2F790CeD6E70A43Fb5B153F78DD6D06266B52',
        erc20_addr: '',
        abi: [
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'previousAdmin',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'address',
                name: 'newAdmin',
                type: 'address',
              },
            ],
            name: 'AdminChanged',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'approved',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Approval',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
              },
            ],
            name: 'ApprovalForAll',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'beacon',
                type: 'address',
              },
            ],
            name: 'BeaconUpgraded',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'uint8',
                name: 'version',
                type: 'uint8',
              },
            ],
            name: 'Initialized',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
              },
            ],
            name: 'OwnershipTransferred',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Transfer',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'implementation',
                type: 'address',
              },
            ],
            name: 'Upgraded',
            type: 'event',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
            ],
            name: 'balanceOf',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'getApproved',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
            ],
            name: 'isApprovedForAll',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'isMintEnabled',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'isWhitelistActive',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'name',
            outputs: [
              {
                internalType: 'string',
                name: '',
                type: 'string',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'owner',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'ownerOf',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'proxiableUUID',
            outputs: [
              {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'bytes',
                name: 'data',
                type: 'bytes',
              },
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
              },
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'bytes4',
                name: 'interfaceId',
                type: 'bytes4',
              },
            ],
            name: 'supportsInterface',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [
              {
                internalType: 'string',
                name: '',
                type: 'string',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            name: 'tier1PlusMintCount',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'tier1PlusPrice',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'tier1Price',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'tier2Price',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'index',
                type: 'uint256',
              },
            ],
            name: 'tokenByIndex',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'index',
                type: 'uint256',
              },
            ],
            name: 'tokenOfOwnerByIndex',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'tokenURI',
            outputs: [
              {
                internalType: 'string',
                name: '',
                type: 'string',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
              },
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'newImplementation',
                type: 'address',
              },
            ],
            name: 'upgradeTo',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'newImplementation',
                type: 'address',
              },
              {
                internalType: 'bytes',
                name: 'data',
                type: 'bytes',
              },
            ],
            name: 'upgradeToAndCall',
            outputs: [],
            stateMutability: 'payable',
            type: 'function',
            payable: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            name: 'userMintCount',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            name: 'whitelist',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'string',
                name: '_uri',
                type: 'string',
              },
              {
                internalType: 'address payable',
                name: '_treasury',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: '_tier1Price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: '_tier2Price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: '_tier1PlusPrice',
                type: 'uint256',
              },
            ],
            name: 'initialize',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [],
            name: 'withdraw',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'bool',
                name: '_state',
                type: 'bool',
              },
            ],
            name: 'setWhitelistState',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address[]',
                name: '_adds',
                type: 'address[]',
              },
            ],
            name: 'addWhitelist',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address[]',
                name: '_adds',
                type: 'address[]',
              },
            ],
            name: 'removeWhitelist',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'bool',
                name: '_state',
                type: 'bool',
              },
            ],
            name: 'setMintState',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'string',
                name: '_uri',
                type: 'string',
              },
            ],
            name: 'setBaseURI',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: '_tier1Price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: '_tier2Price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: '_tier1PlusPrice',
                type: 'uint256',
              },
            ],
            name: 'setSalePrice',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [],
            name: 'getTierPrice',
            outputs: [
              {
                internalType: 'uint256[3]',
                name: '',
                type: 'uint256[3]',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'getTierCount',
            outputs: [
              {
                internalType: 'uint256[3]',
                name: '',
                type: 'uint256[3]',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [],
            name: 'getRecoveryAddress',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_user',
                type: 'address',
              },
            ],
            name: 'mintTier1Plus',
            outputs: [],
            stateMutability: 'payable',
            type: 'function',
            payable: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_user',
                type: 'address',
              },
            ],
            name: 'mintTier1',
            outputs: [],
            stateMutability: 'payable',
            type: 'function',
            payable: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_user',
                type: 'address',
              },
            ],
            name: 'mintTier2',
            outputs: [],
            stateMutability: 'payable',
            type: 'function',
            payable: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_owner',
                type: 'address',
              },
            ],
            name: 'tokensOfOwner',
            outputs: [
              {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
              },
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true,
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_from',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
              },
            ],
            name: 'recoverToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_newRecoveryAddress',
                type: 'address',
              },
            ],
            name: 'setRecoveryAddress',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
        chain: '0x5',
        rpc_url:
          'https://eth-goerli.g.alchemy.com/v2/qHJ8LooG_wsmmOnvw3KN_KTG0_5T15b9',
        explorer_url: 'https://goerli.etherscan.io/',
        collection_url: '',
        discord_url: '',
        contract_url:
          'https://goerli.etherscan.io/address/0x1FA2F790CeD6E70A43Fb5B153F78DD6D06266B52',
        max_per_wallet: 0,
        max_per_transaction: 2,
        mint_price_tier_one_plus: '100000000000000',
        mint_price_tier_one: '100000000000000',
        mint_price_tier_two: '100000000000000',
        total_supply: 2000,
        whitepaper_url: '',
        mint_price_decimal_points: 2,
        background_image: '',
        logo_image: [],
        character_image: '',
        font_name: '',
        font_url: '',
        sentry_url: '',
        is_erc20: false,
        is_mintpass: false,
        has_biconomy: false,
        biconomy_dname: '',
        biconomy_apikey: '',
        erc20_dname: '',
        erc20_currency: '',
      },
    };
  }
  return {
    status: 404,
  };
};
export default getCollectionData;
