/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

const CarouselContentStyled = styled.div`
  width: ${(props) => props.contentWidth};
  display: flex;
  flex-direction: ${(props) =>
    props.id === 8 ? props.flexDirection : 'column'};
  justify-content: ${(props) => props.id === 8 && props.justifyContent};
  align-items: ${(props) => props.id === 8 && props.alignItems};
  font-family: 'Century Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.fontSize};
  line-height: 140%;
  text-align: center;
  color: #0a3047;
  margin-top: 18px;
    
  @media(max-width:800px){
    width: revert;
    font-size: 13px !important;
  }

  #paraOne,
  #paraTwo {
    b {
      font-family: 'Century Gothic BO';
    }
  }

  .third-text {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: #0a3047;
      margin-top: 15px;
      @media (max-width: 800px) {
          font-size: 18px !important;
      }
  }

  a {
    color: #fd7835;
    font-family: 'Century Gothic BO';
    font-size: 19px;
    margin-top: 10px;
    margin-right: 2rem;
  }

  .italic {
    font-style: italic;
  }

  .head-shot-container {
    display: flex;
    column-gap: 16px;
    @media(max-width:800px){
      display: none;
    }
  }

  .head-shot-container-mobile{
    display: flex;
    column-gap: 16px;
    justify-content: center;
    width: 100vw;

    .carousel.carousel-slider .control-arrow {
      top: 50px;
    }
    @media(min-width:800px){
      display: none;
    }
  }

  .brand-partner-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .partner-one {
      display: flex;
      flex-direction: column;
      width: 474.75px;
      height: 97.58px;
    }

    .partner-two {
      width: 577.34px;
      height: 113.65px;
      margin-top: 24px;
      margin-bottom: -14px;
    }
  }

  .stickers {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .name-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #0a3047;
  }

  .name-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #0a3047;
  }

  .slide-btn {
    width: 35px;
    height: 34px;
    background: #fd7835;
    border-radius: 2px;
    transform: rotate(-90deg);
  }
  .bottle-container {
    width: 164.07px;
    height: 271px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 0.541938px solid #4c0d18;
    filter: drop-shadow(0px 2.16775px 2.16775px rgba(0, 0, 0, 0.25));
    border-radius: 13.5485px;

    img {
      width: 79.89px;
      height: 119.83px;
    }

    .label {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 110.61px;
      height: 48.55px;
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 9.75489px;
      line-height: 150%;
      color: #0a3047;

      .selection-label {
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 9.75489px;
        line-height: 150%;
        color: #0a3047;
        border-bottom: 0.541938px solid #4c0d18;
        padding-bottom: 8px;
      }

      .price {
        font-family: 'Century Gothic BO';
        font-style: normal;
        font-size: 9.75489px;
        line-height: 180%;
        text-align: center;
        color: #404756;
        padding-top: 5px;
      }
    }

    button {
      width: 88.84px;
      height: 28.17px;
      background: #fd7835;
      border-radius: 6.50326px;
      border: none;
      color: #ffffff;
      font-family: 'Century Gothic BO';
      font-style: normal;
      font-size: 9.75489px;
      line-height: 140%;
      text-align: center;
    }
  }

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: ${(props) => props.fontSize};
    margin-top: 0;

    #paraOne,
    #paraTwo {
      font-size: 14px;
      line-height: 20px;
    }

    a {
      color: #fd7835;
      font-family: 'Century Gothic BO';
      font-weight: 700;
      font-size: 19px;
      margin-top: 10px;
      margin-right: 0px;
    }


    .brand-partner-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .partner-one {
        display: flex;
        flex-direction: column;
        width: 474.75px;
        height: 97.58px;
      }

      .partner-two {
        width: 577.34px;
        height: 113.65px;
        margin-top: 24px;
        margin-bottom: -14px;
      }
    }

    .stickers {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .name-one {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      text-align: center;
      color: #0a3047;
    }

    .name-two {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      text-align: center;
      color: #0a3047;
    }

    .slide-btn {
      width: 35px;
      height: 34px;
      background: #fd7835;
      border-radius: 2px;
      transform: rotate(-90deg);
    }
    .bottle-container {
      width: 164.07px;
      height: 271px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border: 0.541938px solid #4c0d18;
      filter: drop-shadow(0px 2.16775px 2.16775px rgba(0, 0, 0, 0.25));
      border-radius: 13.5485px;

      img {
        width: 79.89px;
        height: 119.83px;
      }

      .label {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 110.61px;
        height: 48.55px;
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 9.75489px;
        line-height: 150%;
        color: #0a3047;

        .selection-label {
          font-family: 'Century Gothic';
          font-style: normal;
          font-weight: 400;
          font-size: 9.75489px;
          line-height: 150%;
          color: #0a3047;
          border-bottom: 0.541938px solid #4c0d18;
          padding-bottom: 8px;
        }

        .price {
          font-family: 'Century Gothic BO';
          font-style: normal;
          font-weight: 700;
          font-size: 9.75489px;
          line-height: 180%;
          text-align: center;
          color: #404756;
          padding-top: 5px;
        }
      }

      button {
        width: 88.84px;
        height: 28.17px;
        background: #fd7835;
        border-radius: 6.50326px;
        border: none;
        color: #ffffff;
        font-family: 'Century Gothic BO';
        font-style: normal;
        font-weight: 700;
        font-size: 9.75489px;
        line-height: 140%;
        text-align: center;
      }
    }
  }
  .carousel-root{
    width: -webkit-fill-available;
  }
  .dot {
    background: #fd7835 !important;
    opacity: 1 !important;
    width: 10px !important;
    height: 10px !important;
    box-shadow: none !important;
  }

  .control-dots .selected {
    background: #fbf1e6 !important;
  }

  .thumbs-wrapper {
    margin: 0px !important;
  }

  .carousel.carousel-slider .control-arrow {
    background: transparent;
    opacity: 1;
    top: 125px;
    padding: 0px;
    transform: scale(1.2);
    position: absolute;
    background: #fbf1e6;
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }

  .carousel.carousel-slider .control-arrow.control-prev {
    left: 2%;
  }

  .carousel.carousel-slider .control-arrow.control-next {
    right: 2%;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 16px solid #fd7835;
    margin-right: 10px;
    cursor: pointer;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 16px solid #fd7835;
    margin-left: 8px;
    cursor: pointer;
  }

  .carousel .slider .slide {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
    display: flex;
    align-items: center;
  }

  .carousel .slider .selected {
    transform: scale(1);
    opacity: 1;
  }

  .carousel .slide iframe {
    margin-bottom: 0px;
  }
`;

const OuterCarousel = styled.div``;

export { CarouselContentStyled, OuterCarousel };
