import React, { useState } from 'react';
import ProfileFormStyle from './style';
import PopUpHeader from '../PopUpHeader';
import Button from '../Button';

function ProfileForm({ onSave, selectedTier, selectedAddOns }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    discord: '',
  });

  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const handleFormChange = (e) => {
    const formField = e.target.name;
    const newInput = e.target.value;
    formData[formField] = newInput;
    setFormData({ ...formData });
  };

  const getAddonsTitles = () => selectedAddOns.map((i) => i.title).join(', ');

  const handleSubmit = () => {
    if (formData.email === '') {
      setEmailError(true);
    }

    if (formData.name === '') {
      setNameError(true);
      return;
    }

    onSave(formData);
  };
  return (
    <ProfileFormStyle>
      <PopUpHeader
        headingOne='Thanks for your interest!'
        headingTwo='Your Waitlist Confirmation'
        rowGap='25px'
      />

      <div className='info'>
        Thanks for selecting your {'  '}
        <b>{selectedTier.title.split(':')[0]}</b>
        {selectedAddOns.length > 0 && (
          <>
            {' '}and{' '}
            <b>
              {getAddonsTitles()}
            </b>
          </>
        )}

        . We just need a couple more things to finalize your selections.
      </div>

      <div className='form-wrapper'>
        <div className='form-group'>
          <label htmlFor='name' className={nameError && 'error-text'}>
            *Name {nameError && 'Reqiured'}
          </label>
          <input
            type='text'
            onKeyUp={handleFormChange}
            name='name'
            id='name'
            placeholder='Name'
          />
        </div>

        <div className='form-group'>
          <label htmlFor='email' className={emailError && 'error-text'}>
            *Email {emailError && 'Reqiured'}
          </label>
          <input
            type='email'
            onKeyUp={handleFormChange}
            name='email'
            id='email'
            placeholder='Email'
          />
        </div>

        <div className='form-group'>
          <label htmlFor='discord'>Discord Handle</label>
          <input
            type='text'
            onKeyUp={handleFormChange}
            name='discord'
            id='discord'
            placeholder='Discord Handle'
          />
        </div>
      </div>
      <a href='/' className='submit'>
        Join Our Discord Community
      </a>
      <Button onClick={handleSubmit}>Submit</Button>
    </ProfileFormStyle>
  );
}

export default ProfileForm;
