import styled from 'styled-components';

const AboutPBStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 47px;
  padding: 89px 96px 93px 97px;
  background: #fbf1e6;

  @media (max-width: 800px) {
    padding: 20px 30px;
    height: auto;
    padding-top: 3rem;
  }

  .sub-part {
    width: 1045px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 14px;

    @media (max-width: 800px) {
      width: revert;
    }

    .sub-heading {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 90%;
      text-align: center;
      color: #0a3047;

      @media (max-width: 800px) {
        font-size: 18px;
      }
    }

    .sub-content {
      text-align: center;
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      color: #0a3047;

      @media (max-width: 800px) {
        font-size: 14px;
        max-width: 349px;
      }
    }

    .sub-content-mobile-view {
      display: none;
    }
  }

  .image-container {
    height: 574px;
    padding-top: 16px;
    position: relative;

    @media (max-width: 800px) {
      height: auto;
    }

    .image {
      width: 1116px;
      height: 554px;
      border-radius: 25px;
      object-fit: cover;

      @media (max-width: 800px) {
        width: 100%;
        height: auto;
      }
    }

    .logo-image {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 200.19px;
      display: flex;
      justify-content: center;

      img {
        width: 200px;
      }
    }
  }
`;

const OuterCarousel = styled.div`
  width: 1247px;
  @media (max-width: 800px) {
    width: -webkit-fill-available;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }

  .carousel .slider-wrapper {
    width: 1117px;

    @media (max-width: 800px) {
      width: auto;
    }
  }

  .carousel.carousel-slider {
    position: relative;
    top: -30px;
    overflow: unset;
  }
  .carousel .control-dots {
    bottom: -60px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  .carousel.carousel-slider .control-arrow {
    background: transparent;
    opacity: 1;
    height: 35px;
    width: 35px;
    top: 288px;
    padding: 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
      
    &:before {
        margin: 0;
    }

    @media (max-width: 800px) {
      background: #FBF1E6;
      border-radius: 50%;
      top: 50%;
      transform: scale(1.2);
    }
  }

  @media (max-width: 800px) {
    .carousel .control-prev.control-arrow {
      left: -20px;
    }

    .carousel .control-next.control-arrow {
      right: -20px;
    }
  }

  .carousel .control-next.control-arrow:before {
    border-left: 16px solid #ff5a20;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 16px solid #ff5a20;
  }

  .dot {
    background: #ff5a20 !important;
    opacity: 1 !important;
    width: 10px !important;
    height: 10px !important;
    box-shadow: none !important;
  }

  .control-dots .selected {
    background: #ffb32e !important;
  }
`;
export { AboutPBStyled, OuterCarousel };
