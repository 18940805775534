import React from 'react';
import FooterStyle from './style';
import DiscordIcon from '../../assets/discord-icon.png';
import TwitterIcon from '../../assets/twitter-icon.png';
import InstagramIcon from '../../assets/instagram-icon.png';
import FacebookIcon from '../../assets/facebook-icon.png';

function Footer() {
  return (
    <FooterStyle>
      <div className='footer-top' />
      <div className='footer-middle-wrapper'>
        <div className='footer-middle'>
          <div className='follow-us-on'>
            <div>Follow us on</div>
          </div>
          <div className='social-media-icons'>
            <div className='discord-div'>
              <a
                href='https://vip.primebarrel.com/Discord'
                target='_blank'
                className='discord-text'
                rel='noreferrer'
              >
                Discord
              </a>
              <div className='discord-icon'>
                <img
                  src={DiscordIcon}
                  alt='Discord logo'
                  className='logo-image'
                />
              </div>
            </div>
            <div className='twitter-div'>
              <a
                href='https://twitter.com/theprimebarrel'
                target='_blank'
                className='twitter-text'
                rel='noreferrer'
              >
                Twitter
              </a>
              <div className='twitter-icon'>
                <img
                  src={TwitterIcon}
                  alt='Twitter logo'
                  className='logo-image'
                />
              </div>
            </div>
            <div className='instagram-div'>
              <a
                href='https://instagram.com/theprimebarrel'
                target='_blank'
                className='instagram-text'
                rel='noreferrer'
              >
                Instagram
              </a>
              <div className='instagram-icon'>
                <img
                  src={InstagramIcon}
                  alt='Instagram logo'
                  className='logo-image'
                />
              </div>
            </div>
            <div className='facebook-div'>
              <a
                href='http://facebooks.com/theprimebarrel'
                target='_blank'
                className='facebook-text'
                rel='noreferrer'
              >
                Facebook
              </a>
              <div className='facebook-icon'>
                <img
                  src={FacebookIcon}
                  alt='Facebook logo'
                  className='logo-image'
                />
              </div>
            </div>
          </div>
          <div className='footer-links'>
            <div className='link-div-one'>
              <a href='#Why-NFTs' className='link-one'>
                About Our Membership
              </a>
              <a href='#Core-Member-Tiers' className='link-one'>
                Member Tiers
              </a>
            </div>
            <div className='link-div-two'>
              <a href='#Testimonials' className='link-two'>
                Testimonials
              </a>
              <a href='#Benifits-Pricing' className='link-two'>
                Benefits & pricing
              </a>
              <a href='#NFT-Art' className='link-two'>
                Club Art
              </a>
            </div>
            <div className='link-div-three'>
              <a href='#Brand-Partners' className='link-three'>
                Brand Partners
              </a>
              <a href='#Exclusive-Bottles' className='link-three'>
                Exclusive Bottles
              </a>
              <a href='#Meet-The-Team' className='link-three'>
                Meet the Team
              </a>
            </div>
            <div className='link-div-four'>
              <a href='#FAQs' className='link-four'>
                FAQs
              </a>
              {/* <a href='#Tools-Resources' className='link-four'>
                Tools & Resources
              </a> */}
              <a href='#Whitepaper' className='link-four'>
                Whitepaper
              </a>
              <a href='#Current-Selections' className='link-four'>
                Shop Current Selections
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-bottom'>
        <div className='copy-right'>
          Copyright © 2022 Prime Barrel - The First Web3 Whiskey Club™
        </div>
      </div>
    </FooterStyle>
  );
}

export default Footer;
