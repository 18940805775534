import React from 'react';
import HeaderStyled from './style';

function Header({
  id,
  headingOne,
  headingTwo,
  headingTwoSub,
  headingThree,
  rowGap,
  headerWidth,
  headerHeight,
  headerMaxWidth,
  alignItems,
  bottomTextAlign,
  borderBottom,
}) {
  return (
    <HeaderStyled
      width={headerWidth}
      maxWidth={headerMaxWidth}
      rowGap={rowGap}
      headerHeight={headerHeight}
      alignItems={alignItems}
      bottomTextAlign={bottomTextAlign}
      borderBottom={borderBottom}
    >
      {/* This is the header for the first slide in the hero slider */}
      {id === 1 ? (
        <>
          <div className='top-text'>{headingOne}</div>
          <div className='yellow-line' />
          <h1 className='bottom-text'>
            {headingTwo}
            <span className='tm-cirle'>
              <sup className='trade-mark'>&trade;</sup>
            </span>
          </h1>
        </>
      ) : (
        <>
          {/* Rest of the headers */}
          <div className='top-text'>{headingOne}</div>
          <div className='yellow-line' />
          <h2 className={`bottom-text ${id ? 'small-text' : ''}`}>
            {headingTwo}
          </h2>
          {headingTwoSub && (
            <div className='bottom-text' id='bottom-sub'>
              {headingTwoSub}
            </div>
          )}
          {headingThree && <div className='third-text'>{headingThree}</div>}
        </>
      )}
    </HeaderStyled>
  );
}

export default React.memo(Header);
