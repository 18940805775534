import styled from 'styled-components';

export default styled.div`
  width: 664px;
  height: 150px;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  display: flex;

  @media (max-width: 800px) {
    width: 332px;
    height: 80px;
    border-radius: 12px !important;

    .days,
    .hours,
    .minutes {
      width: 110px;
      border-right: 1.5px solid #0a3047;

      .text {
        border-bottom: 1.5px solid #0a3047;
        font-weight: 700;
        font-size: 20px !important;
        line-height: 120%;
        padding-top: 0.6rem !important;
      }

      .day-text {
        border-top-left-radius: 12px !important;
        background: #fd7835;
      }

      .hour-text {
        background: #fd7835;
      }

      .minute-text {
        border-top-right-radius: 12px !important;
        background: #fd7835;
      }

      .day-value {
        border-bottom-left-radius: 12px !important;
        background: #ffffff;
      }

      .hour-value {
        background: #ffffff;
      }

      .minute-value {
        border-bottom-right-radius: 12px !important;
        background: #ffffff;
      }

      .value {
        flex-grow: 2;
        font-weight: 700;
        font-size: 25px !important;
        line-height: 120%;
        padding-top: 0.5rem !important;
      }
    }

    .minutes {
      border-right: none;
    }
  }

  .days,
  .hours,
  .minutes {
    width: 221px;
    text-align: center;
    border-right: 3px solid #0a3047;
    display: flex;
    flex-direction: column;

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      border-bottom: 3px solid #0a3047;
      background: #fd7835;
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 35.353px;
      text-align: center;
      color: #4c0d18;
      padding-top: 0.8rem;
    }

    .day-text {
      border-top-left-radius: 25px;
      background: #fd7835;
    }

    .hour-text {
      background: #fd7835;
    }

    .minute-text {
      border-top-right-radius: 25px;
      background: #fd7835;
    }

    .day-value {
      border-bottom-left-radius: 25px;
      background: #ffffff;
    }

    .hour-value {
      background: #ffffff;
    }

    .minute-value {
      border-bottom-right-radius: 25px;
      background: #ffffff;
    }

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 2;
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 120%;
      text-align: center;
      color: #4c0d18;
    }
  }

  .minutes {
    border-right: none;
  }
`;
