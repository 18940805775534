/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

const HeaderStyled = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => (props.headerHeight ? props.headerHeight : '')};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  row-gap: ${(props) => props.rowGap};
  font-family: 'Nielmaden';
  border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : '')};
  max-width: ${(props) => props.maxWidth};
  
    .top-text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #0a3047;
  }

  .yellow-line {
    width: 100px;
    height: 8px;
    background: #ffb32e;
  }

  .small-text {
    font-size: 56px !important;
  }
    
  h1, h2 {
      margin: 0;
  }

  .bottom-text {
    font-family: 'Nielmaden';
    font-style: normal;
    font-weight: 700;
    font-size: 64px !important;
    line-height: 120%;
    text-align: ${(props) =>
      props.bottomTextAlign ? props.bottomTextAlign : 'center'};
    color: #0a3047;

    .tm-cirle {
      position: absolute;
      width: 31px;
      height: 31px;
      border-radius: 50%;
      border: 1px solid black;
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 23px;

      .trade-mark {
        position: relative;
        top: -19px;
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
      }
    }
  }

  #bottom-sub {
    margin-top: -23px;
  }

  .third-text {
    font-family: 'Nielmaden';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #0a3047;
    margin-top: unset;
    @media (max-width: 800px) {
      font-size: 18px !important;
    }
  }

  @media screen and (max-width: 800px) {
    width: auto;
    height: auto;
    row-gap: 15px;
    font-family: 'Nielmaden';
    align-items: center;

    .top-text {
      font-size: 10px;
    }

    .yellow-line {
      width: 60px;
      height: 4px;
    }

    .bottom-text {
      font-size: 37px !important;
      text-align: center;
      padding-bottom: 1rem;

      .tm-cirle {
        width: 17px;
        height: 17px;

        .trade-mark {
          top: -13px;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    #bottom-sub {
      margin-top: -28px;
    }

    .third-text {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: #0a3047;
      margin-top: 0px;
    }
  }
`;

export default HeaderStyled;
