import React from 'react';
import styled from 'styled-components';
import Button from '../Button';

function AddMore() {
  const contents = [

    { heading: 'New Riff Charity Bottle',
      price: '$725',
      url: 'https://primebarrel.com/products/new-riff-distilling-one-of-one-9-year-single-barrel-straight-bourbon-whiskey-the-prime-barrel-pick' },
    { heading: 'Four Roses Charity Bottle',
      price: '$2000',
      url: 'https://primebarrel.com/products/four-roses-c-for-charity-13-16-year-old-single-barrel-kentucky-straight-bourbon-whiskey-the-prime-barrel-pick' },
    { heading: 'Four Roses & New Riff Charity Bottle',
      price: '$2500',
      url: 'https://primebarrel.com/products/four-roses-new-riff-charity-bottles-bundle' },

  ];

  return (
    <Container>
      <HeadDiv>
        <Head>Add On More?</Head>
        <Para>Use options below to add these fantastic (and rare!) bottles to your purchase</Para>
      </HeadDiv>
      <ContentDiv>
        {contents.map((e, index) => (
          <Content key={`add-more-${index}`}>
            <ContentHead>{e.heading}</ContentHead>
            <Text>Available Starting:<br />
              Q3 2023
            </Text>
            <BorderBox>
              <Price>{e.price}</Price>
              <Button onClick={() => window.open(e.url, '_blank')}>Get It Now</Button>
            </BorderBox>
          </Content>
        ))}
      </ContentDiv>
    </Container>
  );
}

export default AddMore;

const Container = styled.div`
width: 918px;
height: 685px;
background-color: #FBF1E6;
border-radius: 20px;
margin-bottom: 40px;
margin-top: 20px;
@media(max-width: 800px){
    height: auto;
    width: 90%
}
`;

const HeadDiv = styled.div`
background-color: #0A3047;
padding: 20px;
border-radius: 20px 20px 0 0;
text-align: center;
margin-bottom: 20px;
`;

const Head = styled.h1`
font-family: Nielmaden;
font-weight: Bold;
font-size: 62.58px;
color: #FBF1E6;
@media(max-width: 800px){
font-size: 28px;
}
`;

const Para = styled.p`
font-family: Century Gothic;
font-size: 23px;
color: #FFFFFF;
@media(max-width: 800px){
font-size: 10px;
}
`;

const ContentDiv = styled.div`
padding: 20px;
display: flex;
justify-content: space-between;
@media(max-width: 800px){
    flex-direction: column;
    align-items: center;

}
`;

const Content = styled.div`
width: 277px;
height: 404px;
background-color: #4C0D18;
border-radius: 18px;
padding: 20px;
text-align: center;
display: flex;
flex-direction: column;
justify-content: space-between;
@media(max-width: 800px){
    width: 245px;
    height: 320px;
    margin-bottom: 15px;
}
`;

const ContentHead = styled.h2`
font-family: Nielmaden;
font-style: Bold;
font-size: 28.42px;
line-height: 140%;
color: #FFFFFF;
margin-top: 0;
text-decoration: underline;
margin-bottom: 0;
height: 121px;
@media(max-width: 800px){
    font-size: 25.12px;
}
`;

const Text = styled.p`
font-family: Century Gothic;
font-size: 17.05px;
line-height: 100%;
color: #FFFFFF;
@media(max-width: 800px){
    font-size: 15.12px;
}
`;

const BorderBox = styled.div`
border : .5px solid #FBF1E6;
width: 100%;
height: 127px;
border-radius: 18px;
`;

const Price = styled.h3`
font-family: Nielmaden;
font-style: Bold;
font-size: 28.42px;
color: #FD7835;
margin-bottom: 14px;
margin-top: 20px;
@media(max-width: 800px){
    font-size: 25.12px;
}
`;
