import { useState } from 'react';
import { addEmail } from '../../service/api';
import StyledModal from './styles';
import pb from '../../assets/ThePrimeBarrel.webp';

function NotificationModal({ setNotificationTwo }) {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addEmail(email, () => {
      setNotificationTwo(false);
    });
  };

  return (
    <StyledModal>
      <button
        type='button'
        className='close-text'
        onClick={() => {
          setNotificationTwo(false);
        }}
      >
        X
      </button>
      <img src={pb} alt='' className='pb-logo' />

      <form onSubmit={(e) => handleSubmit(e)}>
        <h2>New Barrel Drop Notifications</h2>
        <p>
          Stay up to date on the latest single barrel pick releases, special
          offers & news by signing up for our newsletter.
        </p>
        <input
          type='text'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='email@website.com'
        />
        <button type='submit' className='submit-button'>
          SIGN UP
        </button>
      </form>
    </StyledModal>
  );
}

export default NotificationModal;
