import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 8rem;

  .container {
    width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .header {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 120%;
      text-align: center;
      color: #0a3047;
    }

    .sub-heading {
      width: 986px;
      height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      .heading {
        font-style: normal;
        font-size: 24px;
        line-height: 90%;
        text-align: center;
        color: #0a3047;
      }

      .desc {
        font-style: normal;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #0a3047;
        margin-bottom: 10px;

        a {
          color: #fd7835;
        }
      }
    }

    .bottles {
      width: 1140.48px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 25px;
      flex-wrap: wrap;
      row-gap: 25px;
      column-gap: 1.4rem;

      .shopify-buy-frame {
        border: 1px solid #4c0d18;
        border-radius: 25px;
        height: 441px;
        width: 268px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        iframe {
          width: 75% !important;
        }
      }

      .bottle-container {
        width: 268px;
        height: 441px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 0.541938px solid #4c0d18;
        border-radius: 13.5485px;
        margin-top: 35px;
      }
    }

    .read-less {
      align-self: center;
      color: #fd7835;
      background: transparent;
      text-decoration: underline;
      border: none;
      outline: none;
      font-size: 1.1rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 0.5rem;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 800px) {
    .container {
      width: 100%;
      height: auto;

      .header {
        font-size: 38px;
      }

      .sub-heading {
        display: none;
      }

      .bottles {
        width: 85vw;
        column-gap: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .shopify-buy-frame {
          height: 310px;
          width: 40vw;
        }
      }
    }

  }
`;
