export default [
  {
    item: 1,
    title: 'New Riff Charity Bottle',
    availability: 'Q1-Q2 2023',
    price: 725,
  },
  {
    item: 2,
    title: 'Four Roses Charity Bottle',
    availability: 'Q1-Q2 2023',
    price: 2000,
  },
  {
    item: 3,
    title: 'Four Roses & New Riff Charity Bottle',
    availability: 'Q1-Q2 2023',
    price: 2500,
  },
];
