/* eslint-disable no-confusing-arrow */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import FAQStyled from './style';
import Header from '../Header';
import PlusSign from '../../assets/plus-icon.png';
import DownArrow from '../../assets/down-arrow.png';

function FAQ() {
  const questions = [
    {
      qs: 'Will you have a “how to” guide to help me better understand crypto & NFT technology?',
      ans: 'Yes, we will be posting “how-to” guides and NFT 101 content to assist our members with the onboarding process',
      folded: true,
    },
    {
      qs: 'What wallet do you recommend?',
      ans: 'Coinbase & Metamask are the two most popular digital wallets.Coinbase makes it a bit easier to view purchased NFTs. But we invite you to do your own research as well. We include an installation how to video below as well. Watch it here',
      folded: true,
    },
    {
      qs: 'Will you have any options for those that are not interested in NFT but still would like some kind of membership?',
      ans: 'Not at this time',
      folded: true,
    },
    {
      qs: 'We all want the NFT to be valuable so limiting quantity or continued increasing pricing for membership must be part of the plan, correct?',
      ans: 'Quantity will be very limited at 2,000 total. As we expand partnerships, both with distilleries and retailers, we may look at minting more NFTs in the future with approval from the club',
      folded: true,
    },
    {
      qs: 'Can a token be transferred/sold to a different person and still be honored for the membership it represents?',
      ans: 'Yes! This is Web3 in the nutshell. To be clear - the seller will lose his/her privileges once their NFT is transferred. All membership benefits would shift to the new NFT holder at that time.',
      folded: true,
    },
    {
      qs: 'Are you considering partnerships with other projects?',
      ans: 'Absolutely! We will be continuously engaging with other Web3 projects as we grow. Also, expect to see us at various Conferences and Web3 Events in the future.',
      folded: true,
    },
    {
      qs: 'Do members have a vote on increasing membership if the demand exists?',
      ans: 'Yes, club members will vote on expansion as we grow',
      folded: true,
    },
    {
      qs: 'Why isn’t free shipping included as one of the perks?',
      ans: 'With a lifetime structure is not financially feasible, and will make the membership pricing prohibitive. ',
      folded: true,
    },
    {
      qs: 'Can you offer storage services so I can store bottles until I satisfy a free shipping requirement (3+ bottles)',
      ans: 'Not at this time, but might in the future. Space is always at a premium and we need it to pack orders and ship them in the most efficient manner.',
      folded: true,
    },
    {
      qs: 'How do you address the tremendous pollution and negative environmental impact of this concept? NFTs currently put a lot of carbon in the atmosphere for very little utility.',
      ans: 'The technology is rapidly evolving. Solar powered mining plants will become a norm and will lessen the negative environmental impact.',
      folded: true,
    },
    {
      qs: `Any thoughts about offering periodic tiered bottle discount at T1 and T2 for
      NFT owners. Not suggesting for every bottle offered as NFT is an access
      program but a holiday or special occasion discount vs public price? Or buy
      1 + second bottle discount?`,
      ans: 'Yes, we are planning on periodically doing just that',
      folded: true,
    },
    {
      qs: 'When do you expect to open memberships?',
      ans: 'Q1 2023',
      folded: true,
    },
    {
      qs: 'Can more than one person share an NFT? If no, can you revoke an NFT if abused? If revoked, will you reissue to maintain 2000 members?',
      ans: '• The NFT is a unique identifier, so it’s 1 NFT per person - you would not be able to share it. But you could in theory pass/sell your NFT to another wallet (person). You would be passing on your membership to that wallet (person) under this scenario. Which means they could make the purchase but you would not be able to. And you couldn’t pass it to different wallets and make multiple purchases. <br /> <br />• Right now, we are not looking to revoke the membership. Once you purchase it, it is yours to do as you please.',
      folded: true,
    },
    {
      qs: 'Are you suggesting making certain barrel pick bottles NFTs themselves?',
      ans: 'No, our NFT utility is membership validation.',
      folded: true,
    },
    {
      qs: 'How will this club govern itself moving forward? Will there be a governance document created with rules to oversee changes to governance documents by vote of NFT owning members.',
      ans: 'No, this will not have a DAO (decentralized autonomous organization) structure. Mainly because we operate in the regulated industry and have to have a centralized body. Outside of that, we already put a lot of things to a vote for our Discord community - barrel selections, sticker ideas, etc. We fully expect to continue to do it.',
      folded: true,
    },
    {
      qs: 'What guarantee do we have? If founders lose interest and shut down the program the NFT will be worthless.',
      ans: 'In theory, it’s true. If the business goes under, membership ceases to exist. That is also true for any other business, so there is not an additional level of risk here. As a founder, I am being as transparent as I can about my background, and my goals for this club. I am not going anywhere anytime soon.',
      folded: true,
    },
    {
      qs: 'Will there be bottle limits at each offering?',
      ans: 'There will be bottle limits for exclusive and highly sought-after brands (i.e., Old Forester, Four Roses, Russel Reserve, etc.). For most others there will not be a limit.',
      folded: true,
    },
    {
      qs: 'To upgrade from Tier 2 to Tier 1, can you pay the difference in price between the two or have to pay the full price for the tier 1 NFT and try to sell your tier 2 NFT on your own?',
      ans: 'The latter. There are a number of market places that would easily facilitate such sale (i.e., OpenSea)',
      folded: true,
    },
    {
      qs: 'NFTs use only Ethereum and we’d have to buy Ethereum. Isn\'t there something called "gas fee" and that also raises price?',
      ans: 'That’s correct. Think of it as a credit card processing fee in the virtual world. Normally, “gas fee” is added to the price of an item. You can reference <a href="https://ethgasstation.info/" target="_blank">ethgasstation.info</a> for current gas fee rates',
      folded: true,
    },
    /*{
      qs: 'I used Coinbase Wallet to connect to your site. It lets me sign up on the waitlist but does not show anywhere in my wallet. Will I have to purchase the membership before it shows up?',
      ans: 'Yes, that is correct. It won’t show anything until you buy the membership. Joining the waitlist allows us to isolate wallets and offer discounted prices to only those who joined.',
      folded: true,
    },*/
    {
      qs: 'Coinbase Wallet app is asking me to add crypto to purchase an NFT. I don’t want to buy crypto. I want to connect a credit card to purchase the NFT. Is that not possible?',
      ans: 'You don’t have to have crypto. You’d be able to buy with a credit card.',
      folded: true,
    },
    {
      qs: 'When I click on “Connect the wallet” from my mobile device, it either takes me to the app store or opens the Coinbase Wallet / Metamask app, but no further. How do I connect?',
      ans: 'To connect from your mobile device, you’d need to use a browser built into your wallet app (we are creating instructional videos on this as we speak), navigate to <a href="https://nft.primebarrel.com" target="_blank">nft.primebarrel.com</a> and connect the wallet from there. NOTE: you’d also need to purchase drops the same way once we launch, i.e. from the app’s browser. Alternatively, you can use a browser on your desktop and install a Coinbase Wallet extension to it. They support most browsers (except Safari).',
      folded: true,
    },
  ];

  const [foldableQuestions, setFoldableQuestions] = useState(questions);

  const handleUnfold = (index) => {
    const newArray = [...foldableQuestions];
    newArray[index] = {
      ...newArray[index],
      folded: !newArray[index].folded,
    };

    setFoldableQuestions(newArray);
  };

  return (
    <FAQStyled id='FAQs'>
      <div className='container'>
        <Header
          headingOne='Learn More'
          headingTwo='Frequently Asked Questions'
          rowGap='25px'
          headerWidth='809px'
          headerHeight='240px'
        />
        <div className='question-section'>
          {foldableQuestions.map((qs, index) => (
            <div className='question-ans-container' key={qs.qs}>
              <div className='question-container'>
                <div className='question'>{qs.qs}</div>
                {qs.folded ? (
                  <img
                    src={PlusSign}
                    alt=''
                    className='symbol'
                    onClick={() => {
                      handleUnfold(index);
                    }}
                  />
                ) : (
                  <img
                    src={DownArrow}
                    alt=''
                    className='symbol'
                    onClick={() => {
                      handleUnfold(index);
                    }}
                  />
                )}
              </div>
              {qs.ans && (
                <div
                  className='answer-container'
                  style={{ display: qs.folded ? 'none' : 'block' }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: qs.ans }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </FAQStyled>
  );
}

export default FAQ;
