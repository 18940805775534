/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { CurrentSelectionStyled, Outer } from './style';
import Header from '../Header';
import DropDownImg from '../../assets/drop-down-arrow.svg';
import { ShopifyJSContext } from '../../context/ShopifyJSContext';
import { createShopifyProductComponentCurrent, createShopifyProductComponentCurrentMobile } from '../../utils/createShopifyComponent';

function CurrentSelection() {
  const [currentProducts, setCurrentProducts] = useState([]);
  const [seeMoreShown, setSeeMoreShown] = useState(false);
  const { shopifyClient, shopifyUI } = useContext(ShopifyJSContext);

  const mql = window.matchMedia('(min-width: 800px)');
  const webView = mql.matches;

  const shopifyBuyInit = async () => {
    const ui = shopifyUI;

    /*ui.createComponent('product', {
      options: {
        cart: {
          styles: {
            button: {
              'background-color': '#fd7835 !important',
              ':hover': {
                'background-color': '#e46c30',
              },
              ':focus': {
                'background-color': '#e46c30',
              },
            },
          },
        },
        toggle: {
          styles: {
            toggle: {
              'background-color': '#fd7835 !important',
            },
          },
        },
      },
    });*/

    const collectionId = 'gid://shopify/Collection/266857873590';
    const collection = await shopifyClient.collection.fetchWithProducts(
      collectionId, { productsFirst: 250 }
    );

    // const products = await client.product.fetchAll(250);

    const currentSelections = collection.products.filter(
      (p) => p.availableForSale
    ).map((p) => {
      const idSplit = p.id.split('/');
      return idSplit[idSplit.length - 1];
    });

    setCurrentProducts(currentSelections);

    setTimeout(() => {
      for (const product of currentSelections) {
        if (webView) {
          createShopifyProductComponentCurrent(product, ui, 'current');
        } else {
          createShopifyProductComponentCurrentMobile(product, ui, 'current');
        }
      }
    }, 500);
  };

  useEffect(() => {
    if (!!shopifyClient && !!shopifyUI) {
      shopifyBuyInit();
    }
  }, [shopifyClient, shopifyUI]);

  return (
    <CurrentSelectionStyled id='Current-Selections'>
      <div className='container'>
        <Header
          headingOne='Present, Past Future Club Selections'
          headingTwo='Shop Current Selections'
          rowGap='25px'
        />
        <div className='sub-heading'>
          <div className='heading ff-NL fw-700' style={{ color: '#404756' }}>
            Featured Selections
          </div>
          <div className='desc ff-CG fw-400'>
            Our single barrel selections do not stick around for long.
            <br />
            Browse our most current selections below, peruse our previous sold
            out picks, and see what we have in our queue!
          </div>
        </div>

        {webView ? (
          <>
            <div
              className='bottles'
              style={{
                maxHeight: !seeMoreShown && '1385.62px',
                overflow: !seeMoreShown && 'hidden',
              }}
            >
              {currentProducts.map((o) => (
                <SelectionItem id={o} key={o} />
              ))}
            </div>
            {currentProducts.length > 12 && (
            <button
              onClick={() => setSeeMoreShown(!seeMoreShown)}
              type='button'
              className='ff-BO ff-700 read-less'
            >
              {!seeMoreShown ? 'See More' : 'See Less'}
              <img
                style={{
                  transform: !seeMoreShown ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                src={DropDownImg}
                className='drop-down-img'
                alt=''
              />
            </button>
            )}
          </>
        ) : (
          <div className='bottle-carousal'>
            <Outer>
              <Carousel
                showThumbs={false}
                centerMode
                centerSlidePercentage={40}
                showStatus={false}
                showIndicators={false}
              >
                {currentProducts.map((o) => (
                  <SelectionItem id={o} key={o} />
                ))}
              </Carousel>
            </Outer>
          </div>
        )}
      </div>
    </CurrentSelectionStyled>
  );
}

export default CurrentSelection;

function SelectionItem({ id }) {
  return <div id={`product-component-${id}`} />;
}
