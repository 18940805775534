import styled from 'styled-components';

const MintContainerStyled = styled.div`
  width: 100%;
  max-width: 920px;
  margin-top: 5rem;

  #backDrop {
    filter: opacity(0.5);
  }

  @media (max-width: 800px) {
    .review-section {
      border-radius: 15.7834px !important;
    }
  }

  @media (max-width: 800px) {
    .success-section {
      .text-one,
      .text-two,
      a {
        font-size: 14px !important;
        line-height: 120%;
      }
    }
  }
  .success-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
    border-bottom-right-radius: 33px;
    justify-content: space-evenly;
    padding: 4rem;
    border-bottom-right-radius: 33.418px;
    border-bottom-left-radius: 33.418px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    text-align: center;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #0a3047;

    .text-two {
      font-size: 18px;
    }

    a {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      text-align: center;
      text-decoration-line: underline;
      color: #fd7835 !important;
    }
  }

  .review-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-right-radius: 33px;
    justify-content: space-evenly;
    padding: 2rem;
    border-bottom-right-radius: 33.418px;
    border-bottom-left-radius: 33.418px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

    .tier-heading {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 40.6169px;
      line-height: 120%;
      text-align: center;
      color: #0a3047;
    }

    .error {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 700;
      font-size: 32.0813px;
      line-height: 150%;
      text-align: center;
      color: #0a3047;
    }

    @media (max-width: 800px) {
      .error {
        font-size: 18.0813px;
      }
    }

    @media (max-width: 800px) {
      .tier-heading {
        font-size: 19.1834px;
        line-height: 120%;
        margin-bottom: 1rem;
      }
    }

    .font {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 700;
      font-size: 32.0813px;
      line-height: 150%;
      text-align: center;
      color: #fd7835;
    }

    @media (max-width: 800px) {
      .font {
        font-size: 15.1521px;
        line-height: 150%;
      }
    }

    .price {
      color: #0a3047;
    }

    .increase,
    .decrease {
      color: #0a3047;
      cursor: pointer;
    }

    .selected-count {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 700;
      font-size: 48.1219px;
      line-height: 140%;
      text-align: center;
      color: #fd7835;
      margin-bottom: 2rem;
      margin-top: 1rem;
      display: flex;
    }

    .public-sale {
      display: flex;
      column-gap: 2rem;

      button {
        font-size: inherit;
        font-weight: 700;
        width: 3rem;
        height: fit-content;
      }
    }

    @media (max-width: 800px) {
      .selected-count {
        font-size: 19.1834px;
        line-height: 120%;
        margin-top: 1rem;
      }

      .public-sale {
        column-gap: 1rem;

        button {
          font-size: 20px;
          font-weight: 700;
          width: 2rem;
          height: fit-content;
        }
      }
    }

    @media (max-width: 800px) {
      .confirm {
        width: 147px;
        height: 31px;
        border-radius: 6.98969px;
        font-size: 10.4845px;
        line-height: 140%;
        text-transform: uppercase;
      }
    }
  }
  @media (max-width: 800px) {
    padding: 0;
    margin-top: 1rem;
  }
`;

const MCContentStyled = styled.div`
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  .switch-section {
    display: flex;

    .tier-indicator {
      width: 50%;
      height: 21px;
    }

    .selected {
      background: #fd7835;
    }
  }

  .tier-selection {
    padding: 1rem;
    font-family: 'Nielmaden';
    font-style: normal;
    font-weight: 700;
    font-size: 43.2079px;
    line-height: 240%;
    text-align: center;
    color: #ffffff;
    background: #4c0d18;
    display: flex;
    cursor:pointer;

    .white-margin {
      border-right: 2px solid #FBF1E6;:
    }

    .selected {
      color: #fd7835;
    }

    .tier {
      width: 50%;
      height: 6rem;
    }
  }

  .tier-details{
    display:flex;

    .preSaleNonWaitListed{
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      padding: 2rem;
      row-gap:1rem;

      .preSaleNonWaitListed-heading{
        font-family: 'Nielmaden';
        font-style: normal;
        font-weight: 700;
        font-size: 40.6169px;
        line-height: 125%;
        text-align: center;
        color: #0A3047;
      }

      .yellow-line{
        width: 42.78px;
        height: 5.35px;
        background: #FFB32E;
      }

      .access{
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: #0A3047;
      }
    }

    .left-section{
      width:50%;
      
      .top-section{
        display:flex;
        flex-direction:column;
        align-items:center;
        padding: 65px 2rem 50px;
        row-gap: 45px;
        border-bottom: 0.634638px solid #4C0D18;
        justify-content: center;

        .yellow-line{
          width: 42.78px;
          height: 5.35px;
          background: #FFB32E;
        }

        .font{
          font-family: 'Nielmaden';
          font-style: normal;
          font-weight: 700;
          font-size: 40.6169px;
          line-height: 120%;
          text-align: center;
          color: #0A3047;
        }

        .access{
          font-family: 'Century Gothic';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          text-align: center;
          color: #0A3047;
        }
      }

      .bottom-section{
        padding: 16px 2rem 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 60px;
        justify-content: center;
          
          &.connected {
              row-gap: 35px;
          }

        .price-section{
          .count{
            color:#FD7835;
          }

          .eth,
          .dollar{
            color:#0A3047;
          }
  
          .font{
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 700;
            font-size: 32.0813px;
            line-height: 150%;
            text-align: center;
          }
        }

        .select-count{
          display:flex;
          column-gap:2rem;
          align-items: center;

          .count{
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 700;
            font-size: 32.0813px;
            line-height: 150%;
            text-align: center;
            color: #0A3047;
          }

          button{
            height:fit-content;
            background: #642c36;
            color: white;
            border: none;
            font-weight: 700;
            font-size: larger;
            cursor:pointer;
            width:1.5rem;
            border-radius: 35%;
          }

          button:hover{
            background: #4C0D18;
          }

        }
        
        .buttons{
            row-gap:0.8rem;
            display: flex;
            flex-direction: column;
        }

        @media (max-width:800px){
          .select-count{
            display:flex;
            column-gap:1rem;
            align-items: center;
            margin-top:0.5rem;
  
            .count{
              font-family: 'Century Gothic';
              font-style: normal;
              font-weight: 700;
              font-size: 22.0813px;
              line-height: 150%;
              text-align: center;
              color: #0A3047;
            }
            
            button{
              font-size:inherit;
              border-radius: 35%;
            }
          }
        }
      }
    }

    .right-section{
      width:50%;
      background: #4C0D18;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top:1rem;

      .badge{
        height:122px;
        width:122px;
        position: relative;
        top: 1.5rem;
      }

      .image{
        width: 382px;
        height: 382px;
        border-radius: 33.42px;
      }
    }
  }

  @media (max-width:800px){
    .tier-selection{
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 16.8212px;
      line-height: 140%;
      text-align: center;
      height:3rem;
      padding:0.8rem 0rem;

      .tier {
        width: 418px;
        height:1.5rem;
      }
    }

    .tier-details{
      flex-direction:column;
      height:auto;
      
      .left-section{
        width:auto;
        height:auto;

        .top-section{
          height:auto;
          border-bottom:0rem;
          row-gap:0.3rem;
          padding:1rem;

          .yellow-line{
            width:19.4px;
            height:2.42px;
          }

          .access{
            font-size: 12.4579px;
            margin-top: -0.7rem;
          }

          .font{
            font-size: 15.8125px;
          }
        }

        .bottom-section{
          padding: 0rem;
          height: auto;
          margin-top:0rem;
          row-gap: 0.7rem;

          div button, .buy-eth {
            width: 190px;
            height: 30px;
            font-size: 10px !important;
          }

          .price-section{
            .font{font-size: 14px;
              line-height: 150%;
            }
            .prices{
              display:flex;
              justify-content: center;
              align-items: center;
              column-gap:0.5rem;
            }
            .slash{
              background: #0A3047;
              width: 2px;
              transform: rotate3d(1, 1, 1, 45deg);
              height:1rem;
            }
          }

          .buttons{
            margin-top: 1rem;
            row-gap:0.8rem;
            display: flex;
            flex-direction: column;

            .buy-eth, .buy-dollar{
              width: 188.18px;
              height: 30.79px;
              font-size: 10.4845px !important;
            }
          }
        }
      }

      .right-section{
        width: auto;
        height: 253px;
        margin-top: 1rem;
        padding:0rem;

        .badge{
          width:55.33px;
          height:55.33px;
        }

        .image{
          width:173.24px;
          height:173.24px;

        }
      }
    }
  }
`;

const MCBenefitsStyled = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 20px 20px;
  padding: 4rem 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;

  .top-text {
    font-family: 'Nielmaden';
    font-style: normal;
    font-weight: 700;
    font-size: 54.0098px;
    line-height: 86px;
    text-align: center;
    color: #0a3047;
  }

  .sub-text {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #0a3047;
    padding: 0 5rem;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    height: 6.5rem;
    justify-content: space-between;

    .dollar-mint {
      .crossmintImg-0-2-2 {
        display: none !important;
      }
    }
  }

  .benefits {
    padding: 3rem 0rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    .items {
      display: flex;
      align-items: flex-start;
      column-gap: 2rem;

      .included-check {
          width: 26px;
          min-width: 26px;
          flex: 0 1 auto;

          @media (max-width: 800px) {
              width: 12px;
              min-width: 12px;
          }
      }
        
      .excluded {
        font-size: 48px;
        color: #FF5A20;
        line-height: 0.6;
        font-family: 'Century Gothic';

        @media (max-width: 800px) {
            font-size: 22px;
        }
      }

      .item-text {
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 27.0049px;
        line-height: 150%;
        color: #0a3047;
        a {
          text-decoration: none;
          color: #fd7835;
        }
      }

      .bold {
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 27.0049px;
        line-height: 150%;
        color: #0a3047;
      }
    }
  }
  .select-count {
    display: flex;
    column-gap: 2rem;
    align-items: center;

    .count {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 700;
      font-size: 32.0813px;
      line-height: 150%;
      text-align: center;
      color: #0a3047;
    }

    button {
      height: fit-content;
      background: #642c36;
      color: white;
      border: none;
      font-weight: 700;
      font-size: larger;
      cursor: pointer;
      width: 1.5rem;
      border-radius: 35%;
    }

    button:hover {
      background: #4c0d18;
    }
  }

  @media (max-width: 800px) {
    padding: 2rem 2rem;
    border-radius: 0px 0px 7.78617px 7.78617px;

    .top-text {
      font-size: 21.0265px;
      line-height: 34px;
    }
    .sub-text {
      font-size: 12.4579px;
      line-height: 15px;
      padding: 0rem 2rem;
    }
    .benefits {
      padding: 1rem 1rem;
      row-gap: 0.5rem;

      .items {
        column-gap: 0.8rem;

        .item-text {
          font-size: 12px;
          line-height: 150%;

          .bold {
            font-size: 12px;
            line-height: 150%;
          }
        }
      }
    }
    .buttons {
      height: 4.4rem;
      .buy-eth,
      .buy-dollar {
        width: 188.18px;
        height: 30.79px;
        font-size: 10.4845px !important;
      }
    }
  }
`;

const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  justify-content: center;
  position: relative;
  top: -24rem;
  width: 542px;
  height: 218px;
  background: #4c0d18;
  box-shadow: 0px 6.09709px 6.09709px rgba(0, 0, 0, 0.25);
  border-radius: 38.1068px;
  padding: 4rem;

  @media (max-width: 800px) {
    width: 321px;
    height: 208px;
    border-radius: 20px;
  }

  @media (max-width: 800px) {
    .outer {
      border-radius: 20px !important;
      padding: 0rem;
    }
  }

  .outer {
    border-radius: 38.1068px;
    border: 1.52427px solid #fbf1e6;
    margin: -3rem;
    height: 218px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    .heading {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 700;
      font-size: 27.4369px;
      line-height: 34px;
      text-align: center;
      color: #ffffff;

      @media (max-width: 800px) {
        ont-size: 22.025px;
        line-height: 27px;
        padding-bottom: 0.5rem;
      }
    }

    .text {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 21.34px;
      line-height: 32.01px;
      text-align: center;
      color: #ffffff;

      @media (max-width: 800px) {
        font-size: 17.13px;
        line-height: 25.7px;
      }
    }
  }
`;

const MCHeaderStyled = styled.div`
  background: #0a3047;
  border-radius: 32px 35px 0px 0px;
  padding: 2rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .close-btn {
    border-radius: 50%;
    position: relative;
    right: 28rem;
    top: -2rem;
    width: 58px;
    height: 58px;
    background: #fd7835;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 40.6169px;
    line-height: 145%;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }

  .preSaleNonWaitListedSection {
    row-gap: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .preSaleNonWaitListedHeading {
      font-size: 54px;
      line-height: 120%;
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      text-align: center;
      color: #fbf1e6;
    }

    .sub-heading {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      text-align: center;
      color: #fbf1e6;
    }
  }

  @media (max-width: 800px) {
    .preSaleNonWaitListedSection {
      row-gap: 0.5em;

      .preSaleNonWaitListedHeading {
        font-size: 27px;
        line-height: 120%;
      }

      .sub-heading {
        font-size: 16px;
        line-height: 120%;
      }
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 2rem;

    .heading {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 62.5789px;
      line-height: 140%;
      text-align: center;
      color: #fbf1e6;
    }

    .text {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 140%;
      text-align: center;
      color: #ffffff;
    }
  }

  @media (max-width: 800px) {
    border-radius: 12.4579px 13.6258px 0px 0px;

    .close-btn {
      width: 35px;
      height: 35px;
      font-size: 24.5102px;
      line-height: 132%;
      top: -2.5rem;
      left: -11.5rem;
    }

    .section {
      padding: 0rem 2rem;
      .heading {
        font-size: 28px;
        line-height: 140%;
      }
      .text {
        font-size: 14px;
      }
    }
  }
`;

export {
  MintContainerStyled,
  MCContentStyled,
  MCBenefitsStyled,
  ModalStyled,
  MCHeaderStyled,
};
