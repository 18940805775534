import React from 'react';
import './style.css';
import styled from 'styled-components';

const ButtonStyled = styled.button`
  padding: 10px 20px;
  min-height: 45px;
  max-height: 200px;
  border: none;
  background: #FD7835;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Century Gothic';
  font-size: 18px;
  font-weight: 700;
  background: #fd7835;

  @media screen and (max-width: 800px) {
    padding: 10px 22px;
    min-height: 32px;
    max-height: 200px;
    border-radius: 10px;
    font-size: 16px;
  }
`;

export default function Button({ onClick, children, style }) {
  return (
    <ButtonStyled type='button' style={style} onClick={onClick}>
      {children}
    </ButtonStyled>
  );
}
