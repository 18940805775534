import React from 'react';
import AddOnSelectorStyled from './style';
import PopUpHeader from '../PopUpHeader';
import Button from '../Button';
import addons from '../../models/addons';

function AddOnSelector({ onSelection, onCancel }) {
  return (
    <AddOnSelectorStyled>
      <PopUpHeader
        headingOne='Great choice, thanks for your interest in our membership!'
        headingTwo='Get More With Add-ons'
        headerWidth='824px'
        rowGap='25px'
      />
      <div className='sub-part'>
        {addons.map((addon) => (
          <div className='add-on-item' key={addon}>
            <div className='add-on-body'>
              <div className='add-on-title'>{addon.title}</div>

              <div className='add-on-availability'>
                Available Starting: <br />
                {addon.availability}
              </div>

              <div className='add-on-price-wrapper'>
                <div className='add-on-price'>
                  ${addon.price.toLocaleString()}
                </div>
                <Button
                  onClick={() => onSelection(addon)}
                  style={{ width: '100%', padding: '0px 0px' }}
                >
                  Reserve Your Bottle
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div>
        <div className='skip-link' onClick={onCancel}>
          No thanks, I’m not interested
        </div>
      </div>
    </AddOnSelectorStyled>
  );
}

export default AddOnSelector;
