import styled from 'styled-components';

const MeetTheTeamStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 8rem;

  .container {
    width: 994px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      width: 994px;
      display: flex;
      column-gap: 57px;

      .member {
        width: 469px;
        background: #4c0d18;
        border: 1px solid #4c0d18;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-top: 15px;
        padding-bottom: 15px;

        .image-container {
          width: 390px;
          height: 367px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          border-bottom: 1px solid #ffffff;

          .image {
            width: 257px;
            height: 257px;
          }

          .name {
            width: 226px;
            height: 68px;
            font-family: 'Nielmaden';
            font-style: normal;
            font-weight: 750;
            font-size: 24px;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
          }
        }

        .line {
          display: none;
        }

        .desc {
          width: 391px;
          font-style: normal;
          font-size: 18px;
          line-height: 140%;
          text-align: center;
          color: #ffffff;
          margin: 25px 0px;
        }

        .read-less {
          align-self: center;
          color: #fd7835;
          background: transparent;
          text-decoration: underline;
          border: none;
          outline: none;
          font-size: 1.1rem;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 0.5rem;
        }

        .drop-down-img {
          width: 17px;
          height: 17px;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5rem;

    .container {
      width: 85vw;
      display: flex;
      flex-direction: column;
      align-items: center;

      .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 57px;
        margin-top: 2rem; 


        .member {
          width: 100%;
          background: #4c0d18;
          border: 1px solid #4c0d18;
          border-radius: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          padding-top: 15px;
          padding-bottom: 15px;

          .image-container {
            width: 100%;
            height: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            border-bottom: none;

            .image {
              width: 257px;
              height: 257px;
            }

            .name {
              width: 226px;
              height: 68px;
              font-family: 'Nielmaden';
              font-style: normal;
              font-weight: 750;
              font-size: 24px;
              line-height: 140%;
              text-align: center;
              color: #ffffff;
            }
          }

          .line {
            display: block;
            width: 65%;
            border: 1px solid #FBF1E6; 
          }

          .desc {
            width: 80%;
            font-style: normal;
            font-size: 12px;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
            margin-bottom: 15px;
          }

          .read-less {
            align-self: center;
            color: #fd7835;
            background: transparent;
            text-decoration: underline;
            border: none;
            outline: none;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 0.5rem;
          }

          .drop-down-img {
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
`;

export default MeetTheTeamStyled;
