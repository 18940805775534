import { observer } from 'mobx-react-lite';

import {
  WagmiConfig,
  createConfig,
  configureChains,
} from 'wagmi';

import { mainnet, goerli } from 'wagmi/chains';

import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import Home from './components/Home';
import ShopifyJSProvider from './context/ShopifyJSContext';
import ETHPriceProvider from './context/ETHPriceContext';
import CsvCMSDataProvider from './context/CsvCMSDataContext';
import ReferralCodeProvider from './context/ReferralCodeContext';

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, publicClient, webSocketPublicClient } = configureChains([mainnet, goerli], [
  alchemyProvider({ apiKey: 'qHJ8LooG_wsmmOnvw3KN_KTG0_5T15b9' }),
  publicProvider(),
]);

// Set up client
const config = createConfig({
  autoConnect: false,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'Coinbase Wallet',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

const App = observer(() => (
  <WagmiConfig config={config}>
      <ShopifyJSProvider>
        <ETHPriceProvider>
          <CsvCMSDataProvider>
            <ReferralCodeProvider>
              <Home />
            </ReferralCodeProvider>
          </CsvCMSDataProvider>
        </ETHPriceProvider>
      </ShopifyJSProvider>
  </WagmiConfig>
));

export default App;
