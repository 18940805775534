/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

const ContentStyled = styled.div`
    margin-top: 35px;

    @media screen and (max-width: 800px) {
        margin-top: 20px;
    }

    .shopify-tasting-kit-card {
        iframe {
            margin: 0;
        }

        &.shopify-buy-frame {
            max-width: 240px !important;

            @media screen and (max-width: 800px) {
                max-width: 200px !important;
            }
        }
    }
    
    .tasting-kits-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 12px 0 0;
        width: 92px;
        height: 30px;
        padding: 0;
        font-size: 13px;
        border-radius: 8.5px;
        color: #ffffff;
        cursor: pointer;
        line-height: 1.2;
        text-decoration: none;
        background-color: #fd7835;

        &:hover {
            background-color: #e46c30;
        }
        &:focus {
            background-color: #e46c30;
        }
    }
`;

export { ContentStyled };
