import styled from 'styled-components';

const ReviewSelectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 25px 21px 25px;
  background: #fbf1e6;
  width: 600px;
  color: #0a3047;

  .orange-text {
    color: #fd7835;
  }

  .section {
    padding: 40px;
  }

  .section-seperator {
    width: 100%;
    height: 0.5px;
    background-color: #4c0d18;
  }

  .selected-tier,
  .selected-addon {
    width: 100%;
    .tier-title {
      font-family: 'Nielmaden';
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
    }

    .tier-availability {
      font-family: 'Century Gothic';
      font-weight: 700;
      font-size: 21px;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .tier-benefits {
      font-family: 'Century Gothic';
      font-weight: 400;
      font-size: 16px;
      color: #404756;
    }

    .tier-price {
      font-family: 'Nielmaden';
      font-weight: 700;
      font-size: 21px;
      margin-top: 25px;
    }

    #selection {
      display: flex;
      align-items: flex-end;
      column-gap: 10px;

      img {
        cursor: pointer;
      }
    }

    .tier-edit {
      margin-top: 25px;
      font-family: 'Inter';
      font-weight: 700;
      font-size: 24px;
      text-decoration-line: underline;
      color: #fd7835;
      cursor: pointer;
    }
  }

  .selection-container {
    margin-top: -36px;
    margin-bottom: 2rem;
    .info {
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 700;
      font-size: 11.2271px;
      line-height: 250%;
      color: #515151;
      padding-left: 1rem;
    }

    .tier-selection {
      width: 504px;
      display: flex;
      flex-direction: column;
      border-radius: 9.99594px;
      background: #4c0d18;
      margin-top: 3px;


      .option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #fbf1e6;

        .option-name {
          font-family: 'Century Gothic';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 250%;
          color: #ffffff;
          margin: 0.4rem 0.6rem;
        }

        .add-btn {
          width: 71.96px;
          height: 37.22px;
          background: #fd7835;
          border-radius: 6.88342px;
          cursor: pointer;
          border: none;
          font-family: 'Century Gothic';
          font-style: normal;
          font-weight: 700;
          font-size: 11.2271px;
          line-height: 250%;
          color: #ffffff;
          margin: 0.6rem 1rem;
        }
      }
    }
  }

  .selected-addon .addon-count-changer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 56.28px;
    background: #fd7835;
    border-radius: 10.368px;
    width: 120px;
    margin-top: 13px;

    span {
      color: white;
      font-family: 'Century Gothic';
      font-weight: 700;
      font-size: 27px;
    }

    button {
      background-color: transparent;
      height: 100%;
      width: 40px;
      border: none;
      outline: none;
      color: white;
      font-family: 'Century Gothic';
      font-weight: 700;
      padding: 0px;
      font-size: 27px;
      cursor: pointer;
    }

    button:active {
      background-color: #e06a2f;
    }
  }

  .selected-addon-more {
    width: 100%;

    .label {
      font-family: 'Century Gothic';
      font-weight: 700;
      font-size: 24px;
      color: #fd7835;
      margin-bottom: 10px;
    }

    .addon-more-save {
      font-family: 'Century Gothic';
      font-weight: 700;
      font-size: 18px;
      text-decoration-line: underline;
      color: #fd7835;
      text-align: center;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  .selected-confirm {
    font-family: 'Century Gothic';
    font-style: normal;
    font-size: 24px;
    text-align: center;

    .total {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 26px 20px 90px 20px;
    background: #fbf1e6;
    width: 90vw;

    .orange-text {
      color: #fd7835;
    }

    .section {
      padding: 20px;
    }

    .section-seperator {
      width: 100%;
      height: 0.5px;
      background-color: #4c0d18;
    }

    .selected-tier,
    .selected-addon {
      width: 100%;
      .tier-title {
        font-family: 'Nielmaden';
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
      }

      .tier-availability {
        font-family: 'Century Gothic';
        font-weight: 700;
        font-size: 18px;
        margin-top: 21px;
        margin-bottom: 12px;
      }

      .tier-benefits {
        font-family: 'Century Gothic';
        font-weight: 400;
        font-size: 12px;
      }

      .tier-price {
        font-family: 'Nielmaden';
        font-weight: 700;
        font-size: 18px;
        margin-top: 13px;
      }

      .tier-edit {
        margin-top: 18px;
        font-family: 'Inter';
        font-weight: 700;
        font-size: 14px;
        text-decoration-line: underline;
        color: #fd7835;
        cursor: pointer;
      }
    }

    .selection-container {
      margin-top: -23px;
      margin-bottom: 2rem;
      width: 100%;
    }
    
    .selection-container .info {
        font-size: 8px;
    }
    
    .selection-container .tier-selection {
        width: 100%;
    }
    
    .selection-container .tier-selection .option .option-name {
        font-size: 12px;
    }
    
    .selection-container .tier-selection .option .add-btn {
        width: 52px;
        height: 26.22px;
        font-size: 8px;
        margin: 0.6rem 1rem 0.6rem 0.5rem;
    }
  

    .selected-addon .addon-count-changer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      height: 40px;
      background: #fd7835;
      border-radius: 7px;
      width: 90px;
      margin-top: 10px;

      span {
        color: white;
        font-family: 'Century Gothic';
        font-weight: 700;
        font-size: 27px;
      }

      button {
        background-color: transparent;
        height: 100%;
        width: 40px;
        border: none;
        outline: none;
        color: white;
        font-family: 'Century Gothic';
        font-weight: 700;
        padding: 0px;
        font-size: 27px;
      }

      button:active {
        background-color: #e06a2f;
      }
    }

    .selected-addon-more {
      width: 100%;

      .label {
        font-family: 'Century Gothic';
        font-weight: 700;
        font-size: 14px;
        color: #fd7835;
        margin-bottom: 10px;
      }

      .addon-more-save {
        font-family: 'Century Gothic';
        font-weight: 700;
        font-size: 12px;
        text-decoration-line: underline;
        color: #fd7835;
        text-align: center;
        margin-top: 5px;
        cursor: pointer;
      }
    }

    .selected-confirm {
      font-family: 'Century Gothic';
      font-style: normal;
      font-size: 18px;
      text-align: center;

      .total {
        margin-bottom: 17px;
      }
    }
  }
`;

const DropDownContainer = styled('div')`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const DropDownHeader = styled('div')`
  background: #4c0d18;
  border-radius: 10px;
  font-family: 'Century Gothic';
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  padding: 21px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  user-select: none;

  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const DropDownListContainer = styled('div')`
  position: absolute;
  margin-top: 0.5rem;
  width: 100%;
`;

const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  background: #4c0d18;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 10px;

  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const ListItem = styled('li')`
  list-style: none;
  font-family: 'Century Gothic';
  font-weight: 700;
  font-size: 24px;
  padding: 21px;
  user-select: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

export default ReviewSelectionStyled;
export {
  DropDownContainer,
  DropDownListContainer,
  DropDownList,
  ListItem,
  DropDownHeader,
};
