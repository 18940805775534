import styled from 'styled-components';

const FooterStyle = styled.div`
  display: flex;
  flex-direction: column;
  @media(max-width: 800px){
    width: 100%;
    margin-top: 21px;
  }

  .footer-top {
    height: 65px;
    background: #4c0d18;
    @media(max-width: 800px){
      height: 22px;
    }
  }

  .footer-middle-wrapper {
    display: flex;
    justify-content: center;
    background: #fbf1e6;
    @media(max-width: 800px){
      padding: 20px;
    }
  }

  .footer-middle {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 0px;
    width: 1140px;
    @media(max-width: 800px){
      width: -webkit-fill-available;
      padding-top: 10px;
    }

    .follow-us-on {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 600;
      font-size: 64px;
      line-height: 90%;
      color: #0a3047;
      @media(max-width: 800px){
        font-size: 22px;
      }
    }

    .social-media-icons {
      display: flex;
      column-gap: 146px;
      width: 994px;
      font-family: 'Century Gothic BO';
      font-style: normal;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: #0a3047;
      border-bottom: 1px solid black;
      margin-top: 28px;
      padding-bottom: 14px;
      @media(max-width: 800px){
        font-size: 6px;
        width: -webkit-fill-available;
        column-gap: 48px;
        
      }

      a {
        color: #0a3047;
        text-decoration: none;
      }

      .discord-div {
        display: flex;
        column-gap: 9px;
      }

      .twitter-div {
        column-gap: 9px;
        display: flex;
        margin-left: -7px;
      }

      .instagram-div {
        column-gap: 9px;
        display: flex;
        margin-left: 7px;
      }

      .facebook-div {
        column-gap: 9px;
        display: flex;
        margin-left: -20px;
      }
      .logo-image{
        @media(max-width: 800px){
          width: 8.33px;
          height: 8.33px;
        }
      }
    }

    .footer-links {
      display: flex;
      justify-content: flex-start;
      font-family: 'Century Gothic BO';
      font-style: normal;
      font-size: 18px;
      line-height: 275%;
      color: #4c0d18;
      padding-top: 17px;
      @media(max-width: 800px){
        font-size: 8px;
      }

      a {
        font-family: 'Century Gothic BO';
        font-style: normal;
        font-size: 18px;
        line-height: 275%;
        color: #4c0d18;
        text-decoration: none;
        @media(max-width: 800px){
          font-size: 8px;
        }
      }

      .link-div-one {
        display: flex;
        flex-direction: column;
        width: 240px;
        height: 249px;
        @media(max-width: 800px){
          height: auto;
        }
      }

      .link-div-two {
        display: flex;
        flex-direction: column;
        width: 240px;
        height: 249px;
        @media(max-width: 800px){
          height: auto;
        }
      }

      .link-div-three {
        display: flex;
        flex-direction: column;
        width: 240px;
        height: 249px;
        margin-left: -4px;
        @media(max-width: 800px){
          height: auto;
        }
      }

      .link-div-four {
        display: flex;
        flex-direction: column;
        width: 240px;
        height: 249px;
        margin-left: 2px;
        @media(max-width: 800px){
          height: auto;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    height: 54px;
    background: #4c0d18;
    justify-content: center;
    @media(max-width: 800px){
      height: 22px;
    }

    .copy-right {
      height: 20px;
      width: 1140px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      margin: 9px 0px 25px 0px;
      color: #fbf1e6;
      @media(max-width: 800px){
        height: auto;
        width: 90%;
        font-size: 8px;
        margin: auto;
      }
    }
  }
`;

export default FooterStyle;
