import React from 'react';
import AlertStyle from './style';
import Button from '../Button';

function Alert({ onClose }) {
  return (
    <AlertStyle>
      <h2>You are Already Whitelisted</h2>
      <Button onClick={onClose}>Ok</Button>
    </AlertStyle>
  );
}

export default Alert;
