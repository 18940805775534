import instance from './axios';

function saveWalletAddress(
  name,
  address,
  emailAddress,
  discordId,
  tier,
  addOnOne,
  addOnTwo,
  addOnThree
) {
  return instance
    .post('/whitelist/', {
      name,
      wallet_address: address,
      email: emailAddress,
      discord: discordId,
      tier,
      addon_one: addOnOne,
      addon_two: addOnTwo,
      addon_three: addOnThree,
    })
    .then(
      (res) => res,
      (error) => error.response
    );
}

function isJoined(address) {
  return instance.get('/check/whitelist/', { params: { address } }).then(
    (res) => res,
    (error) => error.response
  );
}

function getTier(address) {
  return instance.get('/reserved/', { params: { address } }).then(
    (res) => res,
    (error) => error.response
  );
}

const addEmail = (email, successCB) => instance.post('/email/', { email }).then(
  (res) => {
    successCB();
    return res;
  },
  (error) => error.response
);

function saveShippingAddress(payload) {
  return instance.post('/shipping/', payload);
}

function shippingAddressExists(address) {
  return instance.get('/shipping_address_exist/', { params: { address } }).then(
    (res) => res,
    (error) => error.response
  );
}

function saveReferralCode(payload) {
  return instance.post('/referrals/', payload, {headers: { "Content-Type": "multipart/form-data" }});
}

export { saveWalletAddress,
  isJoined,
  getTier,
  addEmail,
  saveShippingAddress,
  shippingAddressExists,
  saveReferralCode,
};
