import useCountdown from '../../utils/useCountdown';
import TimerStyled from './style';

function Timer({ targetDate }) {
  const [days, hours, minutes] = useCountdown(targetDate);

  return (
    <TimerStyled>
      <div className='days'>
        <div className='text day-text'>Days</div>
        <div className='value day-value'>{days}</div>
      </div>
      <div className='hours'>
        <div className='text hour-text'>Hours</div>
        <div className='value hour-value'>{hours}</div>
      </div>
      <div className='minutes'>
        <div className='text minute-text'>Minutes</div>
        <div className='value minute-value'>{minutes}</div>
      </div>
    </TimerStyled>
  );
}

export default Timer;
