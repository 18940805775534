// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useConnect } from 'wagmi';
import WalletSelectorStyle from './style';
import store from '../../store';

function WalletSelector({ providers, onSuccess }) {
  const { contractStore } = store;
  // eslint-disable-next-line no-unused-vars
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
  const mql = window.matchMedia('(min-width: 800px)');
  const webView = mql.matches;

  const handleWalletSelect = (e, provider, connector) => {
    contractStore.setCurrentProvider(provider.wallet);
    e.stopPropagation();
    if (provider.installed) {
      connect({ connector });
      onSuccess();
      return;
    }

    if (navigator.appVersion.indexOf('Android') !== -1) {
      window.open(provider.androidUrl, '_blank');
    } else if (
      navigator.appVersion.indexOf('iPhone') !== -1 || navigator.appVersion.indexOf('iPad') !== -1) {
      window.open(provider.iphoneUrl, '_blank');
    } else {
      window.open(provider.installUrl, '_blank').focus();
    }
  };

  const getWallet = (connector) => {
    const provider = providers.find((p) => p.wagmiName === connector.name);
    if (webView && connector.name === 'Injected') return null;
    if (!webView && connector.name === 'Coinbase Wallet') return null;

    return (
      <div
        className='wallet-item'
        onClick={(e) => {
          handleWalletSelect(e, provider, connector);
        }}
        onKeyDown={(e) => {
          handleWalletSelect(e, provider, connector);
        }}
      >
        <div className='wallet-title'>
          {provider.installed ? provider.name : 'Install'} wallet
        </div>
        <div className='wallet-logo'>
          <img
            src={provider.logo}
            alt={provider.name}
          />
        </div>
      </div>
    );
  };

  return (
    <WalletSelectorStyle>
      <>
        <h2 className='selector-title'>
          Connect to a Wallet
        </h2>
        <div className='wallet-list'>
          {connectors.map((connector) => (
            getWallet(connector)
          ))}
        </div>
        {error && <div>{error.message}</div>}
      </>
    </WalletSelectorStyle>
  );
}

export default WalletSelector;
