import styled from 'styled-components';

const ModalStyle = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  z-index: 999;
  padding: 3rem 0px;

  .modal-wrapper {
    position: relative;
    margin: auto;
  }

  .modal-content {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 12px 12px rgba(10, 48, 71, 0.45);
    border: 0.6px solid #4c0d18;
  }

  .close-btn {
    position: absolute;
    width: 37.8px;
    height: 37.8px;
    left: -10px;
    top: -9.33px;
    background: #fd7835;
    box-shadow: 0px 5.60018px 5.60018px rgb(10 48 71 / 50%);
    border: none;
    border-radius: 50%;
    color: #ffffff;
    font-family: 'Century Gothic';
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    z-index: 1;

    .close-text {
      position: relative;
      transform: scale(1.4);
      font-size: 18px;
    }
  }
`;

export default ModalStyle;
