/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import TierBreakDownStyled from './style';
import Header from '../Header';
import tierOneBg from '../../assets/tier-1-image.png';
import tierTwoBg from '../../assets/tier-2-image.png';
import tierOneBadge from '../../assets/tier-1-badge.png';
import tierTwoBadge from '../../assets/tier-2-badge.png';
import WalletButton from '../WalletButton';
import tierList from '../../utils/tier-list';
import { ETHPriceContext } from '../../context/ETHPriceContext';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function TierBreakDown() {
  const [tierOnePrice, setTierOnePrice] = useState();
  const [tierTwoPrice, setTierTwoPrice] = useState();
  const { ethUSDPrice, loading } = useContext(ETHPriceContext);

  useEffect(() => {
    if(!loading){
      const priceOne = numberWithCommas((ethUSDPrice * tierList[0].ethPrice).toFixed(0));
      const priceTwo = numberWithCommas((ethUSDPrice * tierList[1].ethPrice).toFixed(0));

      setTierOnePrice(priceOne);
      setTierTwoPrice(priceTwo);
    }
  }, [loading, ethUSDPrice]);

  return (
    <TierBreakDownStyled id='Core-Member-Tiers'>
      <div className='container'>
        <Header
          headerMaxWidth='1213px'
          headerWidth='100%'
          headerHeight='160px'
          borderBottom='1px solid #404756'
          headingOne='More Details'
          headingTwo='Membership Tiers'
          alignItems='start'
          bottomTextAlign='start'
          rowGap='25px'
        />
        <div className='content border-bottom'>
          <div className='left-content'>
            <div className='top-left-content'>
              <div className='heading ff-NL'>Tier 1: Single Barrel Savant</div>
              <div className='sub-heading ff-BO'>Availability: 193</div>
              <div className='desc ff-CG fw-400'>
                <span className='fw-700 ff-BO'>Benefits</span>: Our premium tier
                includes the best club has to offer, including an exclusive
                Sagamore Spirits single barrel bottle at launch, first access to
                new drops, chance to select barrels with us in person and
                virtually, spot in allocated bottle randomizer, and much more.
                <br />
                <br />
                <a href='#Benifits-Pricing' className='fw-700 ff-BO'>
                  See More Details
                </a>
              </div>
            </div>
            <div className='bottom-left-content'>
              <div className='sale'>
                <div className='text ff-NL'>Price</div>
                <div className='price ff-BO' style={{ color: '#0a3047' }}>
                  ${tierOnePrice}
                </div>
              </div>
              <WalletButton
                action='CONNECT_AND_WHITELIST'
                defaultText='Select'
                currentTier='tier-one'
              />
            </div>

            <div className='bottom-content-mobile'>
              <div className='sale-wrapper'>
                <div className='sale'>
                  <div className='text ff-NL'>Price</div>
                  <div className='price ff-BO' style={{ color: '#0a3047' }}>
                    ${tierOnePrice}
                  </div>
                </div>
              </div>
              <WalletButton
                action='CONNECT_AND_WHITELIST'
                defaultText='Select'
                currentTier='tier-one'
              />
            </div>
          </div>
          <div className='right-content'>
            <img src={tierOneBg} alt='' className='image' />
            <img
              src={tierOneBadge}
              alt=''
              className='badge'
              height='170px'
              width='170px'
            />
            <div className='cut' />
          </div>
        </div>
        <div className='content'>
          <div className='left-content'>
            <div className='top-left-content'>
              <div className='heading ff-NL'>
                Tier 2: Single Barrel Advocate
              </div>
              <div className='sub-heading ff-BO'>Availability: 1,800</div>
              <div className='desc ff-CG fw-400'>
                <span className='fw-700 ff-BO'>Benefits</span>: Our “silver”
                access tier gets you a unique Sagamore Spirits bottle at launch,
                advanced 24 hour access before the general public, chance to
                join our virtual barrel selection experiences, free Glencairn
                glass, and more.
                <br />
                <br />
                <a href='#Benifits-Pricing' className='fw-700 ff-BO'>
                  See More Details
                </a>
              </div>
            </div>
            <div className='bottom-left-content' style={{ marginTop: '2rem' }}>
              <div className='sale'>
                <div className='text ff-NL'>Price</div>
                <div className='price ff-BO' style={{ color: '#0a3047' }}>
                  ${tierTwoPrice}
                </div>
              </div>
              <WalletButton
                action='CONNECT_AND_WHITELIST'
                defaultText='Select'
                currentTier='tier-two'
              />
            </div>
            <div className='bottom-content-mobile'>
              <div className='sale-wrapper'>
                <div className='sale'>
                  <div className='text ff-NL'>Price</div>
                  <div className='price ff-BO' style={{ color: '#0a3047' }}>
                    ${tierTwoPrice}
                  </div>
                </div>
              </div>
              <WalletButton
                action='CONNECT_AND_WHITELIST'
                defaultText='Select'
                currentTier='tier-two'
              />
            </div>
          </div>
          <div className='right-content'>
            <img src={tierTwoBg} alt='' className='image' />
            <img
              src={tierTwoBadge}
              alt=''
              className='badge'
              height='170px'
              width='170px'
            />
            <div className='cut' />
          </div>
        </div>
      </div>
    </TierBreakDownStyled>
  );
}

export default TierBreakDown;
