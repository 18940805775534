import { useState, createContext, useEffect, useMemo } from 'react';

const DEFAULT_STATE = {
  ethUSDPrice: null,
  loading: true,
};

export const ETHPriceContext = createContext(DEFAULT_STATE);

function ETHPriceProvider(props) {
  const { children } = props;
  const [ethUSDPrice, setEthUSDPrice] = useState(null);

  useEffect(() => {
    fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD').then(
      (res) => res.json()
    ).then((data) => {
      setEthUSDPrice(data.USD)
    });
  }, []);

  const value = useMemo(() => ({
    ethUSDPrice,
    loading: ethUSDPrice === null,
  }), [ethUSDPrice]);

  return <ETHPriceContext.Provider value={value}>{children}</ETHPriceContext.Provider>;
}
export default ETHPriceProvider;
