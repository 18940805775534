import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 8rem;

  .container {
    width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .header {
      font-family: 'Nielmaden';
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 120%;
      text-align: center;
      color: #0a3047;
    }

    .desc-sm {
      display: none;
    }

    .sub-heading {
      width: 986px;
      height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      .heading {
        font-style: normal;
        font-size: 24px;
        line-height: 90%;
        text-align: center;
        color: #0a3047;
      }

      .desc {
        font-style: normal;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #0a3047;
        margin-bottom: 10px;

        a {
          color: #fd7835;
        }
      }
    }

    .bottles {
      width: 1140.48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;
      flex-wrap: wrap;
      row-gap: 25px;
      padding-top: 3rem;
      padding-bottom: 2rem;
    }

    .list-container {
      width: 1122px;
      display: flex;
      flex-direction: column;
      background: #fbf1e6;
      border-radius: 25px;
      justify-content: space-evenly;

      .list-container-two {
        width: 1122px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 5px;
        justify-content: center;
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 200%;
        text-align: center;
        color: #0a3047;
      }

      .get-notified {
        width: 147.12px;
        background: #fd7835;
        border-radius: 9.6687px;
        border: none;
        color: #ffffff;
        font-family: 'Century Gothic BO';
        font-style: normal;
        font-size: 14.5031px;
        line-height: 140%;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        padding: 10px 0px;
        margin-top: 25px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .container {
      width: 100%;
      height: auto;

      .header {
        font-size: 38px;
      }

      .sub-heading {
        width: 85vw;
        margin-top: 35px;
        height: 185px;

        .heading {
          line-height: 120%;
        }
      }

      .desc-lg {
         display: none;
      }

      .desc-sm {
        display: block;
      }

      .list-container {
        width: 90vw;
        padding-top: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        row-gap: 0px;

        .list-container-two {
          width: 100%;
          font-size: 12px;
          flex-wrap: wrap;
          text-align: left;
          line-height: 180%;
          column-gap: 15px;

          div {
            width: 100%;
          }
        }
      }
    }

  }
`;
