import React, { useState } from 'react';
import NavStyle from './style';
import WalletButton from '../WalletButton';
import useIsMobile from '../../hooks/useIsMobile';

export default function Nav() {
  const [hamburger, setHamburger] = useState(false);
  const { isMobile } = useIsMobile()

  return (
    <NavStyle>
      <a href='/' className='logo-wrapper'>
        <img
          className='logo-image'
          sizes='(max-width: 1400px) 100vw, 1400px'
          srcSet='
            https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_200.png 200w,
            https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_363.png 363w,
            https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_488.png 488w,
            https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_603.png 603w,
            https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_687.png 687w,
            https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_687.png 789w'
          src='https:// assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_687.png'
          alt='Prime Barrel logo'
        />
        {/* https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_868.png 868w,
        https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_957.png 957w,
        https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_1038.png 1038w,
        https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_1084.png 1084w,
        https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_1160.png 1160w,
        https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_1266.png 1266w,
        https://assets.primebarrel.com/WebsiteImages/LogoResponsive/TM_20logo_l7ay0z_c_scale%2Cw_1400.png 1400w' */}
      </a>
      <div className='wallet-btn'>
        <WalletButton action='CONNECT' />
      </div>
      <div
        className='hamburger-menu'
        onClick={() => {
          setHamburger(!hamburger);
        }}
        onKeyDown={() => ''}
        role='button'
      >
        <div className={`bar-top ${hamburger ? 'bar-top-rotated' : ''}`} />
        {!hamburger && <div className='bar-middle' />}
        <div
          className={`bar-bottom ${hamburger ? 'bar-bottom-rotated' : ''}`}
        />
      </div>

      {hamburger && (
        <div className='drop-down-menu'>
          <button
            type='button'
            className='close-btn'
            onClick={() => {
              setHamburger(false);
            }}
          >
            <div className='x-text'>X</div>
          </button>
          <div className='drop-down-links'>
            <div className='underline'>
              <a onClick={() => { if(isMobile){setHamburger(false)} }} href='#Why-NFTs'>About our Membership</a>
            </div>
            <div className='underline'>
              <a onClick={() => { if(isMobile){setHamburger(false)}} } href='#Core-Member-Tiers'>Member Tiers</a>
            </div>
            <div className='underline'>
              <a onClick={() => { if(isMobile){setHamburger(false)}} } href='#Testimonials'>Testimonials</a>
            </div>
            <div className='underline'>
              <a onClick={() => { if(isMobile){setHamburger(false)}} } href='#Benifits-Pricing'>Benefits & Pricing</a>
            </div>
            <div className='underline'>
              <a onClick={() => { if(isMobile){setHamburger(false)}} } href='#NFT-Art'>Club Art</a>
            </div>
            <div className='underline'>
              <a onClick={() => { if(isMobile){setHamburger(false)}} } href='#Brand-Partners'>Brand Partners</a>
            </div>
            <div className='underline'>
              <a onClick={() => { if(isMobile){setHamburger(false)}} } href='#Exclusive-Bottles'>Exclusive Bottles</a>
            </div>
            <div className='underline'>
              <a onClick={() => { if(isMobile){setHamburger(false)}} } href='#Meet-The-Team'>Meet The Team</a>
            </div>
            <div className='underline'>
              <a onClick={() => { if(isMobile){setHamburger(false)}} } href='#FAQs'>FAQs</a>
            </div>
            {/* <div className='underline'>
              <a onClick=() => { if(isMobile){setHamburger(false)}  href='#Tools-Resources'>Tools & Resources</a>
            </div> */}
            <div className='underline'>
              <a onClick={() => { if(isMobile){setHamburger(false)}} } href='#Whitepaper'>Whitepaper</a>
            </div>
            <div className=''>
              <a onClick={() => { if(isMobile){setHamburger(false)} }} href='#Current-Selections'>Shop Current Selections</a>
            </div>
          </div>
        </div>
      )}
    </NavStyle>
  );
}
