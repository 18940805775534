import styled from 'styled-components';

const TierBreakDownStyled = styled.div`
  height: 1567px;
  background: #fbf1e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container {
    width: 100%;
    max-width: 1160px;
    height: 1443px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 33px;
    padding: 0 20px;

    .border-bottom {
      border-bottom: 1px solid #4c0d18;
    }

    .content {
      height: 580px;
      display: flex;
      justify-content: space-between;

      .left-content {
        width: 483px;
        height: 540px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top-left-content {
          width: 529px;
          height: 284px;

          .heading {
            font-style: normal;
            font-weight: 750;
            font-size: 34px;
            line-height: 90%;
            color: #0a3047;
            margin-bottom: 34px;
          }

          .sub-heading {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 90%;
            color: #222222;
            margin-bottom: 27px;
            margin-top: 2rem;
          }

          .desc {
            font-style: normal;
            font-size: 18px;
            line-height: 140%;
            color: #404756;
          }

          a {
            color: #fd7835;
          }
        }

        .bottom-left-content {
          width: 253.89px;
          height: 234px;
          display: flex;
          flex-direction: column;
          row-gap: 17px;

          .sale {
            width: 259.89px;
            height: 78px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .text {
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 90%;
              color: #0a3047;
            }

            .price {
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 140%;
              color: #ff5a20;
            }
          }
        }

        .bottom-content-mobile {
          display: none;
        }
      }

      .right-content {
        // width: 620px;
        // height: 560px;
        position: relative;
        right: 70px;
        top: 14px;

        .image {
          width: 560px;
          height: 560px;
          border-radius: 50%;
        }

        .cut {
          position: absolute;
          top: 2px;
          left: 0px;
          width: 560px;
          height: 560px;
          clip-path: polygon(0 60%, 0% 100%, 100% 100%);
          background: #FBF1E6;
        }

        .badge {
          position: absolute;
          top: 300px;
          left: -86px;
          z-index: 1;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    background: #fbf1e6;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 1rem;
    height: auto;

    .container {
      width: 80vw;
      height: auto;
      display: flex;
      flex-direction: column;
      row-gap: 35px;
      margin-top: 4rem;


      .content {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;

        .left-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: auto;
          height: auto;

          .top-left-content {
            width: auto;
            height: auto;

            .heading {
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 90%;
              color: #0a3047;
              margin-bottom: 34px;
              text-align: center;
            }

            .sub-heading {
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 90%;
              color: #222222;
              margin-bottom: 27px;
              text-align: center;
            }

            .desc {
              width: 100%;
              height: auto;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              color: #404756;
              text-align: center;

              a {
                color: #fd7835;
              }
            }
          }

          .bottom-left-content {
            display: none;
          }

          .bottom-content-mobile {
            width: 100%;
            height: 234px;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .sale-wrapper {
              display: flex;
              height: 78px;
              width: 300px;
              align-items: center;
              justify-content: center;
              margin-bottom: 45px;
            }

            .vr-line {
              height: 78px;
              width: 1px;
              background-color: #0A3047;
            }

            .sale {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              row-gap: 6px;

              .text {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 90%;
                color: #0a3047;
              }

              .price {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 140%;
                color: #ff5a20;
              }
            }
          }
        }

        .right-content {
          position: relative;
          top: 0px;
          left: 0px;
          width: 100%;
          height: auto;

          .image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            left: unset;
          }

          .cut {
            position: absolute;
            top: 2px;
            left: 0px;
            width: 100%;
            height: 80vw;
            clip-path: polygon(0 60%, 0% 100%, 100% 100%);
            background: #FBF1E6;
          }

          .badge {
            position: absolute;
            top: unset;
            left: unset;
            right: 0px;
            bottom: 0px;
            width: 25vw;
            height: 25vw;
            z-index: 1;
          }
        }
      }
    }
  }
`;
export default TierBreakDownStyled;
