/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

const ContentStyled = styled.div`
  font-size: ${(props) => props.fontSize};
  display: flex;
  flex-direction: ${(props) =>
  props.flexDirection ? props.flexDirection : 'column'};
  justify-content: ${(props) =>
  props.justifyContent ? props.justifyContent : ''};
  align-items: center;
  column-gap: 14px;
  width: 65%;
  height: 100%;

  .shopify-card {
    iframe {
      margin: 0;
    }
  }

  .brand-partner-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
      
    @media(max-width: 800px){
      display: none;
    }
 
    .partner-one {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .partner-two {
      width: 100%;
      margin-top: 24px;
    }
  }

  .partners-mobile{
    width: 100%;
      
    @media(min-width: 800px){
      display: none;
    }
      
    .Array-one-div{
      display: flex;
      justify-content: center;
      align-items: end;
      column-gap: 16px;
      flex-wrap: wrap;
    }
    .Array-two-div{
      display: flex;
      justify-content: center;
      align-items: end;
      column-gap: 16px;
      flex-wrap: wrap;
    }
    .Array-three-div{
      display: flex;
      justify-content: center;
      align-items: end;
      column-gap: 16px;
      flex-wrap: wrap;
    }
  }

  a {
    color: #fd7835;
    font-family: 'Century Gothic BO';
    font-weight: 700;
  }

  .stickers {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #continue {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #fd7835;
    margin-top: 2rem;
  }

  .name-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #0a3047;
  }

  .name-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #0a3047;
  }

  .bottle-container {
    width: 164.07px;
    height: 271px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 0.541938px solid #4c0d18;
    border-radius: 13.5485px;
    margin-top: 35px;

    img {
      width: 79.89px;
      height: 119.83px;
      mix-blend-mode: multiply;
    }

    .label {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 110.61px;
      height: 48.55px;
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 9.75489px;
      line-height: 150%;
      color: #0a3047;

      .selection-label {
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 9.75489px;
        line-height: 150%;
        color: #0a3047;
        border-bottom: 0.541938px solid #4c0d18;
        padding-bottom: 8px;
      }

      .price {
        font-family: 'Century Gothic BO';
        font-style: normal;
        font-weight: 700;
        font-size: 9.75489px;
        line-height: 180%;
        text-align: center;
        color: #404756;
        padding-top: 5px;
      }
    }

    .shop-now {
      width: 88.84px;
      height: 28.17px;
      background: #fd7835;
      border-radius: 6.50326px;
      border: none;
      color: #ffffff;
      font-family: 'Century Gothic BO';
      font-style: normal;
      font-weight: 700;
      font-size: 9.75489px;
      line-height: 140%;
      text-align: center;
      cursor: pointer;
    }
  }

  .get-notified {
    width: 106.65px;
    background: #fd7835;
    border-radius: 7.21177px;
    border: none;
    color: #ffffff;
    font-family: 'Century Gothic BO';
    font-style: normal;
    font-weight: 700;
    font-size: 9.75489px;
    line-height: 140%;
    text-align: center;
    cursor: pointer;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1rem;

    text-decoration: none;
  }

  .partner-all {
    display: none;
  }

  .shopify-buy-frame {
    width: 180px;
    height: 290px;
    border: 1px solid #4c0d18;
    border-radius: 13.5485px;
    padding: 5px 0px;

    iframe {
      height: 280px !important;
      width: 85% !important;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
      
    .partner-one,
    .partner-two {
      display: none !important;
    }

    .partner-all {
      display: grid;
      grid-template-columns: 55px 55px 55px;
      grid-template-rows: 65px 65px 65px;
      row-gap: 1rem;
      column-gap: 1rem;
      align-items: flex-end;

      .stickers {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-around;

        img {
          width: 100%;
        }

        div {
          font-size: 7px;
          line-height: 10px;
        }
      }
    }
  }
`;

export { ContentStyled };
