import styled from 'styled-components';

const AlertStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 80px 36px 80px;
  background: #fbf1e6;
  width: 600px;
  color: #0A3047;

  @media screen and (max-width: 800px) {
    width: 90vw;
    padding: 26px 40px 36px 40px;

  }
`;

export default AlertStyle;
