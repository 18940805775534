import styled from 'styled-components';


export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 45px;
  padding-bottom: 130px;

  @media (max-width: 800px) {
    height: auto;
    align-items: baseline;
    padding-bottom: 30px;
  }

  .container {
    width: 100%;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    row-gap: 55px;

    @media (max-width: 800px) {
      padding: 20px 16px;
      height: auto;
      width: -webkit-fill-available;
    }

    .section {
      .content-wrapper {
        display: flex;
        column-gap: 70px;
        justify-content: space-between;
        align-items: center;

          @media (max-width: 800px) {
              flex-direction: column;
          }
      }
        
      & + .section {
        padding-top: 90px;
        border-top: 1px solid #000000;;
      }

      .section-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 23px;

        @media (max-width: 800px) {
          width: -webkit-fill-available;
          align-items: center;
          height: auto;
          margin-bottom: 50px;
        }

        .desc {
          display: flex;
          flex-direction: column;
          margin-bottom: 7px;

          @media (max-width: 800px) {
            width: -webkit-fill-available;
            align-items: center;
            margin-bottom: 20px;
          }

          .heading {
            font-family: 'Nielmaden';
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 140%;
            color: #0a3047;
            margin-bottom: 0.6rem;

            @media (max-width: 800px) {
              text-align: center;
              font-size: 28px;
            }
          }
 
          .text {
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 400;
            font-size: 23px;
            line-height: 140%;
            color: #0a3047;
            letter-spacing: 0.1px;

            @media (max-width: 800px) {
              text-align: center;
              font-size: 18px;
            }
          }
        }

        .find-more {
          width: 179px;
          padding-top: 10px;
          padding-bottom: 10px;
          background: #fd7835;
          color: #ffffff;
          border-radius: 12px;
          border: none;
          font-family: 'Century Gothic';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
          text-decoration: none;
        }
      }

      .section-right {
        line-height: 0;
        background-color: black;

        @media (max-width: 800px) {
          width: 100%;
          aspect-ratio: 1/1;
        }

        iframe {
          pointer-events: all;

          @media (max-width: 800px) {
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
          }
        }

        .play-image-wrapper {
          width: 505px;
          height: 505px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #4c0d18;
            
          .play-icon {
              cursor: pointer;
              position: absolute;
          }
            
          @media (max-width: 800px) {
            width: 100%;
            height: 100%;
              
            svg:not(.play-icon), img {
                width: 80%;
                height: auto;
            }
          }
        }

        .reel-video {
          @media (max-width: 800px) {
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }
  }
`;

export const OuterCarousel = styled.div`
    margin-top: 108px;

    .image-container {
        width: 505px;
        height: 448px;
        object-fit: cover;
        background: #4C0D18;

        @media (max-width: 800px) {
            width: 100%;
            height: auto;
            aspect-ratio: 1 / 1;
        }
    }
`;
