import styled from 'styled-components';

const PartnershipsStyled = styled.div`
  padding-top: 50px;
  padding-bottom: 85px;
  background: #fbf1e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    
  @media(max-width: 800px){
    padding: 16px;
    height: auto;
    padding-top: 40px;
  }

  .container {
    width: 1250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
      
    @media(max-width: 800px){
      width: auto;
 
      .third-text {
        display: none !important;
      }
    }

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      column-gap: 14px;
      border-radius: 25px;
      justify-content: center;
      padding-top: 60px;
      padding-bottom: 60px;
        
      &.list {
          background: #4c0d18;

          @media(max-width: 800px){
              padding-top: 35px;
              padding-bottom: 35px;
              margin-top: 45px;
          }
      }
        
      &.stickers {
          @media(min-width: 800px){
              margin-top: 15px;
          }
      }
        
      @media(max-width: 800px){
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: -webkit-fill-available;
        padding: 16px;
        margin-top: -0.5rem;
      }

      .list-container {
        width: 100%;
        display: grid;
        text-align: left;
        grid-auto-flow: column;
        justify-content: space-evenly;
        font-family: 'Century Gothic BO';
        font-size: 19.1765px;
        line-height: 170%;
        color: #fbf1e6;
          
        @media(max-width: 800px){
          font-size: 12px;
          text-wrap: nowrap;
        }
      }

      .bottom-text {
        font-family: 'Nielmaden';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 1;
        text-align: center;
        color: #fd7835;
        margin-top: 16px;
      }

      .brand-partner-container {
        display: flex;
          width: 100%;
        flex-direction: column;
        align-items: center;
        row-gap: 28px;

        @media(max-width: 800px){
          display: none;
        }

        .partner-one {
          width: 90%;
          display: flex;
          flex-direction: column;

          .name-one {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 400;
            font-size: 23.811px;
            line-height: 120%;
            text-align: center;
            color: #0a3047;
          }
        }

        .partner-two {
          width: 100%;
          margin-top: 24px;
          margin-bottom: -14px;

          .name-two {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 400;
            font-size: 23.811px;
            line-height: 120%;
            text-align: center;
            color: #0a3047;
          }
        }

        .stickers {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .partners-mobile{
        @media(min-width: 800px){
          display: none;
        }

        .Array-div{
          display: grid;
          grid-template-columns: 1fr 1fr 1fr; 
          column-gap: 26px;
          row-gap: 1rem;
          margin-bottom: 30px;

          .logo-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 0.5rem;


            img {
              width: 100% !important;
              height: auto !important;
            }
          }
        }
      }
    }
  }
`;

const Outer = styled.div`
  width: 1250px;
  @media(max-width: 800px){
    width: -webkit-fill-available;
  }

  .carousel.carousel-slider {
      padding-bottom: 65px;
    @media(max-width: 800px){
      height: auto;
      overflow: unset;
    }
  }

  .carousel.carousel-slider .control-arrow {
    background: transparent;
    opacity: 1;
    height: 35px;
    width: 35px;
    top: 58%;
    right: 15px;
    padding: 0px;
    transform: scale(2);

    @media (max-width: 800px){
      height: 35px;
      background: #FBF1E6;
      border-radius: 50%;
      top: 50%;
      transform: scale(1.2);
    }
  }

  .carousel .control-prev.control-arrow {
      left: 12px;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 16px solid #FF5A20;

    @media (max-width: 800px){
      margin-left: 5px;
    }
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 16px solid #FF5A20;

    @media (max-width: 800px){
      margin-right: 10px;
    }
  }

  @media (max-width: 800px){
    .carousel .control-prev.control-arrow {
      left: -12px;
    }

    .carousel .control-next.control-arrow {
      right: -12px;
    }
  }

  .dot {
    background: #FFB32E !important;
    opacity: 1 !important;
    width: 10px !important;
    height: 10px !important;
    box-shadow: none !important;
  }

  .control-dots .selected {
    background: #FF5A20 !important;
  }
`;

export { PartnershipsStyled, Outer };
