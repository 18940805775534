import React from 'react';
import styled from 'styled-components';
import Button from '../Button';

function FreeBottle({ setModal }) {
  return (
    <Box>
      <YellowLine />
      <Head>
        You’re eligible for free bottles!
      </Head>
      <Des>We just need your shipping address.</Des>
      <Button onClick={() => setModal(true)}>Get Free Bottles</Button>

    </Box>
  );
}

export default FreeBottle;

const Box = styled.div`
width: 918px;
background-color: #0A3047;
border-radius: 20px;
padding: 35px;
margin-top : 30px;
margin-bottom : 10px;
text-align: center;
@media(max-width: 800px){
    width: 90%;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}
`;

const Head = styled.h1`
font-family: Nielmaden;
font-weight: Bold;
font-size: 39.87px;
line-height: 120%;
color: #FBF1E6;
@media(max-width: 800px){
    font-size: 18.47px;
}
`;

const Des = styled.p`
font-family: Century Gothic;
font-size: 24px;
color: #FBF1E6;
@media(max-width: 800px){
    font-size: 11.07px;
}
`;

const YellowLine = styled.div`
width: 100px;
height: 8px;
background: #ffb32e;
margin: auto;
@media(max-width: 800px){
    width: 40px;
    height: 5px;
}
`;
