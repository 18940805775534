export default [
  {
    id: 1,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs+Mobile/hero-bg-mobile-1.webp',
    bgFit: 'cover',
    rowGap: '25px',
  },
  {
    id: 2,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs+Mobile/hero-bg-mobile-4.webp',
  },
  {
    id: 3,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs/BG-3.jpg',
  },
  {
    id: 4,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs+Mobile/hero-bg-mobile-4.webp',
  },
  {
    id: 5,
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs+Mobile/hero-bg-mobile-rest.webp',
    bgFit: 'cover',
  },
  {
    id: 6,
    bgFit: 'cover',
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs+Mobile/hero-bg-mobile-rest.webp',
  },
  {
    id: 7,
    bgFit: 'cover',
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs+Mobile/hero-bg-mobile-rest.webp',
    subCarousel: [
      {
        id: 71,
        headingOne: 'Present, Past and Future Club Selections',
        headingTwo: 'Shop Current Selections',
        rowGap: '8px',
        fontSize: '18px',
        contentWidth: '700.54px',
        showWalletButton: false,
        showShopifyProducts: true,
        shopNowBtn: true,
      },
    ],
  },
  {
    id: 8,
    bgFit: 'cover',
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs+Mobile/hero-bg-mobile-rest.webp',
    subCarousel: [
      {
        id: 81,
        headingOne: 'Present, Past and Future Club Selections',
        headingTwo: 'Check Out Past Selections',
        rowGap: '8px',
        fontSize: '18px',
        contentWidth: '700.54px',
        showWalletButton: false,
        showShopifyProducts: true,
        getNotifiedBtn: true,
      },
    ],
  },
  {
    id: 9,
    bgFit: 'cover',
    backGround:
      'https://assets.primebarrel.com/WebsiteImages/Carousal+BGs+Mobile/hero-bg-mobile-rest.webp',
    subCarousel: [
      {
        id: 91,
        headingOne: 'Present, Past and Future Club Selections',
        headingTwo: 'Sign Up For Future Selections',
        rowGap: '8px',
        fontSize: '18px',
        contentWidth: '700.54px',
        showWalletButton: false,
        futureSelectionsList: true,
        getNotifiedBtn: true,
      },
    ],
  },
];
