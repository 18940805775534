import styled from 'styled-components';

const BPStyled = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
  padding-bottom: 90px;

  h1, h2, h3, h4, h5, h6, p {
    margin: 0px;
    padding: 0px;
  }

  .bold-text {
    font-weight: 700;
  }

  .italic-light {
    font-size: 15px;
    font-style: italic;
  }

  .orange-text {
    color: #FD7835;
    text-decoration: none;
  }

  .orange-bg {
    background-color: #FD7835 !important;
  }

  .container {
    display: flex;
    max-width: 1260px;
    padding: 0 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sub-heading {
      margin-top: 80px;
      max-width: 1050px;

      .heading {
        font-style: normal;
        font-size: 24px;
        line-height: 90%;
        text-align: center;
        color: #0a3047;
      }

      .text {
        margin-top: 35px;
        font-style: normal;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #0a3047;
      }
    }

    .benefits-mobile {
      display: none;
    }

    .tabs {
      display: none;
    }

    .benefits {
      display: flex;
      gap: 40px;
      max-width: 1150px;
      margin-top: 115px;

      .tier {
          width: 100%;
          display: flex;
          position: relative;
          flex-direction: column;

        .badge {
          position: absolute;
          width: 100px;
          height: 100px;
          left: -30px;
          top: -65px;
        }
      }

      .tier .header-section {
        background: #4C0D18;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 1.2rem;
        padding: 3rem 2rem 2rem 2rem;
        border-radius: 25px 25px 0px 0px;
        border: 1px solid #4C0D18;
        

        h2, h3, p, .sub-title {
          font-family: 'Nielmaden';
          text-align: center;
        }

        h2 {
          font-size: 40px;
          font-weight: 700;
          text-decoration: underline;
        }

        h3 {
          font-size: 28px;
          font-weight: 700;
        }

        .sub-title {
          font-size: 24px;
          font-weight: 400;
          line-height: 2rem;
        }
      }

      .tier .body-section{
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        border-radius: 0px 0px 25px 25px; 
        border: 1px solid #4C0D18;
        padding: 25px 40px 0px 40px;
        color: #0A3047;

        .price-wrapper {
          margin-bottom: 2rem;

          h4 {
            font-size: 24px;
            font-weight: 700;
            font-family: 'Nielmaden';
          }
          .price-data-wrapper {
            display: flex;
            align-items: center;
            margin-top: 14px;
            
            .price {
              font-family: 'Century Gothic';
              font-weight: 700;
              font-size: 24px;
            }

            .line {
              background-color: #4C0D18;
              height: 1px;
              width: 100%;
              margin-left: 1rem;
            }
          }
        }

        .expect-items {
          .expect-title {
            margin-bottom: 38px;;
          }

          .expect-title h4{
            font-family: 'Nielmaden';
            font-weight: 700;
            font-size: 24px;
          }

          .expect-title .line{
            width: 100%;
            height: 1px;
            background-color: #4C0D18;
            margin-top: 0.5rem;
          }

          .item {
            display: flex;
            column-gap: 15px;
            align-items: flex-start;
            margin-bottom: 23px;
            
            .item-icon {
              margin-top: 5px;
                
                .included-check {
                  width: 14px;
                }
                
                &.exclude{
                  margin-top: 0;
                  font-size: 28px;
                  color: #FF5A20;
                  line-height: 0.8;
                  font-family: 'Century Gothic';
                }
            }

            .item-text {
              font-size: 19px;
              font-family: 'Century Gothic';
            }
          }

          .item:last-child {
            margin-bottom: 0px;
          }
        }

        .wallet-btn {
          padding-top: 64px;
          padding-bottom: 64px;
          margin-top: auto;
        }

        .wallet-btn > button {
          font-weight: 700 !important;
          width: 100%;
        }

      }
    }
  }

  @media screen and (max-width: 800px) {
    height: auto;

    .italic-light {
      font-size: 15px;
      font-style: italic;
    }

    .container {
      width: 100%;
      height: auto;
      padding-left: 0;
      padding-right: 0;

      .sub-heading {
        row-gap: 20px;
        margin-top: 1.5rem;
        width: auto;
        height: auto;
        padding: 0 16px;

        .heading {
          font-size: 21px;
        }

        .text {
          font-size: 14px;
          margin-bottom: 1rem;
        }
      }

      .benefits {
        display: none;
      }

      .tabs {
        display: block;
        width: 100%;

        .tabs-wrapper {
          height: 92px;
          background: #4C0D18;
          padding-top: 8px;
          padding-bottom: 8px;
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        .indicaters {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        .indicaters div {
          height: 19px;
          background: transparent;
          border-radius: 5px 5px 0px 0px;
        }

        .selected-tab {
          color: #FD7835 !important;
        }

        .selected-indicater {
          background: #FD7835 !important;
        }

        .tab {
          background: transparent;
          border: none;
          outline: none;
          font-family: 'Nielmaden';
          font-weight: 700;
          font-size: 22.4px;
          text-align: center;
          color: #FFFFFF;
          border-right: 1px solid #FBF1E6;
        }

        .tab:last-child {
          border-right: none;
        }
      }

      .benefits-mobile {
        flex-direction: column;
        width: 100%;
        display: flex;
        column-gap: 28px;
        margin-top: 0px;

        .tier {
          width: auto;
          position: relative;

          .badge {
            position: absolute;
            width: 84px;
            height: 84px;
            left: 2vw;
            top: 20px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          }
        }

        .tier .header-section {
          background: white;
          color: #0A3047;
          row-gap: 1.2rem;
          padding: 0px 0rem 2rem 0rem;
          border-radius: 25px 25px 0px 0px;
          border: none;
          

          h2, h3, p, .sub-title {
            font-family: 'Nielmaden';
            text-align: center;
          }

          h2 {
            font-size: 36px;
            font-weight: 700;
            text-decoration: none;
            margin-top: 0.5rem;
          }

          h3 {
            font-size: 28px;
            font-weight: 700;
            margin-top: 1.7rem;
          }

          .title {
            height: 92px;
            background: #FD7835;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            
            .desc {
              font-family: 'Century Gothic';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
            }
          } 

          .sub-title {
            margin-top: 1rem;
          }
        }

        .tier .body-section{
          border-radius: 0px 0px 25px 25px; 
          border: none;
          padding: 0px 55px 0px 55px;
          color: #0A3047; 

          .body-top-wrapper {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin-bottom: 1.5rem;
          }

          .vr-line {
            height: 100px;
            width: 1px;
            background-color: #0A3047
          }

          .price-wrapper {
            margin-bottom: 0px;

            h4 {
              font-size: 21px;
              font-weight: 700;
              font-family: 'Nielmaden';
              text-align: center;
              width: 100px;
            }
            .price-data-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 14px;
              
              .price {
                font-family: 'Century Gothic';
                font-weight: 700;
                font-size: 21px;
              }
            }
          }

          .expect-items {
            .expect-title {
              margin-bottom: 38px;;
            }

            .expect-title h4{
              font-family: 'Nielmaden';
              font-weight: 700;
              font-size: 24px;
            }

            .expect-title .line{
              width: 100%;
              height: 1px;
              background-color: #4C0D18;
              margin-top: 0.5rem;
            }

            .item {
              display: flex;
              column-gap: 15px;
              align-items: flex-start;
              margin-bottom: 14px;
              
              .item-icon {
                margin-top: 2px;

                  .included-check {
                      width: 14px;
                  }

                  &.exclude{
                      margin-top: 0;
                      font-size: 28px;
                      color: #FF5A20;
                      line-height: 0.8;
                      font-family: 'Century Gothic';
                  }
              }

              .item-text {
                font-size: 14px;
                font-family: 'Century Gothic';
              }
            }

            .item:last-child {
              margin-bottom: 0px;
            }
          }

          .wallet-btn {
            padding-top: 64px;
            padding-bottom: 64px;

          }

          .wallet-btn > button {
            font-weight: 700 !important;
            width: 100%;
          }

        }
      }
    }
  }
`;
export default BPStyled;
