/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { ContentStyled, MiniCarouselStyled, OuterCarousel } from './style';
import { CarouselContentStyled } from '../CarouselContent/style';
import Header from '../Header';
import WalletButton from '../WalletButton';
import NotificationModal from '../NotificationModal';
import BrandPartnersSubSlide from '../BrandPartnersSubSlide';
import {
  createShopifyProductComponentSlider,
} from '../../utils/createShopifyComponent';
import TastingKitsSubSlide from '../TastingKitsSubSlide';
import { ShopifyJSContext } from '../../context/ShopifyJSContext';
import { CsvCMSDataContext } from '../../context/CsvCMSDataContext';
import useIsMobile from '../../hooks/useIsMobile';
import VimeoPlayer from '../VimeoPlayer';

function MiniCarousel({ subCarousel }) {
  const [notificationOne, setNotificationOne] = useState(false);
  const [notificationTwo, setNotificationTwo] = useState(false);

  const [currentProducts, setCurrentProducts] = useState([]);
  const [pastProducts, setPastProducts] = useState([]);

  const { shopifyClient, shopifyUI } = useContext(ShopifyJSContext);
  const { barrelPickingPartners, futureSelectionsDivided, loading } = useContext(CsvCMSDataContext);
  const { isMobile } = useIsMobile();

  const [futureSelectionSlidesAdded, setFutureSelectionSlidesAdded] = useState(false);
  let futureSelectionsListIndex = -1; // Starts in -1 because I increment it before using it
  const hasFutureSelectionsList = subCarousel.some(slide => slide.futureSelectionsList)

  const [barrelPickingPartnersListToPrint, setBarrelPickingPartnersListToPrint] = useState([]);

  // Creates a copy of the subcarousel and adds the future selections slides if it needs more than just one
  // At least one subcarousel slide for Future selections has to be defined in assets/autoCarousel.js
  const subCarouselCopy = useMemo(() => {
    if (!hasFutureSelectionsList || (hasFutureSelectionsList && loading)) {
      return [...subCarousel];
    }

    let copy = [...subCarousel];
    const futureSelectionSlide = copy.find(slide => slide.futureSelectionsList);

    if (!!futureSelectionSlide && futureSelectionsDivided.length > 1 && !futureSelectionSlidesAdded) {
      for (let i = 1; i < futureSelectionsDivided.length; i++) {
        copy = [...copy, { ...futureSelectionSlide, id: futureSelectionSlide.id + i }];
      }

      setFutureSelectionSlidesAdded(true);
    }

    return copy;
  }, [subCarousel, futureSelectionsDivided, loading]);

  const shopifyBuyInit = async () => {
    const ui = shopifyUI;

    const collectionId = 'gid://shopify/Collection/266857873590';
    const collection = await shopifyClient.collection.fetchWithProducts(
      collectionId, { productsFirst: 4 }
    );

    // const products = await client.product.fetchAll(250);

    const currentSelections = collection.products.filter(
      (p) => p.availableForSale
    ).map((p) => {
      const idSplit = p.id.split('/');
      return idSplit[idSplit.length - 1];
    });

    const pastSelections = [
      '6767562326198',
      '7210447044790',
      '7299051258038',
      '7395317547190',
    ];
    setCurrentProducts(currentSelections);
    setPastProducts(pastSelections);

    setTimeout(() => {
      for (const product of currentSelections) {
        createShopifyProductComponentSlider(product, ui, 'current');
      }
      for (const product of pastSelections) {
        createShopifyProductComponentSlider(product, ui, 'past');
      }
    }, 500);
  };

  useEffect(() => {
    if (
      !loading && subCarousel[0].headingOne === 'Present, Past and Future Club Selections' && !!shopifyClient && !!shopifyUI
    ) {
      void shopifyBuyInit();
    }
  }, [loading, shopifyClient, shopifyUI]);

  useEffect(() => {
    if(isMobile && barrelPickingPartners.length > 30) {
      setBarrelPickingPartnersListToPrint(() => {
        return barrelPickingPartners.slice(0,30);
      })
    } else {
      setBarrelPickingPartnersListToPrint(barrelPickingPartners);
    }
  }, [barrelPickingPartners])

  return !hasFutureSelectionsList || (hasFutureSelectionsList && !loading) ? (
    <MiniCarouselStyled>
      <OuterCarousel>
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          transitionTime={1000}
        >
          {subCarouselCopy.map((subSlide) => {

            if (subSlide.futureSelectionsList && (futureSelectionsListIndex < futureSelectionsDivided.length - 1)) {
              futureSelectionsListIndex++;
            }

            return (
              <React.Fragment key={`subslide-${subSlide.id}`}>
                <Header
                  headingOne={subSlide.headingOne}
                  headingTwo={subSlide.headingTwo}
                  headingThree={subSlide.headingThree}
                  rowGap={subSlide.rowGap ? subSlide.rowGap : ''}
                  headerWidth={subSlide.headerWidth ? subSlide.headerWidth : ''}
                />

                {subSlide.paraOne || subSlide.paraTwo || subSlide.subtitle ? (
                  <CarouselContentStyled fontSize={subSlide.fontSize}
                                         contentWidth={subSlide.contentWidth}>
                    {subSlide.paraOne && (
                      <div id='paraOne'
                           dangerouslySetInnerHTML={{ __html: subSlide.paraOneMob ? subSlide.paraOneMob : subSlide.paraOne }} />
                    )}
                    {subSlide.paraTwo && (
                      <div id='paraTwo' dangerouslySetInnerHTML={{ __html: subSlide.paraTwo }} />
                    )}
                    {subSlide.subtitle && <div className='third-text'>{subSlide.subtitle}</div>}
                  </CarouselContentStyled>
                ) : null}

                {subSlide.brandPartnerLogos && (
                  <BrandPartnersSubSlide subSlide={subSlide} />
                )}

                {subSlide.partnerList && (
                  <ContentStyled
                    contentWidth={subSlide.contentWidth}
                    fontSize={subSlide.fontSize}
                  >
                    <div className='list-container'
                         style={{ gridTemplateRows: `repeat(${Math.ceil(barrelPickingPartnersListToPrint.length / (isMobile ? 2 : 3))}, minmax(min-content, max-content))` }}>
                      {barrelPickingPartnersListToPrint.map((partner, index) => (
                        <div
                          key={`barrel-picking-partners-${index}`}>{partner['Partner Name']}</div>))
                      }
                      {isMobile? (
                        <a
                          href={subSlide.shopNowBtn ? '#Current-Selections' : '#Past-Selections'}
                          style={{
                            color: '#fd7835',
                            fontFamily: 'Century Gothic BO',
                            fontWeight: '700',
                            fontSize: '19px',
                            display: 'inline-block',
                            marginTop: '1rem',
                          }}
                        >
                          See More
                        </a>
                      ) : null}
                    </div>
                  </ContentStyled>
                )}

                {!!subSlide.showTastingKits && (
                  <TastingKitsSubSlide subSlide={subSlide} />
                )}

                {subSlide.showShopifyProducts && (
                  <div className='wrapper' style={{ marginTop: '35px' }}>
                    <ContentStyled
                      contentWidth={subSlide.contentWidth}
                      fontSize={subSlide.fontSize}
                      flexDirection='row'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      {subSlide.shopNowBtn &&
                        currentProducts.map((id) => (
                          <div
                            key={id}
                            id={`shopify-component-product-${id}`}
                            className='shopify-card'
                          />
                        ))}
                      {subSlide.getNotifiedBtn &&
                        pastProducts.map((id) => (
                          <div
                            key={id}
                            id={`shopify-component-product-${id}`}
                            className='shopify-card'
                          />
                        ))}

                    </ContentStyled>
                    <a
                      href={subSlide.shopNowBtn ? '#Current-Selections' : '#Past-Selections'}
                      style={{
                        color: '#fd7835',
                        fontFamily: 'Century Gothic BO',
                        fontWeight: '700',
                        fontSize: '19px',
                        display: 'inline-block',
                        marginTop: '1rem',
                      }}
                    >
                      See More
                    </a>
                    {notificationOne && (
                      <div className='notification-modal-one'>
                        <div
                          className='close-text'
                          onClick={() => setNotificationOne(false)}
                        >
                          X
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {subSlide.futureSelectionsList && (
                  <>
                    <ContentStyled
                      contentWidth={subSlide.contentWidth}
                      fontSize={subSlide.fontSize}
                    >
                      <div
                        className='list-container-two'
                        style={{ marginTop: '3rem' }}
                      >
                        {futureSelectionsDivided[futureSelectionsListIndex].map((selection, index) => (
                        <div key={`future-selection-list-${futureSelectionsListIndex}-${index}`}>
                          <span id='underline'>{selection['Brand']}</span> {selection['Expression']}
                        </div>
                      ))}
                      </div>
                      {futureSelectionsListIndex > 0 && <div id='continue'>Continued</div>}
                      {subSlide.getNotifiedBtn && (
                        <button className='get-notified' onClick={() => {
                          _Sendlane.openForm('eyJkb21haW4iOiJkZXdpbmVzcG90IiwiZm9ybV9pZCI6ImRhMmY3YjJhLTk5N2QtNGVjNi05ZDFjLWNkMTgyYjg4YjFhNSJ9');
                        }
                        }>
                          Get Notified of Future Drops
                        </button>
                      )}
                    </ContentStyled>
                    {notificationTwo && (
                      <NotificationModal
                        setNotificationTwo={setNotificationTwo}
                      />
                    )}
                  </>
                )}

                {subSlide.videoEmbed && (
                  <ContentStyled contentWidth={subSlide.contentWidth}>
                    <div className='video-embed'>
                      <VimeoPlayer videoId={subSlide.videoEmbed} />
                    </div>
                  </ContentStyled>

                )}

                {subSlide.showWalletButton && (
                  <div className='btn'>
                    <WalletButton
                      action='CONNECT_AND_WHITELIST'
                      defaultText='Join Now'
                    />
                  </div>
                )}
              </React.Fragment>
            );
          })
          }
        </Carousel>
      </OuterCarousel>
    </MiniCarouselStyled>) : null;
}

export default MiniCarousel;
