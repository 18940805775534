import { useState, createContext, useEffect, useMemo } from 'react';
import ShopifyBuy from '@shopify/buy-button-js';

const DEFAULT_STATE = {
  shopifyClient: null,
  shopifyUI: null,
};

export const ShopifyJSContext = createContext(DEFAULT_STATE);

function ShopifyJSProvider(props) {
  const { children } = props;
  const [shopifyClient, setShopifyClient] = useState(null);
  const [shopifyUI, setShopifyUI] = useState(null);

  useEffect(() => {
    setShopifyClient(() => ShopifyBuy.buildClient({
      domain: 'prime-barrel.myshopify.com',
      storefrontAccessToken: '99deac4efd44c4be0b2a1808940f20ff',
    }));
  }, []);

  useEffect(() => {
    if (shopifyClient !== null) {
      setShopifyUI(() => ShopifyBuy.UI.init(shopifyClient));
    }
  }, [shopifyClient]);

  const value = useMemo(() => ({
    shopifyClient,
    shopifyUI,
  }), [shopifyClient, shopifyUI]);

  return <ShopifyJSContext.Provider value={value}>{children}</ShopifyJSContext.Provider>;
}
export default ShopifyJSProvider;
