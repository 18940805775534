/* eslint-disable react/no-danger */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { CarouselContentStyled } from './style';
import TestimonialsSlide from '../TestimonialsSlide';

function CarouselContent({ slide }) {

  return slide.testimonials !== undefined ? (
    <TestimonialsSlide slide={slide} />
  ) : (
    <CarouselContentStyled fontSize={slide.fontSize} contentWidth={slide.contentWidth}>
      {slide.paraOne && (
        <div id='paraOne'
             dangerouslySetInnerHTML={{ __html: slide.paraOneMob ? slide.paraOneMob : slide.paraOne }} />
      )}
      {slide.paraTwo && (
        <div id='paraTwo' dangerouslySetInnerHTML={{ __html: slide.paraTwo }} />
      )}

      <a href={slide.linkAddress} style={{ fontSize: '14px' }}>
        {slide.link}
      </a>
    </CarouselContentStyled>
  );
}

export default CarouselContent;
