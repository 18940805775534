/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

const OuterCarousel = styled.div`
@media(max-width: 800px){
  width: -webkit-fill-available;
}

  .control-arrow .control-next {
    opacity: 1;
    padding: 0px;
    margin-top: 0px;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carousel.carousel-slider > button {
    background: #fd7835;
    height: 33.85px;
    width: 33.85px;
    top: 230px;
    padding: 0px;
  }

  .carousel.carousel-slider .control-arrow {
      box-shadow: none;
  }

  .carousel.carousel-slider .control-arrow.control-prev {
    left: 3%;
  }

  .carousel.carousel-slider .control-arrow.control-next {
    right: 3%;
  }

  .carousel.carousel-slider .control-arrow {
    background: transparent;
    opacity: 1;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 16px solid #ff5a20;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 16px solid #ff5a20;
  }
`;

const MiniCarouselStyled = styled.div`
  width: 1016px;
  display: flex;
  flex-direction: column;
  background: #fbf1e6 !important;
  padding: 0px 30px 0 30px;
  border-radius: 25px;
    
  @media(max-width: 800px){
    width: -webkit-fill-available !important;
    padding: 0;
    height: auto;
    align-items: center;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 19px;
    margin-left: -33px;
  }

  .notification-modal-one {
    width: 424px;
    height: 414.55px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid black;
    background: #4c0d18;
    position: relative;
    top: -392px;
    padding: 9px;

    .close-text {
      color: white;
      cursor: pointer;
      transform: rotateX(44deg);
      float: right;
    }
  }

  .notification-modal-two {
    width: 424px;
    height: 414.55px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid black;
    background: #4c0d18;
    position: relative;
    top: -450px;
    padding: 9px;

    .close-text {
      color: white;
      cursor: pointer;
      transform: rotateX(44deg);
      float: right;
    }
  }
`;

const ContentStyled = styled.div`
  font-size: ${(props) => props.fontSize};
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'column'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : ''};
  align-items: center;
  column-gap: 14px;

  .shopify-card {
    iframe {
      margin: 0;
    }
  }

  .brand-partner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    @media(max-width: 800px){
      display: none;
    }

    .partner-one {
      display: flex;
      flex-direction: column;
      width: 474.75px;
      height: 97.58px;
    }

    .partner-two {
      width: 577.34px;
      height: 113.65px;
      margin-top: 24px;
      margin-bottom: -14px;
    }
  }

  .partners-mobile{
    margin-top: 15px;
    @media(min-width: 800px){
      display: none;
    }
    .Array-one-div{
      display: flex;
      align-items: end;
      column-gap: 26px;
    }
    .Array-two-div{
      display: flex;
      align-items: end;
      column-gap: 41px;
    }
    .Array-three-div{
      display: flex;
      align-items: end;
      column-gap: 19px;
    }
  }

  a {
    color: #fd7835;
    font-family: 'Century Gothic BO';
    font-size: 19px;
  }

  .stickers {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .list-container {
    display: grid;
    text-align: left;
    column-gap: 125px;
    row-gap: 0;
    grid-auto-flow: column;
    //grid-template-rows: repeat(11, 1fr); // This is calculated as an inline style depending on the list length
    margin-top: 15px;
    font-family: 'Century Gothic BO';
    font-style: normal;
    font-size: 14px;
    line-height: 170%;
    color: #0a3047;

      @media screen and (max-width: 800px){
          column-gap: 50px;
          font-size: 10px;
          text-wrap: nowrap;
          row-gap: 2px;
          margin-top: 0;
      }
  }

  .list-container-two {
    display: flex;
    column-gap: 42px;
    row-gap: 3px;
    margin-top: 22px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
    color: #0a3047;

      @media(max-width: 800px){
          font-size: 10px;
          margin-top: auto !important;
          flex-wrap: wrap;
          text-align: left;
          height: 235px;
          line-height: 170%;
          justify-content: flex-start;

          div {
              width: 80%;
          }
      }
  }

  #continue {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #fd7835;
    margin-top: 2rem;
  }

  .name-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #0a3047;
  }

  .name-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #0a3047;
  }

  .bottle-container {
    width: 164.07px;
    height: 271px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 0.541938px solid #4c0d18;
    border-radius: 13.5485px;
    margin-top: 35px;

    img {
      width: 79.89px;
      height: 119.83px;
      mix-blend-mode: multiply;
    }

    .label {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 110.61px;
      height: 48.55px;
      font-family: 'Century Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 9.75489px;
      line-height: 150%;
      color: #0a3047;

      .selection-label {
        font-family: 'Century Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 9.75489px;
        line-height: 150%;
        color: #0a3047;
        border-bottom: 0.541938px solid #4c0d18;
        padding-bottom: 8px;
      }

      .price {
        font-family: 'Century Gothic BO';
        font-style: normal;
        font-size: 9.75489px;
        line-height: 180%;
        text-align: center;
        color: #404756;
        padding-top: 5px;
      }
    }

    .shop-now {
      width: 88.84px;
      height: 28.17px;
      background: #fd7835;
      border-radius: 6.50326px;
      border: none;
      color: #ffffff;
      font-family: 'Century Gothic BO';
      font-style: normal;
      font-size: 9.75489px;
      line-height: 140%;
      text-align: center;
      cursor: pointer;
    }
  }

  .get-notified {
    width: 106.65px;
    background: #fd7835;
    border-radius: 7.21177px;
    border: none;
    color: #ffffff;
    font-family: 'Century Gothic BO';
    font-style: normal;
    font-size: 9.75489px;
    line-height: 140%;
    text-align: center;
    cursor: pointer;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1rem;

    text-decoration: none;
  }

  .partner-all {
    display: none;
  }

  .shopify-card {
      &.shopify-buy-frame {
          width: 180px;
          height: 290px;
          border: 1px solid #4c0d18;
          border-radius: 13.5485px;
          padding: 5px 0px;

          iframe {
              height: 280px !important;
              width: 85% !important;
          }
      }
  }
    
  .video-embed {
    margin-top: 25px;
    width: 533px;
    height: 300px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
      
      iframe {
          margin: 0;
      }
  }

  @media screen and (max-width: 800px) {
    .partner-one,
    .partner-two {
      display: none !important;
    }

    .partner-all {
      display: grid;
      grid-template-columns: 55px 55px 55px;
      grid-template-rows: 65px 65px 65px;
      row-gap: 1rem;
      column-gap: 1rem;
      align-items: flex-end;

      .stickers {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-around;

        img {
          width: 100%;
        }

        div {
          font-size: 7px;
          line-height: 10px;
        }
      }
    }
  }
`;

export { MiniCarouselStyled, OuterCarousel, ContentStyled };
