import React from 'react';
import AutoCarouselFGStyled from './style';
import Header from '../Header';
import WalletButton from '../WalletButton';
import CarouselContent from '../CarouselContent';
import MobileMiniCarousel from '../MobileMiniCarousel';

function AutoCarouselFGMob({ slide }) {
  return (
    <AutoCarouselFGStyled
      style={{
        paddingLeft: slide.id >= 7 && slide.id <= 8 ? '0px' : 'auto',
        paddingRight: slide.id >= 7 && slide.id <= 8 ? '0px' : 'auto',
      }}
    >
      {slide.headingOne && slide.headingTwo && (
        <Header
          id={slide.id}
          headingOne={slide.headingOne}
          headingTwo={slide.headingTwo}
          headingThree={slide.headingThree}
          rowGap={slide.rowGap}
          headerWidth={slide.headerWidth}
        />
      )}
      {!slide.subCarousel ? (
        <CarouselContent slide={slide} />
      ) : (
        <MobileMiniCarousel subCarousel={slide.subCarousel} />
      )}
      {slide.showWalletButton && (
        <div className='btn'>
          <WalletButton action='CONNECT_AND_WHITELIST' defaultText='Join Now' />
        </div>
      )}

      {slide.info && (
      <div className='info'>
        { slide.info }
      </div>
      )}
    </AutoCarouselFGStyled>
  );
}

export default AutoCarouselFGMob;
