import { VimeoPlayerStyled } from './style';
import React, { useEffect, useRef } from 'react';
import Player from '@vimeo/player';
import { ReactComponent as PlayIcon } from '../../assets/reelVideo/play.svg';

function VimeoPlayer({ videoId, config = {}, overlay }) {
  const playerContainer = useRef(null);
  const [player, setPlayer] = React.useState(null);
  const [playing, setPlaying] = React.useState(false);

  useEffect(() => {
    if (playerContainer.current !== null) {
      setPlayer(() => new Player(playerContainer.current, {
        id: videoId,
        vimeo_logo: false,
        portrait: false,
        byline: false,
        pip: false,
        speed: false,
        title: false,
        controls: false,
        ...config,
      }));
    }

    return () => {
      if(player) {
        player.destroy();
      }
    }
  }, [playerContainer.current]);

  useEffect(() => {
    if(player) {
      player.on('play', () => setPlaying(true));
      player.on('pause', () => setPlaying(false));
    }
  }, [player]);

  const togglePlay = () => {
    player.getPaused().then((paused) => {
      if (paused) {
        player.play();
      } else {
        player.pause();
      }
    });
  }

  return (
    <VimeoPlayerStyled>
      <div ref={playerContainer}></div>
      <div className={`overlay ${playing? 'playing' : ''}`} onClick={togglePlay}>
        <PlayIcon />
        <span>{overlay}</span>
      </div>
    </VimeoPlayerStyled>
  );
}

export default VimeoPlayer;
