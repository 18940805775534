import React from 'react';
import MintSubContentStyled from './style';

function MintSubContent() {
  return (
    <MintSubContentStyled>
      Follow the below prompts to securely buy your unique membership. If you
      need assistance, check out our “How-To Buy” explainer video{' '}
      <span>
        <a href='https://youtu.be/Mq_VwvOK_G4' className='ff-CG CTA'>
          here
        </a>
      </span>
      .
    </MintSubContentStyled>
  );
}

export default React.memo(MintSubContent);
