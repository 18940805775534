import React, { useContext } from 'react';
import FutureSelectionStyled from './style';
import { CsvCMSDataContext } from '../../context/CsvCMSDataContext';

function FutureSelection() {
  const {futureSelections } = useContext(CsvCMSDataContext);

  return (
    <FutureSelectionStyled>
      <div className='container'>
        <div className='header'>Look at Future Selections</div>
        <div className='sub-heading'>
          <div className='heading ff-NL fw-700'>
            Coming Soon Exclusively For Our Members!
          </div>
          <div className='desc desc-lg ff-CG fw-400'>
            Our single barrel selections do not stick around for long.
            <br />
            Browse our most current selections below, peruse our previous sold
            out picks, and see what we have in our queue!
          </div>
          <div className='desc desc-sm ff-CG fw-400'>
            Our single barrel selections do not stick around for long.
            <br /> See what we have in our queue and sign up from drop notifications!
          </div>
        </div>
        <div className='bottles list-container'>
          <div className='list-container-two'>
            {futureSelections.map((selection, index) => (
              <div key={`future-selection-section-${index}`}>
                <span id='underline'>{selection['Brand']}</span> {selection['Expression']}
              </div>
            ))}
          </div>
          {/* <button
                type='button'
                className='get-notified'
                onClick={() => setNotificationTwo(true)}
              >
                Get Notified of Future Drops
              </button> */}
          <button className='get-notified' onClick={() => {
            _Sendlane.openForm('eyJkb21haW4iOiJkZXdpbmVzcG90IiwiZm9ybV9pZCI6ImRhMmY3YjJhLTk5N2QtNGVjNi05ZDFjLWNkMTgyYjg4YjFhNSJ9')}
          }>
            Get Notified of Future Drops
          </button>
        </div>
      </div>
    </FutureSelectionStyled>
  );
}

export default FutureSelection;
