/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useAccount } from 'wagmi';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { saveShippingAddress } from '../../../service/api';
import Button from '../../Button';

function ShippingModal({ setModal, setThanksModal }) {
  const [countryField, setCountryField] = useState('');
  const [error, setError] = useState(false);
  const [emailField, setEmailField] = useState('');
  const [addressOne, setAddressOne] = useState('');
  const [suite, setSuite] = useState('');
  const [cityField, setCityField] = useState('');
  const [stateField, setStateField] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [addr, setAddr] = useState('');
  const { address } = useAccount();

  const handleChange = (value) => {
    setAddr(value);
  };

  const handleSelect = (value) => {
    geocodeByAddress(value)
      .then((results) => {
        setCountryField('');
        setAddressOne('');
        setSuite('');
        setCityField('');
        setStateField('');
        setZipCode('');

        const addressComponent = results[0].address_components;
        addressComponent.forEach((component) => {
          // @ts-ignore remove once typings fixed
          const componentType = component.types[0];

          switch (componentType) {
            case 'street_number': {
              setSuite(component.long_name);
              break;
            }

            case 'route': {
              setAddressOne(component.long_name);
              break;
            }

            case 'postal_code': {
              setZipCode(component.long_name);
              break;
            }

            case 'postal_code_suffix': {
              setZipCode(component.long_name);
              break;
            }

            case 'locality':
              setCityField(component.long_name);
              break;

            case 'administrative_area_level_1': {
              setStateField(component.long_name);
              break;
            }

            case 'country':
              setCountryField(component.long_name);
              break;

            default:
              break;
          }
        });
      })
      .catch((err) => console.error('Error', err));
  };

  const payload = {
    wallet_address: address,
    email: emailField,
    country: countryField,
    address_one: addressOne,
    address_two: suite,
    city: cityField,
    state: stateField,
    zip: zipCode,
    phone: phoneNum,
  };

  const handleSubmit = () => {
    const userInput = document.getElementById('required');
    const validation = userInput.value;
    if (!validation) {
      setError(true);
    } else {
      setError(false);
      saveShippingAddress(payload).then((res) => {
        if (res.status === 201) {
          setModal(false);
          setThanksModal(true);
        }
      }).catch(() => setError(true));
    }
  };

  return (
    <Modal onClick={() => setModal(false)}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <HeadDiv>
          <TextDiv>
            <Head>You’re eligible for free bottles!</Head>
            <SubHead>Please add your address below</SubHead>
          </TextDiv>
        </HeadDiv>
        <ContentDiv>
          <Title>Shipping Address</Title>
          {error && (
            <ErrorMessage>*All fields required</ErrorMessage>
          )}
          <InputDiv>
            <Label>Location</Label>
            <PlacesAutocomplete
              value={addr}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getInputProps({
                      placeholder: 'Search Places ...',
                      className: 'location-search-input',
                    })}
                  />
                  {suggestions.length > 0 && (
                    <div className='auto-complete-popup'>
                      {suggestions.map((suggestion) => (
                        <p
                          key={suggestion.id}
                          {...getSuggestionItemProps(suggestion)}
                        >{suggestion.description}
                        </p>
                      ))}
                    </div>
                  )}

                </div>
              )}
            </PlacesAutocomplete>

            <div className='add-div'>
              <Label>*Email</Label>
              <Input id='required' type='text' onChange={(e) => setEmailField(e.target.value)} required />
            </div>

            <div className='add-div'>
              <Label>*Country/Region</Label>
              <Input id='required' type='text' value={countryField} onChange={(e) => setCountryField(e.target.value)} required />
            </div>
            <div className='add-div'>
              <Label>*Name</Label>
              <Input type='text' id='required' />
            </div>
            <AddressDiv>
              <div className='add-div'>
                <Label>*Address 1</Label>
                <AddressInput id='required' type='text' value={addressOne} onChange={(e) => setAddressOne(e.target.value)} required />
              </div>
              <div className='add-div'>
                <Label>Apt, Suite, etc.</Label>
                <AddressInput id='required' type='text' value={suite} onChange={(e) => setSuite(e.target.value)} required />
              </div>
              <div className='add-div'>
                <Label>*City</Label>
                <AddressInput id='required' type='text' value={cityField} onChange={(e) => setCityField(e.target.value)} required />
              </div>
              <div className='add-div'>
                <Label>*State</Label>
                <AddressInput id='required' type='text' value={stateField} onChange={(e) => setStateField(e.target.value)} required />
              </div>
              <div className='add-div'>
                <Label>*Zip</Label>
                <AddressInput id='required' type='number' value={zipCode} onChange={(e) => setZipCode(e.target.value)} required />
              </div>
              <div className='add-div'>
                <Label>*Phone</Label>
                <AddressInput id='required' type='number' onChange={(e) => setPhoneNum(e.target.value)} required />
              </div>
            </AddressDiv>

          </InputDiv>
          <Button onClick={() => {
            handleSubmit();
          }}
          >Submit
          </Button>
        </ContentDiv>
      </ModalContent>
    </Modal>
  );
}

export default ShippingModal;

const Modal = styled.div`
position: fixed;
left: 0;
right: 0;
top: 0;
bottom: 0;
background-color: rgba(0,0,0,0.5);
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
overflow: auto;
z-index: 999;

&::-webkit-scrollbar {
  display: none;
}
`;

const ModalContent = styled.div`
width: 614px;
background-color: #FBF1E6;
border-radius: 32px;
margin: auto;
margin-top: 20px;
@media(max-width: 800px){
  width: 95vw;
}
`;

const HeadDiv = styled.div`
height: 194px;
background-color: #0A3047;
border-radius: 32px 32px 0 0;
display: flex;
justify-content: center;

@media(max-width: 800px){
  height: 138px;
}
`;

const Head = styled.h1`
font-family: Nielmaden;
font-size: 40px;
font-weight: bold;
line-height: 120%;
color: #FBF1E6;

@media(max-width: 800px){
  font-size: 28.45px;
}
`;

const SubHead = styled.p`
font-family:Century Gothic;
font-size: 26px;
color: #FBF1E6;

@media(max-width: 800px){
  font-size: 17.07px;
}
`;

const TextDiv = styled.div`
width: 429px;
text-align: center;
`;

const Title = styled.div`
color: #0A3047;
font-family: Nielmaden;
font-size: 40px;
font-weight: bold;
line-height: 120%;
margin-top: 29px;
@media(max-width: 800px){
  font-size: 28.88px;
}
`;

const ContentDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 29px;

.location-search-input {
  width: 401px;
  height: 42px;
  padding-left: 15px; 
  padding-right: 15px; 
  border-radius: 8px;
  border: none;
  background-color: #4C0D18;
  margin-top: -9px;
  box-sizing: border-box;
  @media(max-width: 800px){
    width: 285px;
    height: 30px;
  }
  font-family: Century Gothic;
  font-size: 17.21px;
  color:#FFFFFF;
}

.auto-complete-popup {
  background-color: white;
  padding: 1rem;
  width: 401px;
  box-shadow: 1px 3px 5px 5px #00000042; 
  position: absolute;

  @media(max-width: 800px){
    width: 285px;
  }

  p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: unset;
    border-bottom: 1px solid #00000042;
    cursor: pointer;
  }

  p:hover {
    background-color: #f0f0f0;
  }
}
`;

const InputDiv = styled.div`
@media(max-width: 800px){
display: flex;
flex-direction: column;
align-items: center;
}
`;

const Label = styled.p`
font-family: Century Gothic;
font-weight: Bold;
font-size: 15.21px;
color: #4C0D18;
@media(max-width: 800px){
  font-size: 12.25px;
}
`;

const Input = styled.input`
width: 401px;
height: 42px;
padding-left: 15px; 
padding-right: 15px; 
border-radius: 8px;
border: none;
background-color: #4C0D18;
margin-top: -9px;
box-sizing: border-box;
@media(max-width: 800px){
  width: 285px;
  height: 30px;
}
font-family: Century Gothic;
font-size: 17.21px;
color:#FFFFFF;
`;

const AddressDiv = styled.div`
display: flex;
flex-wrap: wrap;
width: 418px;
margin-bottom: 30px;

@media(max-width: 800px){
  display: flex;
  justify-content: center;
  margin-left: 5px;
}
.add-div{
  margin-right: 10px;
}

`;

const ErrorMessage = styled.p`
font-family: Century Gothic;
font-weight: Bold;
font-size: 15.21px;
color: red;
@media(max-width: 800px){
  font-size: 12.25px;
}
`;

const AddressInput = styled.input`
width: 194.58px;
height: 42px;
padding-left: 12px; 
padding-right: 12px; 
border-radius: 8px;
border: none;
background-color: #4C0D18;
margin-top: -9px;
box-sizing: border-box;
@media(max-width: 800px){
  width: 138px;
  height: 30px;
}
font-family: Century Gothic;
font-size: 17.21px;
color:#FFFFFF;
`;
