/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

const VimeoPlayerStyled = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    font-family: 'Century Gothic BO', sans-serif;
    font-weight: 700;
    font-size: 12px;

    iframe {
        margin: 0 !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100%;
    }
    
    .overlay {
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 16px;
        position: absolute;
        align-items: flex-end;
        background: rgba(0, 0, 0, 0.4);
        color: white;
        cursor: pointer;
        
        &.playing {
            background: transparent;
            span, svg {
                display: none;
            }
        }
        
        span {
            width: 100%;
        }
        
        svg {
            width: 48px;
            height: 48px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            
            path{
                fill: white;
            }
            circle{
                stroke: white;
            }
        }
    }
`;

export { VimeoPlayerStyled };
