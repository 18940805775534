/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './style.css';
import { observer } from 'mobx-react-lite';
import stores from '../../store';

const EthMint = observer((props) => {
  const { contractStore } = stores;
  const handleEthMint = () => {
    if (props.action === 'CONFIRM') {
      props.setShowTransactionModal(true);
      contractStore.doMint(props.count, props.tier.tierName);
    }
    props.onEthClick();
  };

  return (
    <button
      type='button'
      className={`${props.action ? 'confirm' : 'buy-eth'}`}
      onClick={handleEthMint}
    >
      {props.action === 'CONFIRM' ? 'Confirm' : 'BUY WITH ETH'}
    </button>
  );
});

export default React.memo(EthMint);
