import React from 'react';
import MPStyled from './style';
import Header from '../Header';
import MintSubContent from '../MintSubContent';
import MintContainer from '../MintContainer';

function MintPage() {
  return (
    <MPStyled id='Buy-Now'>
      <Header
        headingOne='Join Now - Membership Open!'
        headingTwo='Purchase Your Exclusive Access Membership'
        rowGap='25px'
        headerMaxWidth='898px'
      />
      <MintSubContent />
      <MintContainer />
    </MPStyled>
  );
}

export default React.memo(MintPage);
