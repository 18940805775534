import { useState, createContext, useEffect, useMemo } from 'react';
import Papa from 'papaparse';

const DEFAULT_STATE = {
  barrelPickingPartners: [],
  futureSelections: [],
  futureSelectionsDivided: [],
  tierOnePerks: [],
  tierTwoPerks: [],
  loading: true,
};

export const CsvCMSDataContext = createContext(DEFAULT_STATE);

function CsvCMSDataProvider(props) {
  const { children } = props;
  const googleSheetURL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTuNt8sAQcBCba7vBMhRL29su4AbB1icXqGokCziY8KQ8iaLHCdsCvxtxY5HIFNsd44EDx_p7qoAiX4/pub';
  const barrelPickingPartnersSheet = '?gid=118602110&single=true&output=csv'
  const futureSelectionsSheet = '?gid=0&single=true&output=csv'
  const tierOnePerksSheet = '?gid=2091233571&single=true&output=csv'
  const tierTwoPerksSheet = '?gid=1508596261&single=true&output=csv'

  const [barrelPickingPartners, setBarrelPickingPartners] = useState([]);
  const [futureSelectionsDivided, setFutureSelectionsDivided] = useState([]); // Sublists of 9 items each for the slider
  const [futureSelections, setFutureSelections] = useState([]); // Full list
  const [tierOnePerks, setTierOnePerks] = useState([]);
  const [tierTwoPerks, setTierTwoPerks] = useState([]);
  const [counter, setCounter] = useState(0);

  function removeNotConfirmedSelections(selections) {
    return selections.filter(item => item['Inventory Status'] === 'Confirmed');
  }

  // This divides the future selections into sublists of 9 items each to be used in the slider
  function futureSelectionsSublists(selections) {
    let result = [];
    let selectionsToDivide = selections.length > 24 ? selections.slice(0, 24) : selections;

    for (let i = 0; i < selectionsToDivide.length; i += 8) {
      let subArray = selectionsToDivide.slice(i, i + 8);
      result.push(subArray);
    }
    return result;
  }

  function removeEmptyPartnerRows(partners) {
    return partners.filter(partner => partner['Partner Name'] !== '');
  }

  useEffect(() => {
    let isMounted = true; // add this line

    Papa.parse(`${googleSheetURL}${barrelPickingPartnersSheet}`, {
      delimiter: ',',
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        if (isMounted) {
          setBarrelPickingPartners(removeEmptyPartnerRows(results.data))
          setCounter(prevCounter => prevCounter + 1);
        }
      },
    });

    Papa.parse(`${googleSheetURL}${futureSelectionsSheet}`, {
      delimiter: ',',
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        if (isMounted) {
          const filteredSelections = removeNotConfirmedSelections(results.data)
          setFutureSelectionsDivided(futureSelectionsSublists(filteredSelections))
          setFutureSelections(filteredSelections)
          setCounter(prevCounter => prevCounter + 1);
        }
      },
    });

    Papa.parse(`${googleSheetURL}${tierOnePerksSheet}`, {
      delimiter: ',',
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        if (isMounted) {
          setTierOnePerks(results.data)
          setCounter(prevCounter => prevCounter + 1);
        }
      },
    });

    Papa.parse(`${googleSheetURL}${tierTwoPerksSheet}`, {
      delimiter: ',',
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        if (isMounted) {
          setTierTwoPerks(results.data)
          setCounter(prevCounter => prevCounter + 1);
        }
      },
    });

    return () => { isMounted = false }; // add this line
  }, []);

  const value = useMemo(() => ({
    barrelPickingPartners,
    futureSelections,
    futureSelectionsDivided,
    tierOnePerks,
    tierTwoPerks,
    loading: counter < 4,
  }), [barrelPickingPartners, futureSelections, futureSelectionsDivided, tierOnePerks, tierOnePerks, counter]);

  return <CsvCMSDataContext.Provider value={value}>{children}</CsvCMSDataContext.Provider>;
}
export default CsvCMSDataProvider;
