/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useAccount } from 'wagmi';
import store from '../../store';
// eslint-disable-next-line no-unused-vars
import {
  getAllProviders,
  // eslint-disable-next-line no-unused-vars
  getProvider as getNewProvider,
} from '../../utils/web3-providers';
import { saveWalletAddress } from '../../service/api';
import Button from '../Button';
import Modal from '../Modal';
import WalletSelector from '../WalletSelector';
import AddOnSelector from '../AddOnSelector';
import ReviewSelection from '../ReviewSelection';
import ProfileForm from '../ProfileForm';
import Alert from '../Alert';
import tier from '../../models/tiers';

const WalletButton = observer(({ action, defaultText, currentTier }) => {
  const { walletStore } = store;
  const { currentProvider } = walletStore;

  // const ethereum = getProvider();
  const providers = getAllProviders();

  const isMintEnabled = process.env.REACT_APP_ENABLE_MINT === 'true';

  const [isLoading, setIsLoading] = useState(false);
  const [isWalletSelectorShown, setWalletSelectorShown] = useState(false);

  const [selectedTier, setSelectedTier] = useState(null);

  const [isAddOnSelectorShown, setAddOnSelectorShown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedAddOns, setSelectedAddOns] = useState([]);

  const [isReviewSelectionShown, setReviewSelectionShown] = useState(false);
  const [isProfileFormShown, setProfileFormShown] = useState(false);

  const [isAlertShown, setAlertShown] = useState(false);

  const [total, setTotal] = useState(0);

  const { address, isConnected } = useAccount();

  const connectToProvider = (provider) => {
    setWalletSelectorShown(false);
    setIsLoading(true);

    walletStore
      .connectToWallet(provider) // TODO: new wallet, need to check if already whitelisted
      .then(() => {
        setIsLoading(false);
        if (action === 'CONNECT_AND_WHITELIST') {
          if (walletStore.isWaitListed) {
            setAlertShown(true);
          }
        }
      })
      .catch((_error) => {
        setIsLoading(false);
      });
  };

  const onConnectWallet = () => {
    if (!isConnected) {
      setWalletSelectorShown(true);
    } else if (action === 'CONNECT_AND_WHITELIST') {
      if (walletStore.isWaitListed) {
        setAlertShown(true);
      } else {
        setAddOnSelectorShown(true);
        const defaultTier = currentTier || 'tier-one-plus';
        setSelectedTier(tier.find((t) => t.name === defaultTier));
      }
    }
  };

  const handleOnConnect = (connectionInfo) => {
    setIsLoading(true);
    walletStore
      .onConnect(connectionInfo)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const formattedPublicAddress = (addr) => {
    if (!addr) return 'Connect to Wallet';
    if (defaultText) return defaultText;
    return (
      <span>
        {addr.substring(0, 5)}
        ....
        {addr.substring(addr.length - 4)}
      </span>
    );
  };

  useEffect(() => {
    walletStore.userAccount = address;
    setAddOnSelectorShown(false);
    setProfileFormShown(false);
    setReviewSelectionShown(false);
    if (address) walletStore.isUserJoined(address);
  }, [address]);

  useEffect(() => {
    if (!currentProvider) return;
    currentProvider?.on('connect', handleOnConnect);
    // eslint-disable-next-line consistent-return
    return () => {
      setIsLoading(false);
      currentProvider?.removeListener('chainChanged', handleOnConnect);
    };
  });

  useEffect(() => {
    currentProvider?.on('accountsChanged', walletStore.onAccountsChanged);
    currentProvider?.on('chainChanged', walletStore.onChainChanged);
    return () => {
      currentProvider?.removeListener(
        'accountsChanged',
        walletStore.onAccountsChanged
      );
      currentProvider?.removeListener(
        'chainChanged',
        walletStore.onChainChanged
      );
    };
  });

  const updateTotal = () => {
    setTotal(
      selectedTier.presalePrice +
        selectedAddOns.reduce((sum, item) => sum + item.price * item.count, 0)
    );
  };

  useEffect(() => {
    if (selectedTier) {
      updateTotal();
    }
  }, [selectedAddOns, selectedTier]);

  const clearSelections = () => {
    setSelectedTier(null);
    setSelectedAddOns([]);
    setTotal(0);
  };

  const handleAddOnSelection = (selected) => {
    if (selected) setSelectedAddOns([{ ...selected, count: 1 }]);
    setAddOnSelectorShown(false);
    setReviewSelectionShown(true);
  };

  const handleAddOnCancel = () => {
    setAddOnSelectorShown(false);
    setReviewSelectionShown(true);
  };

  const handleConfirm = () => {
    setReviewSelectionShown(false);
    setProfileFormShown(true);
  };

  const getCount = (n) => {
    const obj = selectedAddOns.find((i) => i.item === n);
    return obj ? obj.count : 0;
  };

  const handleSave = (formData) => {
    saveWalletAddress(
      formData.name,
      walletStore.userAccount,
      formData.email,
      formData.discord,
      selectedTier.item,
      getCount(1),
      getCount(2),
      getCount(3)
    ).then((res) => {
      if (res && res.status === 201) {
        setProfileFormShown(false);
        walletStore.setIsWaitListed(true);
      }
    });
  };

  useEffect(() => {
    setAddOnSelectorShown(false);
  }, [walletStore.isWaitListed]);

  return (
    <>
      {isConnected && isMintEnabled && action === 'CONNECT_AND_WHITELIST' ? (
        <Button
          onClick={() =>
            window.open('https://vip.primebarrel.com/Discord', '_blank')
          }
        >
          Join Discord Community
        </Button>
      ) : isMintEnabled && action !== 'CONNECT_AND_WHITELIST' ? (
        <Button onClick={onConnectWallet} disabled={isLoading}>
          {isLoading ? 'Connecting.....' : formattedPublicAddress(address)}
        </Button>
      ) : walletStore.isWaitListed && action === 'CONNECT_AND_WHITELIST' ? (
        <Button
          onClick={() =>
            window.open('https://vip.primebarrel.com/Discord', '_blank')
          }
        >
          Join Discord Community
        </Button>
      ) : (
        <Button onClick={onConnectWallet} disabled={isLoading}>
          {isLoading ? 'Connecting.....' : formattedPublicAddress(address)}
        </Button>
      )}

      <Modal
        show={isWalletSelectorShown}
        onClose={() => setWalletSelectorShown(false)}
      >
        <WalletSelector
          providers={providers}
          connect={connectToProvider}
          onSuccess={() => {
            setWalletSelectorShown(false);
          }}
        />
      </Modal>

      {!walletStore.isWaitListed && (
        <Modal
          show={isAddOnSelectorShown}
          onClose={() => {
            setAddOnSelectorShown(false);
            clearSelections();
          }}
        >
          <AddOnSelector
            onSelection={handleAddOnSelection}
            onCancel={handleAddOnCancel}
          />
        </Modal>
      )}

      <Modal
        show={isReviewSelectionShown}
        onClose={() => {
          setReviewSelectionShown(false);
          clearSelections();
        }}
      >
        <ReviewSelection
          selectedTier={selectedTier}
          selectedAddOns={selectedAddOns}
          setSelectedTier={setSelectedTier}
          setSelectedAddOns={setSelectedAddOns}
          total={total}
          updateTotal={updateTotal}
          onConfirm={handleConfirm}
        />
      </Modal>

      <Modal
        show={isProfileFormShown}
        onClose={() => {
          setProfileFormShown(false);
          clearSelections();
        }}
      >
        <ProfileForm
          onSave={handleSave}
          selectedAddOns={selectedAddOns}
          selectedTier={selectedTier}
        />
      </Modal>

      <Modal
        show={isAlertShown}
        onClose={() => {
          setAlertShown(false);
        }}
      >
        <Alert onClose={() => setAlertShown(false)} />
      </Modal>
    </>
  );
});

export default WalletButton;
