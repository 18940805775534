import { useState, createContext, useEffect, useMemo } from 'react';

const DEFAULT_STATE = {
  referralCode: null,
};

export const ReferralCodeContext = createContext(DEFAULT_STATE);

function ReferralCodeProvider(props) {
  const { children } = props;
  const [referralCode, setReferralCode] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const referralParam = queryParams.get('referral')

    if(referralParam) {
      setReferralCode(referralParam)
    }
  }, []);

  const value = useMemo(() => ({
    referralCode,
  }), [referralCode]);

  return <ReferralCodeContext.Provider value={value}>{children}</ReferralCodeContext.Provider>;
}
export default ReferralCodeProvider;
