import styled from 'styled-components';

const ExclusiveBottlesStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 20px 60px;

  .container {
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sub-heading {
      max-width: 985px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .heading {
        font-style: normal;
        font-size: 24px;
        line-height: 90%;
        text-align: center;
        color: #0a3047;
      }

      .text {
        font-style: normal;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #0a3047;
      }
    }

    .maroon-bg {
      width: 515px;
      height: 278px;
      background: #4c0d18;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 25px;
      position: absolute;
      left: 48px;
      top: 39px;

      .yellow-bg {
        width: 490px;
        height: 255px;
        background: #ffb32e;
        border: 1px solid #4c0d18;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
        position: relative;
        top: 10px;
        left: 12px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .logo-image {
          width: 40%;
          height: auto;
        }

        .plus-sign {
          font-family: 'Century Gothic';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          text-align: center;
          color: #000000;
        }

        .sticker {
          width: 35%;
          height: auto;

          .qs-mark {
            position: relative;
            top: -162px;
            width: 40%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    height: auto;

    .container {
      width: 100%;
      height: auto;
      margin-top: 3rem;

      .sub-heading {
        width: 80vw;
        height: auto;

        .heading {
          display: none;
        }

        .text {
          margin-top: 1.5rem;
        }
      }

      .maroon-bg {
        width: 60vw;
        max-width: 245px;
        max-height: 160px;
        height: 35vw;
        padding: 5px;
        top: 50%;
        right: 50%;
        left: unset;
        transform: translate(50%,-50%);

        .yellow-bg {
          width: 100%;
          position: static;
          height: 100%;
        }
      }
    }
  }
`;

const Outer = styled.div`
  width: 1269px;
  margin-top: 56px;

  .carousel.carousel-slider {
    overflow: unset;
  }

  .carousel .slider-wrapper {
    width: 1140px;
  }

  .carousel.carousel-slider .control-arrow {
    opacity: 1;
    width: 35px;
    height: 35px;
    padding: 0;
    background: rgb(251, 241, 230);
    border-radius: 50%;
    top: 40%;
    transform: scale(1.5);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

      @media screen and (max-width: 800px) {
        top: 55%;
      }
  }
    
  

  .carousel .control-prev.control-arrow {
      left: -10px;
  }

  .carousel .control-next.control-arrow {
      right: -10px;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 16px solid #ff5a20;
    margin-right: 8px;
    cursor: pointer;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 16px solid #ff5a20;
    margin-left: 8px;
    cursor: pointer;
  }

  .dot {
    background: #ff5a20 !important;
    opacity: 1 !important;
    width: 10px !important;
    height: 10px !important;
    box-shadow: none !important;
  }

  .control-dots .selected {
    background: #fbf1e6 !important;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }

  .carousel .slide img {
      height: 100%;
      object-fit: cover;
      object-position: top center;
  }

  .content {
    width: 1139px;
    height: 389px;
    border-radius: 25px;
    overflow: hidden;
    border: 1px solid #4c0d18;
  }

  @media screen and (max-width: 800px) {
    width: 90vw;
    max-width: 445px;

    .carousel .slider-wrapper {
      width: 90vw;
    }

    .carousel.carousel-slider {
      height: 520px;
    }

    .content {
      width: 90vw;
      max-width: 445px;
      height: 453px;
    }
  }
`;
export { ExclusiveBottlesStyled, Outer };
