/* eslint-disable no-confusing-arrow */
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { AutoCarouselBGStyled, Outer } from './style';
import AutoCarouselFG from '../AutoCarouselFG';
import AutoCarouselFGMob from '../AutoCarouselFGMob.js';
import autoCarousel from '../../assets/autoCarousel';
import autoCarouselMob from '../../assets/autoCarouselMob';


function AutoCarouselBG() {
  const mql = window.matchMedia('(min-width: 800px)');
  const isDesktop = mql.matches;

  const mobileSliderData = autoCarouselMob.map((slide, index) => {
    return autoCarousel[index] ? {...autoCarousel[index], ...slide} : slide;
  });

  return (
    <Outer>
      {isDesktop ? (
        <Carousel showThumbs={false} showStatus={false} transitionTime={1000}>
          {autoCarousel.map((slide) => (
            <AutoCarouselBGStyled key={slide.id} bg={slide.backGround} bgFit={slide.bgFit}>
              <AutoCarouselFG slide={slide} />
            </AutoCarouselBGStyled>
          ))}
        </Carousel>
      ) : (
        <Carousel
          showThumbs={false}
          autoPlay={false}
          interval={5000}
          showStatus={false}
          transitionTime={1000}
          showArrows={false}
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={15}
        >
          {mobileSliderData.map((slide) => (
            <AutoCarouselBGStyled key={slide.id} bg={slide.backGround} bgFit={slide.bgFit}>
              <AutoCarouselFGMob slide={slide} />
            </AutoCarouselBGStyled>
          ))}
        </Carousel>
      )}
    </Outer>
  );
}

export default AutoCarouselBG;
