import styled from 'styled-components';

const AutoCarouselFGStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 1016px;
  background: #fbf1e6;
  border-radius: 25px;
  padding: 31px 30px 38px 30px;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 19px;
    margin-left: -44px;
  }
  .mobile{
    @media(min-width: 800px){
      display: none;
    }
  }
  .desktop{
    @media(max-width: 800px){
      display: none;
    }
  }

  .info {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #4c0d18;
    margin-top: 1rem;
    margin-right: 2.5rem;
  }

  @media screen and (max-width: 800px) {
    .info {
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 800px) {
    width: -webkit-fill-available;
    height: auto;
    padding-top: 18px;

    .btn {
      margin-left: 0px;
    }
  }
`;

export default AutoCarouselFGStyled;
