export const createShopifyProductComponentSlider = (id, ui, type) => {
  ui.createComponent('product', {
    id,
    node: document.getElementById(`shopify-component-product-${id}`),
    // eslint-disable-next-line no-template-curly-in-string
    moneyFormat: '${{amount_no_decimals}}',
    options: {
      product: {
        styles: {
          product: {
            '@media (min-width: 601px)': {
              'max-width': 'calc(25% - 75px)',
              'margin-bottom': '10px',
            },
          },
          title: {
            'font-size': '9px',
            color: '#0A3047',
            'border-bottom': '1px solid #4C0D18',
            'padding-bottom': '5px',
            'min-height': '6.5em',
          },
          img: {
            width: '80%',
          },
          button: {
            width: '88px',
            height: '28.17px',
            padding: '0px',
            'font-weight': 'bold',
            'font-size': '10px',
            'border-radius': '6.50326px',
            ':hover': {
              'background-color': '#e46c30',
            },
            'background-color': '#fd7835',
            ':focus': {
              'background-color': '#e46c30',
            },
          },
          buttonWrapper: {
            'margin-top': '15px',
          },
          prices: {
            'margin-bottom': '3px',
          },
          price: {
            'font-size': '14px',
            'font-weight': 700,
            color: '#404756',
          },
          compareAt: {
            display: 'none !important',
          },

        },
        contents: {
          // img: false,
          // title: false,
          // price: false,
        },
        text: {
          button:
            type === 'current' ? 'Shop Now' : 'Get Notified of Future Drops',
        },
      },
      styles: {
        wrapper: {
          margin: '0px',
        },
      },
    },
  });
};

export const createShopifyTastingKitComponentSlider = (id, ui, type) => {
  ui.createComponent('product', {
    id,
    node: document.getElementById(`shopify-component-tastingkit-${id}`),
    options: {
      product: {
        styles: {
          product: {
            padding: '0 15px',
            '@media (min-width: 601px)': {
              'max-width': 'calc(25% - 75px)',
            },
          },
          img: {
            width: '100%',
            'box-shadow': '0px 8.47518px 8.47518px rgba(0, 0, 0, 0.25)',
            'border-radius': '8.5px',
          },
          button: {
            width: '92px',
            height: '30px',
            padding: '0px',
            'font-size': '13px',
            'border-radius': '8.5px',
            ':hover': {
              'background-color': '#e46c30',
            },
            'background-color': '#fd7835',
            ':focus': {
              'background-color': '#e46c30',
            },
          },
          buttonWrapper: {
            'margin-top': '30px',
          },
          compareAt: {
            display: 'none !important',
          },
        },
        contents: {
          // img: false,
          title: false,
          price: false,
          options: false,
          button: type === 'current',
        },
        text: {
          button:
            'Buy Now',
        },
      },
      styles: {
        wrapper: {
          margin: '0px',
        },
      },
    },
  });
};

export const createShopifyProductComponentCurrent = (id, ui) => {
  ui.createComponent('product', {
    id,
    node: document.getElementById(`product-component-${id}`),
    // eslint-disable-next-line no-template-curly-in-string
    moneyFormat: '${{amount_no_decimals}}',
    options: {
      product: {
        styles: {
          product: {
          },
          img: {
            width: '80%',
            'max-height': '158px',
            'object-fit': 'contain',
          },
          title: {
            'font-size': '13px',
            'border-bottom': '1px solid #4C0D18',
            color: '#0A3047',
            'padding-bottom': '10px',
            'min-height': '6.5em',
          },
          price: {
            'font-size': '18px',
            'font-weight': 700,
            color: '#404756',
          },
          compareAt: {
            display: 'none !important',
          },
          button: {
            height: '45px',
            'font-weight': '700',
            'font-size': '18px',

            ':hover': {
              'background-color': '#e46c30',
            },
            'background-color': '#fd7835',
            ':focus': {
              'background-color': '#e46c30',
            },
            'border-radius': '12px',
          },
          quantityInput: {
            'font-size': '14px',
          },
        },
        text: {
          button:
            'Shop Now',
        },
      },
      cart: {
        iframe: false,
        styles: {
          button: {
            'background-color': '#fd7835 !important',
            ':hover': {
              'background-color': '#e46c30',
            },
            ':focus': {
              'background-color': '#e46c30',
            },
          },
        },
      },
      toggle: {
        iframe: false,
        styles: {
          toggle: {
            'background-color': '#fd7835 !important',
          },
        },
      },
    },
  });
};

export const createShopifyProductComponentCurrentMobile = (id, ui) => {
  ui.createComponent('product', {
    id,
    node: document.getElementById(`product-component-${id}`),
    // eslint-disable-next-line no-template-curly-in-string
    moneyFormat: '${{amount_no_decimals}}',
    options: {
      product: {
        styles: {
          product: {
            'max-width': '180px !important',
            'margin-left': '0px !important',
            'margin-bottom': '0px !important',
          },
          img: {
            width: '80%',
            'max-height': '158px',
            'object-fit': 'contain',
            '@media screen and (max-width: 800px)': {
              'max-height': '133px !important',
            },
          },
          title: {
            'font-size': '13px',
            'border-bottom': '1px solid #4C0D18',
            color: '#0A3047',
            'padding-bottom': '10px',
            'min-height': '6.5em',
            '@media screen and (max-width: 800px)': {
              'font-size': '8px !important',
            },
          },
          price: {
            'font-size': '18px',
            'font-weight': 700,
            color: '#404756',
            '@media screen and (max-width: 800px)': {
              'font-size': '8px !important',
            },
          },
          compareAt: {
            display: 'none !important',
          },
          button: {
            height: '45px',
            'font-weight': '700',
            'font-size': '18px',

            ':hover': {
              'background-color': '#e46c30',
            },
            'background-color': '#fd7835',
            ':focus': {
              'background-color': '#e46c30',
            },
            'border-radius': '12px',
            '@media screen and (max-width: 800px)': {
              'font-size': '12px !important',
              height: '36px !important',
              padding: '10px 15px !important',
            },
          },
          quantityInput: {
            'font-size': '14px',
          },
        },
        text: {
          button:
            'Shop Now',
        },
      },
      cart: {
        iframe: false,
        styles: {
          button: {
            'background-color': '#fd7835 !important',
            ':hover': {
              'background-color': '#e46c30',
            },
            ':focus': {
              'background-color': '#e46c30',
            },
          },
        },
      },
      toggle: {
        iframe: false,
        styles: {
          toggle: {
            'background-color': '#fd7835 !important',
          },
        },
      },
    },
  });
};

export const createShopifyProductComponentPast = (id, ui) => {
  ui.createComponent('product', {
    id,
    node: document.getElementById(`product-component-${id}`),
    // eslint-disable-next-line no-template-curly-in-string
    moneyFormat: '${{amount_no_decimals}}',
    options: {
      product: {
        contents: {
          options: false,
        },
        styles: {
          product: {
            '@media (min-width: 601px)': {
              'max-width': 'calc(25% - 20px)',
              'margin-left': '20px',
              'margin-bottom': '50px',
            },
          },
          img: {
            width: '80%',
            'max-height': '158px',
            'object-fit': 'contains',
          },
          title: {
            'font-size': '13px',
            'border-bottom': '1px solid #4C0D18',
            color: '#0A3047',
            'padding-bottom': '10px',
            'min-height': '6.5em',
          },
          price: {
            'font-size': '18px',
            'font-weight': 700,
            color: '#404756',
          },
          compareAt: {
            display: 'none !important',
          },
          button: {
            height: '45px',
            'font-weight': '700',
            'font-size': '18px',

            ':hover': {
              'background-color': '#e46c30',
            },
            'background-color': '#fd7835',
            ':focus': {
              'background-color': '#e46c30',
            },
            'border-radius': '12px',
          },
          quantityInput: {
            'font-size': '14px',
          },
        },
        text: {
          button:
            'Get Notified of Future Drops',
        },
      },
    },
  });
};

export const createShopifyProductComponentPastMobile = (id, ui) => {
  ui.createComponent('product', {
    id,
    node: document.getElementById(`product-component-${id}`),
    // eslint-disable-next-line no-template-curly-in-string
    moneyFormat: '${{amount_no_decimals}}',
    options: {
      product: {
        contents: {
          options: false,
        },
        styles: {
          product: {
            'max-width': 'calc(25% - 20px)',
            'margin-left': '20px',
            'margin-bottom': '50px',
            '@media screen and (max-width: 800px)': {
              'max-width': '180px !important',
              'margin-left': '0px !important',
              'margin-bottom': '0px !important',
            },
          },
          img: {
            width: '80%',
            'max-height': '158px',
            'object-fit': 'contain',
            '@media screen and (max-width: 800px)': {
              'max-height': '133px !important',
            },
          },
          title: {
            'font-size': '13px',
            'border-bottom': '1px solid #4C0D18',
            color: '#0A3047',
            'padding-bottom': '10px',
            'min-height': '6.5em',
            '@media screen and (max-width: 800px)': {
              'font-size': '8px !important',
            },
          },
          price: {
            'font-size': '18px',
            'font-weight': 700,
            color: '#404756',
            '@media screen and (max-width: 800px)': {
              'font-size': '8px !important',
            },
          },
          compareAt: {
            display: 'none !important',
          },
          button: {
            height: '45px',
            'font-weight': '700',
            'font-size': '18px',

            ':hover': {
              'background-color': '#e46c30',
            },
            'background-color': '#fd7835',
            ':focus': {
              'background-color': '#e46c30',
            },
            'border-radius': '12px',
            '@media screen and (max-width: 800px)': {
              'font-size': '12px !important',
              height: '36px !important',
              padding: '10px 15px !important',
            },
          },
          quantityInput: {
            'font-size': '14px',
          },
        },
        text: {
          button:
            'Get Notified of Future Drops',
        },
      },
      cart: {
        iframe: false,
        styles: {
          button: {
            'background-color': '#fd7835 !important',
            ':hover': {
              'background-color': '#e46c30',
            },
            ':focus': {
              'background-color': '#e46c30',
            },
          },
        },
      },
      toggle: {
        iframe: false,
        styles: {
          toggle: {
            'background-color': '#fd7835 !important',
          },
        },
      },
    },
  });
};