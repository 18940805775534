/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useState } from 'react';
import PastSelectionStyled from './style';
import DropDownImg from '../../assets/drop-down-arrow.svg';
import { ShopifyJSContext } from '../../context/ShopifyJSContext';
import { createShopifyProductComponentPast, createShopifyProductComponentPastMobile } from '../../utils/createShopifyComponent';

function PastSelection() {
  const [pastProducts, setPastProducts] = useState([]);
  const [seeMoreShown, setSeeMoreShown] = useState(false);
  const { shopifyClient, shopifyUI } = useContext(ShopifyJSContext);

  const mql = window.matchMedia('(min-width: 800px)');
  const webView = mql.matches;

  const shopifyBuyInit = async () => {
    const ui = shopifyUI;

    const collectionId = 'gid://shopify/Collection/266857447606';
    const collection = await shopifyClient.collection.fetchWithProducts(
      collectionId, { productsFirst: webView? 40 : 20 }
      );

    // const products = await client.product.fetchAll(250);

    const pastSelections = collection.products.filter(
      (p) => !p.availableForSale
    ).map((p) => {
      const idSplit = p.id.split('/');
      return idSplit[idSplit.length - 1];
    });

    setPastProducts(pastSelections);

    setTimeout(() => {
      for (const product of pastSelections) {
        if (webView) {
          createShopifyProductComponentPast(product, ui);
        } else {
          createShopifyProductComponentPastMobile(product, ui);
        }
      }
    }, 500);
  };

  useEffect(() => {
    if (!!shopifyClient && !!shopifyUI) {
      shopifyBuyInit();
    }
  }, [shopifyClient, shopifyUI]);

  return (
    <PastSelectionStyled id='Past-Selections'>
      <div className='container'>
        <div className='header'>Check Out Past Selections</div>
        <div className='sub-heading'>
          <div className='heading ff-NL fw-700' style={{ color: '#404756' }}>
            Featured Selections
          </div>
          <div className='desc ff-CG fw-400'>
            Our single barrel selections do not stick around for long.
            <br />
            Browse our most current selections below, peruse our previous sold
            out picks, and see what we have in our queue!
          </div>
        </div>
        <div
          className='bottles'
          style={{
            maxHeight: !seeMoreShown && webView ? '1385.62px' : !seeMoreShown && !webView && '990px',
            overflow: !seeMoreShown && 'hidden',
          }}
        >
          {pastProducts.map((o) => (
            <SelectionItem id={o} key={o} />
          ))}
        </div>
        {pastProducts.length > (webView ? 12 : 6) && (
          <button
            onClick={() => setSeeMoreShown(!seeMoreShown)}
            type='button'
            className='ff-BO ff-700 read-less'
          >
            {!seeMoreShown ? 'See More' : 'See Less'}
            <img
              style={{
                transform: !seeMoreShown ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
              src={DropDownImg}
              className='drop-down-img'
              alt=''
            />
          </button>
        )}
      </div>
    </PastSelectionStyled>
  );
}

export default PastSelection;

function SelectionItem({ id }) {
  return <div id={`product-component-${id}`} />;
}
