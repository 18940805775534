// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { Portal } from 'react-portal';
import ModalStyle from './style';

// eslint-disable-next-line no-unused-vars
function Modal({ children, show, onClose }) {
  if (!show) {
    document.body.style.height = 'auto';
    document.body.style.overflow = 'scroll';
    document.body.style.paddingRight = '0px';
    return null;
  }

  document.body.style.height = '100vh';
  document.body.style.overflow = 'hidden';
  document.body.style.paddingRight = '20px';

  return (
    <Portal>
      <ModalStyle onClick={onClose}>
        <div className='modal-wrapper' onClick={(e) => e.stopPropagation()}>
          <button
            type='button'
            className='close-btn'
            onClick={onClose}
          >
            <span className='close-text'>X</span>
          </button>
          <div className='modal-content'>
            {children}
          </div>
        </div>
      </ModalStyle>
    </Portal>
  );
}

export default Modal;
