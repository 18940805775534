import { useLayoutEffect, useState } from 'react';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)

  const handleWindowSizeChange = () => {
    if (window !== undefined) {
      const size = window.innerWidth
      setIsMobile(size <= 800)
    }
  }

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange, false)

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange, false)
    }
  }, [])

  return {
    isMobile,
  }
}

export default useIsMobile
