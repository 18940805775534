/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

const TestimonialsStyled = styled.div`
  width: ${(props) => props.contentWidth};
  display: flex;
  flex-direction: ${(props) =>
    props.id === 8 ? props.flexDirection : 'column'};
  justify-content: ${(props) => props.id === 8 && props.justifyContent};
  align-items: ${(props) => props.id === 8 && props.alignItems};
  font-family: 'Century Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.fontSize};
  line-height: 140%;
  text-align: center;
  color: #0a3047;
  margin-top: 45px;
    
  @media(max-width:800px){
    width: revert;
   font-size: 13px !important;
  }

  a {
    color: #fd7835;
    font-family: 'Century Gothic BO', sans-serif;
    font-weight: 700;
    font-size: 19px;
    margin-top: 10px;
    margin-right: 2rem;
  }

  .head-shot-container {
    display: flex;
    column-gap: 16px;
      
    @media(max-width:800px){
      display: none;
    }
  }

  .head-shot-container-mobile{
    display: flex;
    column-gap: 16px;
    justify-content: center;
    width: 97vw;

    .carousel.carousel-slider .control-arrow {
      top: 50px;
    }
      
    @media(min-width:800px){
      display: none;
    }
  }
    
  .testimonials-container {
    display: flex;
    width: 100%;
    height: auto;
    aspect-ratio: 4/5;
    border-radius: 10px;
    overflow: hidden;

      @media(max-width:800px){
          width: auto;
          height: 240px;
      }
  }

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: ${(props) => props.fontSize};
    margin-top: 18px;

    a {
      color: #fd7835;
      font-family: 'Century Gothic BO';
      font-weight: 700;
      font-size: 19px;
      margin-top: 10px;
      margin-right: 0px;
    }
  }

  .carousel.carousel-slider .control-arrow {
    background: transparent;
    opacity: 1;
    top: 125px;
    padding: 0px;
    transform: scale(1.2);
    position: absolute;
    background: #fbf1e6;
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }

  .carousel-root{
      width: -webkit-fill-available;
  }

  .carousel.carousel-slider .control-arrow.control-prev {
    left: 2%;
  }

  .carousel.carousel-slider .control-arrow.control-next {
    right: 2%;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 16px solid #fd7835;
    margin-right: 10px;
    cursor: pointer;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 16px solid #fd7835;
    margin-left: 8px;
    cursor: pointer;
  }

  .carousel .slider .slide {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
    display: flex;
    align-items: center;
  }

  .carousel .slider .selected {
    transform: scale(1);
    opacity: 1;
  }

  .carousel .slide iframe {
    margin-bottom: 0px;
  }
`;

export { TestimonialsStyled };
