import styled from 'styled-components';

const TRStyled = styled.div`
  background: #fbf1e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0rem;
  row-gap: 3rem;

  .container {
    width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 3rem;

    .desc {
      width: 986px;
      font-style: normal;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: #0a3047;
    }

    .videos {
      width: 1140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
      row-gap: 5rem;

      .iframe-section {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        .video-title {
          font-family: 'Nielmaden';
          color: #0a3047;
          font-weight: 800;
          font-size: 20px;
        }

        .iframe {
          width: 1139px;
          height: 663px;
          background: rgba(76, 13, 24, 0.5);
          mix-blend-mode: multiply;
          border-radius: 27px;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 2rem 2rem;
    .container {
      width: 100%;
      row-gap: 0rem;

      .desc {
        width: 90vw;
        font-size: 14px;
      }

      .videos {
        width: 90vw;
        .iframe-section {
          .video-title {
            font-size: 14px;
            width: 90vw;
            line-height: 140%;
          }
          .iframe {
            width: 90vw;
            height: 290px;
            border-radius: 16px;
          }
        }
      }
    }
  }
`;

export default TRStyled;
