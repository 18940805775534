/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import WPDStyled from './style';
import Header from '../Header';
import rowOneImgOne from '../../assets/first-row/img-1.png';
import rowOneImgTwo from '../../assets/first-row/img-2.png';
import rowOneImgThree from '../../assets/first-row/img-3.png';
// import rowOneImgFour from '../../assets/first-row/img-4.png';
import rowTwoImgOne from '../../assets/second-row/img-1.png';
import rowTwoImgTwo from '../../assets/second-row/img-2.png';
import rowTwoImgThree from '../../assets/second-row/img-3.png';
// import rowTwoImgFour from '../../assets/second-row/img-4.png';

function WhitePaperDownload() {
  return (
    <WPDStyled id='Whitepaper'>
      <div className='container'>
        <Header
          headingOne='Read More Below'
          headingTwo='Whitepaper Download'
          rowGap='25px'
          alignItems='start'
        />
        <div className='content'>
          <div className='text-area'>
            <div className='heading ff-NL fw-700'>Whitepaper Overview</div>
            <br />
            <div className='desc'>
              If you gotten this far on this page, we thank you – you are most
              likely intrigued by the value of our upcoming membership and
              considering a purchase. <br /> <br /> We invite you to download
              our white paper to get even a better understanding of how we are
              crossing the rubicon by combining NFTs and whiskey.
            </div>
            <br />
            <br />
            <button
              onClick={() =>
                window.open('https://assets.primebarrel.com/WhitePaper.pdf')
              }
              type='button'
            >
              <span className='btn ff-BO fw-700'>Download Now</span>
            </button>
          </div>
          <div className='image-area'>
            <div className='maroon-bg'>
              <div className='img-fg'>
                <div className='row'>
                  <img src={rowOneImgOne} alt='' />
                  <img src={rowOneImgTwo} alt='' />
                  <img src={rowOneImgThree} alt='' />
                </div>
                <div className='row'>
                  <img src={rowTwoImgOne} alt='' />
                  <img src={rowTwoImgTwo} alt='' />
                  <img src={rowTwoImgThree} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WPDStyled>
  );
}

export default WhitePaperDownload;
