import styled from 'styled-components';

const FAQStyled = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container {
    width: 994px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .question-section {
      width: 994px;
      margin-top: 4rem;

      .question-ans-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        row-gap: 32px;
        border-top: 1px solid #4c0d18;
        padding-top: 30px;
        padding-bottom: 36px;
        align-items: flex-start;

        .question-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 994px;

          .question {
            width: 844px;
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #404756;
          }

          .symbol {
            width: 26px;
            height: 26px;
            cursor: pointer;
          }
        }

        .answer-container {
          width: 844px;
          font-family: 'Century Gothic';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
          color: #404756;
          overflow: hidden;
        }
      }
    }
  }


  @media screen and (max-width: 800px) {
    .container {
      width: 80vw;;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
  
      .question-section {
        width: auto;
        margin-top: 30px;
  
        .question-ans-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          row-gap: 4px;
          border-top: 1px solid #4c0d18;
          padding-top: 14px;
          padding-bottom: 14px;
          align-items: flex-start;
  
          .question-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
  
            .question {
              width: auto;
              font-family: 'Century Gothic';
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 14px;
              color: #404756;
            }
  
            .symbol {
              width: 10px;
              height: 10px;
              cursor: pointer;
            }
          }
  
          .answer-container {
            width: auto;
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 400;
            font-size: 8px;
            line-height: 140%;
            color: #404756;
            overflow: hidden;
          }
        }
      }
    }
  }
`;

export default FAQStyled;
