import stickerSagamore from './sticker-sagamore.png';
import stickerFoundNorth from './sticker-foundnorth.png';
import stickerCopperCask from './sticker-coppercask.png';
import stickerLaws from './sticker-laws.png';
import stickerIronclad from './sticker-ironclad.png';
import stickerDadsHat from './sticker-dadshat.png';
import stickerFourRoses from './sticker-fourroses.png';
import stickerNewRiff from './sticker-newriff.png';
import stickerBullRun from './sticker-bullrun.png';
import stickerRareCharacter from './sticker-rarecharacter.png';

export const partnersFirstLine = [
  {
    sticker: stickerFoundNorth,
    name: 'Found North',
    firstImageWidth: '106.02px',
    firstImageHeight: '38.52px',
    secondImageWidth: '210.37px',
    secondImageHeight: '76.43px',
    marginLeft: '-11px',
  },
  {
    sticker: stickerSagamore,
    name: 'Sagamore',
    firstImageWidth: '80.85px',
    firstImageHeight: '80.85px',
    secondImageWidth: '160.43px',
    secondImageHeight: '160.43px',
  },
  {
    sticker: stickerCopperCask,
    name: 'Copper & Cask',
    firstImageWidth: '124.33px',
    firstImageHeight: '52.25px',
    secondImageWidth: '246.69px',
    secondImageHeight: '103.67px',
  },
  {
    sticker: stickerLaws,
    name: 'Laws',
    firstImageWidth: '80.85px',
    firstImageHeight: '80.85px',
    secondImageWidth: '160.43px',
    secondImageHeight: '160.43px',
  },
  {
    sticker: stickerRareCharacter,
    name: 'Rare Character',
    firstImageWidth: '125px',
    firstImageHeight: '64px',
    secondImageWidth: '245px',
    secondImageHeight: '125px',
  },
]

export const partnersSecondLine = [
  {
    sticker: stickerFourRoses,
    name: 'Four Roses',
    firstImageWidth: '80.85px',
    firstImageHeight: '80.85px',
    secondImageWidth: '160.43px',
    secondImageHeight: '160.43px',
  },
  {
    sticker: stickerBullRun,
    name: 'Bull Run',
    firstImageWidth: '125px',
    firstImageHeight: '50px',
    secondImageWidth: '245px',
    secondImageHeight: '98px',
  },
  {
    sticker: stickerDadsHat,
    name: 'Dad\'s Hat',
    firstImageWidth: '125px',
    firstImageHeight: '50px',
    secondImageWidth: '245px',
    secondImageHeight: '98px',
  },
  {
    sticker: stickerIronclad,
    name: 'Ironclad',
    firstImageWidth: '80.85px',
    firstImageHeight: '80.85px',
    secondImageWidth: '160.43px',
    secondImageHeight: '160.43px',
  },
  {
    sticker: stickerNewRiff,
    name: 'New Riff',
    firstImageWidth: '80.85px',
    firstImageHeight: '80.85px',
    secondImageWidth: '160.43px',
    secondImageHeight: '160.43px',
  },
]