/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

const PopUpHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  row-gap: ${(props) => props.rowGap};
  font-family: 'Nielmaden';

  .top-text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #0a3047;
  }

  .yellow-line {
    width: 100px;
    height: 8px;
    background: #ffb32e;
  }

  .bottom-text {
    font-family: 'Nielmaden';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 120%;
    text-align: ${(props) =>
      props.bottomTextAlign ? props.bottomTextAlign : 'center'};
    color: #0a3047;
  }

  @media screen and (max-width: 800px) {
    .top-text {
      font-size: 10px;
    }

    .bottom-text {
      font-size: 32px;
    }
  }
`;

export default PopUpHeaderStyled;
