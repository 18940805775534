/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { ContentStyled, MiniCarouselStyled, Outer, OuterCarousel } from './style';
import Header from '../Header';
import WalletButton from '../WalletButton';
import NotificationModal from '../NotificationModal';
import { CurrentSelectionStyled } from '../CurrentSelection/style';
import { CarouselContentStyled } from '../CarouselContent/style';
import BrandPartnersSubSlide from '../BrandPartnersSubSlide';
import TastingKitsSubSlide from '../TastingKitsSubSlide';
import { ShopifyJSContext } from '../../context/ShopifyJSContext';
import { CsvCMSDataContext } from '../../context/CsvCMSDataContext';
import useIsMobile from '../../hooks/useIsMobile';
import VimeoPlayer from '../VimeoPlayer';

function MobileMiniCarousel({ subCarousel }) {
  const [notificationOne, setNotificationOne] = useState(false);
  const [notificationTwo, setNotificationTwo] = useState(false);

  const [currentProducts, setCurrentProducts] = useState([]);
  const [pastProducts, setPastProducts] = useState([]);

  const { shopifyClient, shopifyUI } = useContext(ShopifyJSContext);
  const { barrelPickingPartners, futureSelectionsDivided, loading } = useContext(CsvCMSDataContext);
  const { isMobile } = useIsMobile();

  const [futureSelectionSlidesAdded, setFutureSelectionSlidesAdded] = useState(false);
  let futureSelectionsListIndex = -1; // Starts in -1 because I increment it before using it
  const hasFutureSelectionsList = subCarousel.some(slide => slide.futureSelectionsList)

  const [barrelPickingPartnersListToPrint, setBarrelPickingPartnersListToPrint] = useState([]);

  // Creates a copy of the subcarousel and adds the future selections slides if it needs more than just one
  // At least one subcarousel slide for Future selections has to be defined in assets/autoCarousel.js
  const subCarouselCopy = useMemo(() => {
    if (!hasFutureSelectionsList || (hasFutureSelectionsList && loading)) {
      return [...subCarousel];
    }

    let copy = [...subCarousel];
    const futureSelectionSlide = copy.find(slide => slide.futureSelectionsList);

    if (!!futureSelectionSlide && futureSelectionsDivided.length > 1 && !futureSelectionSlidesAdded) {
      for (let i = 1; i < futureSelectionsDivided.length; i++) {
        copy = [...copy, { ...futureSelectionSlide, id: futureSelectionSlide.id + i }];
      }

      setFutureSelectionSlidesAdded(true);
    }

    return copy;
  }, [subCarousel, futureSelectionsDivided, loading]);

  const createComponent = (id, ui, type) => {
    ui.createComponent('product', {
      id,
      node: document.getElementById(`carousel-component-${id}`),
      // eslint-disable-next-line no-template-curly-in-string
      moneyFormat: '${{amount_no_decimals}}',
      options: {
        product: {
          styles: {
            product: {
              'max-width': '180px !important',
              'margin-left': '0px !important',
              'margin-bottom': '0px !important',
            },
            img: {
              width: '80%',
              'max-height': '158px',
              'object-fit': 'contain',
              '@media screen and (max-width: 800px)': {
                'max-height': '133px !important',
              },
            },
            title: {
              'font-size': '13px',
              'border-bottom': '1px solid #4C0D18',
              color: '#0A3047',
              'padding-bottom': '10px',
              'min-height': '6.5em',
              '@media screen and (max-width: 800px)': {
                'font-size': '8px !important',
              },
            },
            price: {
              'font-size': '18px',
              'font-weight': 700,
              color: '#404756',
              '@media screen and (max-width: 800px)': {
                'font-size': '8px !important',
              },
            },
            compareAt: {
              display: 'none !important',
            },
            button: {
              height: '45px',
              'font-weight': '700',
              'font-size': '18px',

              ':hover': {
                'background-color': '#e46c30',
              },
              'background-color': '#fd7835',
              ':focus': {
                'background-color': '#e46c30',
              },
              'border-radius': '12px',
              '@media screen and (max-width: 800px)': {
                'font-size': '10px !important',
                height: '36px !important',
                padding: '10px 15px !important',
              },
            },
            quantityInput: {
              'font-size': '14px',
            },
          },
          contents: {
            // img: false,
            // title: false,
            // price: false,
          },
          text: {
            button:
              type === 'current' ? 'Shop Now' : 'Get Notified of Future Drops',
          },
        },
        styles: {
          wrapper: {
            margin: '0px',
          },
        },
      },
    });
  };

  const shopifyCurrentBuyInit = async () => {
    const ui = shopifyUI;

    const collectionId = 'gid://shopify/Collection/266857873590';
    const collection = await shopifyClient.collection.fetchWithProducts(
      collectionId, { productsFirst: 4 }
    );

    // const products = await client.product.fetchAll(250);

    const currentSelections = collection.products.filter(
      (p) => p.availableForSale
    ).map((p) => {
      const idSplit = p.id.split('/');
      return idSplit[idSplit.length - 1];
    });

    setCurrentProducts(currentSelections);

    setTimeout(() => {
      for (const product of currentSelections) {
        createComponent(product, ui, 'current');
      }
    }, 500);
  };

  const shopifyPastBuyInit = async () => {
    const ui = shopifyUI;

    const pastSelections = [
      '6767562326198',
      '7210447044790',
      '7299051258038',
      '7395317547190',
    ];
    setPastProducts(pastSelections);

    setTimeout(() => {
      for (const product of pastSelections) {
        createComponent(product, ui, 'past');
      }
    }, 500);
  };

  useEffect(() => {
    if (loading || !shopifyClient || !shopifyUI) {
      return;
    }

    if (
      subCarousel[0].headingTwo === 'Shop Current Selections'
    ) {
      shopifyCurrentBuyInit();
    } else if (
      subCarousel[0].headingTwo === 'Check Out Past Selections'
    ) {
      shopifyPastBuyInit();
    }
  }, [loading, shopifyClient, shopifyUI]);

  useEffect(() => {
    if(isMobile && barrelPickingPartners.length > 28) {
      setBarrelPickingPartnersListToPrint(() => {
        return barrelPickingPartners.slice(0,28);
      })
    } else {
      setBarrelPickingPartnersListToPrint(barrelPickingPartners);
    }
  }, [barrelPickingPartners])

  return !hasFutureSelectionsList || (hasFutureSelectionsList && !loading) ? (
    <MiniCarouselStyled>
      <OuterCarousel>
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          transitionTime={1000}
          swipeable={false}
        >
          {subCarouselCopy.map((subSlide) => {

            if (subSlide.futureSelectionsList && (futureSelectionsListIndex < futureSelectionsDivided.length - 1)) {
              futureSelectionsListIndex++;
            }

            return (
              <React.Fragment key={`subslide-mobile-${subSlide.id}`}>
                <Header
                  headingOne={subSlide.headingOne}
                  headingTwo={subSlide.headingTwo}
                  headingThree={subSlide.headingThree}
                  rowGap={subSlide.rowGap ? subSlide.rowGap : ''}
                  headerWidth={subSlide.headerWidth ? subSlide.headerWidth : ''}
                />

                {subSlide.paraOne || subSlide.paraTwo || subSlide.subtitle ? (
                  <CarouselContentStyled fontSize={subSlide.fontSize}
                                         contentWidth={subSlide.contentWidth}>
                    {subSlide.paraOne && (
                      <div id='paraOne'
                           dangerouslySetInnerHTML={{ __html: subSlide.paraOneMob ? subSlide.paraOneMob : subSlide.paraOne }} />
                    )}
                    {subSlide.paraTwo && (
                      <div id='paraTwo' dangerouslySetInnerHTML={{ __html: subSlide.paraTwo }} />
                    )}
                    {subSlide.subtitle && <div className='third-text'>{subSlide.subtitle}</div>}
                  </CarouselContentStyled>
                ) : null}

                {subSlide.brandPartnerLogos && (
                  <BrandPartnersSubSlide subSlide={subSlide} mobile />
                )}

                {subSlide.partnerList && (
                  <ContentStyled
                    contentWidth={subSlide.contentWidth}
                    fontSize={subSlide.fontSize}
                  >
                    <div className='list-container'
                         style={{ gridTemplateRows: `repeat(${Math.ceil(barrelPickingPartnersListToPrint.length / (isMobile ? 2 : 3))}, minmax(min-content, max-content))` }}>
                      {barrelPickingPartnersListToPrint.map((partner, index) => (
                        <div
                          key={`barrel-picking-partners-mobile-${index}`}>{partner['Partner Name']}</div>))
                      }
                    </div>
                    {isMobile? (
                      <a
                        href={subSlide.shopNowBtn ? '#Current-Selections' : '#Past-Selections'}
                        style={{
                          color: '#fd7835',
                          fontFamily: 'Century Gothic BO',
                          fontWeight: '700',
                          fontSize: '14px',
                          display: 'inline-block',
                          marginTop: '12px',
                        }}
                      >
                        See More
                      </a>
                    ) : null}
                  </ContentStyled>
                )}

                {!!subSlide.showTastingKits && (
                  <TastingKitsSubSlide subSlide={subSlide} />
                )}

                {subSlide.showShopifyProducts && subSlide.shopNowBtn && (
                  <CurrentSelectionStyled style={{ marginBottom: '0px' }}>
                    <div className='bottle-carousal' style={{ marginTop: '35px' }}>
                      <Outer>
                        <Carousel
                          showThumbs={false}
                          centerMode
                          centerSlidePercentage={40}
                          showStatus={false}
                          showIndicators={false}
                          preventMovementUntilSwipeScrollTolerance
                        >
                          {currentProducts.map((id) => (
                            <div
                              key={id}
                              id={`carousel-component-${id}`}
                              className='shopify-card'
                            />
                          ))}
                        </Carousel>
                        <a
                          href='#Current-Selections'
                          style={{
                            color: '#fd7835',
                            fontFamily: 'Century Gothic BO',
                            fontWeight: '700',
                            fontSize: '14px',
                            display: 'inline-block',
                            marginTop: '1rem',
                          }}
                        >See More
                        </a>
                      </Outer>
                      {notificationOne && (
                        <div className='notification-modal-one'>
                          <div
                            className='close-text'
                            onClick={() => setNotificationOne(false)}
                          >
                            X
                          </div>
                        </div>
                      )}
                    </div>
                  </CurrentSelectionStyled>
                )}
                {subSlide.showShopifyProducts && subSlide.getNotifiedBtn && (
                  <CurrentSelectionStyled style={{ marginBottom: '0px' }}>
                    <div className='bottle-carousal' style={{ marginTop: '35px' }}>
                      <Outer>
                        <Carousel
                          showThumbs={false}
                          centerMode
                          centerSlidePercentage={40}
                          showStatus={false}
                          showIndicators={false}
                          preventMovementUntilSwipeScrollTolerance
                        >
                          {pastProducts.map((id) => (
                            <div
                              key={id}
                              id={`carousel-component-${id}`}
                              className='shopify-card'
                            />
                          ))}
                        </Carousel>
                        <a
                          href='#Past-Selections'
                          style={{
                            color: '#fd7835',
                            fontFamily: 'Century Gothic BO',
                            fontWeight: '700',
                            fontSize: '14px',
                            display: 'inline-block',
                            marginTop: '1rem',
                          }}
                        >See More
                        </a>
                      </Outer>
                      {notificationOne && (
                        <div className='notification-modal-one'>
                          <div
                            className='close-text'
                            onClick={() => setNotificationOne(false)}
                          >
                            X
                          </div>
                        </div>
                      )}
                    </div>
                  </CurrentSelectionStyled>
                )}

                {subSlide.futureSelectionsList && (
                  <>
                    <ContentStyled
                      contentWidth={subSlide.contentWidth}
                      fontSize={subSlide.fontSize}
                    >
                      <div
                        className='list-container-two'
                        style={{ marginTop: '3rem' }}
                      >
                        {futureSelectionsDivided[futureSelectionsListIndex].map((selection, index) => (
                          <div key={`future-selection-list-mobile-${futureSelectionsListIndex}-${index}`}>
                            <span id='underline'>{selection['Brand']}</span> {selection['Expression']}
                          </div>
                        ))}
                      </div>
                      {futureSelectionsListIndex > 0 && <div id='continue'>Continued</div>}
                      {subSlide.getNotifiedBtn && (
                        <button className='get-notified' onClick={() => {
                          _Sendlane.openForm('eyJkb21haW4iOiJkZXdpbmVzcG90IiwiZm9ybV9pZCI6ImRhMmY3YjJhLTk5N2QtNGVjNi05ZDFjLWNkMTgyYjg4YjFhNSJ9');
                        }}>
                          Get Notified of Future Drops
                        </button>
                      )}
                    </ContentStyled>
                    {notificationTwo && (
                      <NotificationModal
                        setNotificationTwo={setNotificationTwo}
                      />
                    )}
                  </>
                )}

                {subSlide.videoEmbed && (
                  <ContentStyled contentWidth={subSlide.contentWidth}>
                    <div className='video-embed'>
                      <VimeoPlayer videoId={subSlide.videoEmbed} />
                    </div>
                  </ContentStyled>
                )}

                {subSlide.showWalletButton && (
                  <div className='btn'>
                    <WalletButton
                      action='CONNECT_AND_WHITELIST'
                      defaultText='Join Now'
                    />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </Carousel>
      </OuterCarousel>
    </MiniCarouselStyled>
  ) : null;
}

export default MobileMiniCarousel;
