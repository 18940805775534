import React from 'react';
import AboutNFTsStyled from './style';
import Header from '../Header';
import Image from '../../assets/ConsumpScene2_Speakeasy.jpg';

function AboutNFTs() {
  return (
    <AboutNFTsStyled id='Why-NFTs'>
      <div className='container'>
        <div className='heading-content'>
          <Header
            headingOne='About Our Membership'
            headingTwo='Welcome to the Future of Whiskey Club Membership'
            headerWidth='100%'
            headerMaxWidth='890px'
            rowGap='25px'
          />
          <div className='content'>
            <div className='subheading'>
              <h3 className='subtitle'>Simple, Secure, Special: That’s The Prime Barrel Membership
                Experience</h3>
              <p className='text'>At The Prime Barrel Club, we’re revolutionizing how memberships
                work
                by incorporating a
                unique, easy-to-use digital approach. Our membership model uses Non-Fungible Tokens
                (NFTs), but don’t let the jargon intimidate you—it’s as simple as having an app on
                your
                phone!</p>
            </div>
          </div>
        </div>
        <div className='column-container'>
          <div className='two-column-content'>
            <div className='text-content underline'>
              <h3 className='subtitle extra-bold'>Why The Prime Barrel Membership?</h3>
              <div className='text'>
                <ul>
                  <li>Security and Authenticity: Your membership is as unique as you are, securely
                    tied to a digital token that cannot be duplicated.
                  </li>
                  <li>Effortless Experience: Forget everything you think you know about blockchain
                    and NFTs. Using your digital membership is as easy as tapping your smartphone.
                    You don’t even need to own crypto to partake!
                  </li>
                  <li>Exclusive Benefits: Unlock doors to members-only discounts, special
                    releases, and insider events. It’s your all-access pass to a world of fine
                    whiskey.
                  </li>
                </ul>
              </div>
            </div>
            <div className='misc-content'>
              <img src={Image} alt='' />
            </div>
          </div>
          <div className='two-column-content inverted'>
            <div className='text-content'>
              <h3 className='subtitle'>Common Concerns? We’ve got Answers:</h3>
              <div className='text'>
                <ul>
                  <li>Worried about scams? Rest easy. Our blockchain technology is transparent and
                    secure, making your membership benefits clear and tamper-proof.
                  </li>
                  <li>Confused about NFTs? No tech expertise is needed. We guide you through the
                    simple setup, and you enjoy the perks.
                  </li>
                  <li>Prefer traditional? Our digital approach cuts overheads, keeps prices fair,
                    and delivers real-time benefits directly to you—things a conventional card
                    just can’t do.
                  </li>
                </ul>
                <a href='mailto:hello@primebarrel.com' className='contact-link'>Still Have Questions? Contact Us!</a>
              </div>
            </div>
            <div className='misc-content blue-bg-text'>
              <p className='subtitle regular'>Join us at The Prime Barrel Club, where tradition
                meets innovation, and every member is part of something extraordinary.</p>
            </div>
          </div>
        </div>
      </div>
    </AboutNFTsStyled>
  );
}

export default AboutNFTs;
