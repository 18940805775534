import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { ExclusiveBottlesStyled, Outer } from './style';
import Header from '../Header';

import imgMore from '../../assets/exclusiveBottles/EG-bg-3.png';
import imgMoreMobile from '../../assets/exclusiveBottles/EG-mobile-bg-3.png';

import imgOne from '../../assets/exclusiveBottles/exclusive-bottles01.jpg';
import imgOneMobile from '../../assets/exclusiveBottles/EB-mobile-bg-1.jpg';
import imgTwo from '../../assets/exclusiveBottles/exclusive-bottles02.jpg';
import imgTwoMobile from '../../assets/exclusiveBottles/EB-mobile-bg-2.jpg';
import imgThree from '../../assets/exclusiveBottles/exclusive-bottles03.jpg';
import imgThreeMobile from '../../assets/exclusiveBottles/EB-mobile-bg-3.png';
import imgFour from '../../assets/exclusiveBottles/exclusive-bottles04.jpg';
import imgFourMobile from '../../assets/exclusiveBottles/EB-mobile-bg-4.png';
import imgFive from '../../assets/exclusiveBottles/exclusive-bottles05.jpg';
import imgFiveMobile from '../../assets/exclusiveBottles/EB-mobile-bg-5.jpg';
import imgSix from '../../assets/exclusiveBottles/exclusive-bottles06.jpg';
import imgSixMobile from '../../assets/exclusiveBottles/EB-mobile-bg-6.png';

import logo from '../../assets/exclusiveBottles/PB-logo.png';
import sagamore from '../../assets/exclusiveBottles/sagamore-spirit.png';
import foundNorth from '../../assets/exclusiveBottles/foundnorth.png';
import ironClad from '../../assets/exclusiveBottles/iron-clad.png';
import fourRoses from '../../assets/exclusiveBottles/four-roses.png';
import newRiff from '../../assets/exclusiveBottles/newriff.png';
import copperCask from '../../assets/exclusiveBottles/copperandcask.png';
import More from '../../assets/exclusiveBottles/more.png';
import useIsMobile from '../../hooks/useIsMobile';

function ExclusiveBottles() {
  const {isMobile} = useIsMobile()

  return (
    <ExclusiveBottlesStyled id='Exclusive-Bottles'>
      <div className='container'>
        <Header
          headingOne='Partnerships'
          headingTwo='Exclusive Bottles'
          rowGap='25px'
          headerMaxWidth='898px'
        />
        <div className='sub-heading'>
          <div className='heading ff-NL fw-700'>Overview partnerships</div>
          <div className='text ff-CG fw-400'>
            In addition to delicious single barrel expressions we select, we are
            partnering with multiple renowned distilleries on exclusive bottles
            that would only be available to our members. These expressions will
            come from barrels we hand-picked with respective master distillers
            and are not open to the general public.
            <br />
            <br />
            <span className='ff-BO'>
              If that were not enough, each bottle would bear a custom front
              label bearing our custom art!
            </span>
          </div>
        </div>

        <Outer>
          <Carousel
            showThumbs={false}
            showArrows={isMobile}
            showStatus={false}
            transitionTime={1000}
          >
            <div className='content'>
              <div className='maroon-bg'>
                <div className='yellow-bg'>
                  <img src={logo} alt='' className='logo-image' />
                  <div className='plus-sign'>+</div>
                  <img
                    style={{ mixBlendMode: 'multiply' }}
                    src={fourRoses}
                    alt=''
                    className='sticker'
                  />
                </div>
              </div>
              <img src={isMobile ? imgOneMobile : imgOne} alt='' />
            </div>
            <div className='content'>
              <div className='maroon-bg'>
                <div className='yellow-bg'>
                  <img src={logo} alt='' className='logo-image' />
                  <div className='plus-sign'>+</div>
                  <img
                    style={{ height: 'auto', mixBlendMode: 'multiply' }}
                    src={newRiff}
                    alt=''
                    className='sticker'
                  />
                </div>
              </div>
              <img src={isMobile ? imgTwoMobile : imgTwo} alt='' />
            </div>
            <div className='content'>
              <div className='maroon-bg'>
                <div className='yellow-bg'>
                  <img src={logo} alt='' className='logo-image' />
                  <div className='plus-sign'>+</div>
                  <img src={foundNorth} alt='' className='sticker' />
                </div>
              </div>
              <img src={isMobile? imgThreeMobile : imgThree} alt='' />
            </div>
            <div className='content'>
              <div className='maroon-bg'>
                <div className='yellow-bg'>
                  <img src={logo} alt='' className='logo-image' />
                  <div className='plus-sign'>+</div>
                  <img
                    style={{ mixBlendMode: 'multiply' }}
                    src={sagamore}
                    alt=''
                    className='sticker'
                  />
                </div>
              </div>
              <img src={isMobile? imgFourMobile : imgFour} alt='' />
            </div>
            <div className='content'>
              <div className='maroon-bg'>
                <div className='yellow-bg'>
                  <img src={logo} alt='' className='logo-image' />
                  <div className='plus-sign'>+</div>
                  <img style={{ mixBlendMode: 'multiply' }} src={copperCask} alt='' className='sticker' />
                </div>
              </div>
              <img src={isMobile ? imgFiveMobile : imgFive} alt='' />
            </div>
            <div className='content'>
              <div className='maroon-bg'>
                <div className='yellow-bg'>
                  <img src={logo} alt='' className='logo-image' />
                  <div className='plus-sign'>+</div>
                  <img src={ironClad} alt='' className='sticker' />
                </div>
              </div>
              <img src={isMobile? imgSixMobile : imgSix} alt='' />
            </div>
            <div className='content'>
              <div className='maroon-bg'>
                <div className='yellow-bg'>
                  <img src={logo} alt='' className='logo-image' />
                  <div className='plus-sign'>+</div>
                  <div className='sticker'>
                    <img src={More} alt='' />
                  </div>
                </div>
              </div>
              <img src={isMobile ? imgMoreMobile : imgMore} alt='' />
            </div>
          </Carousel>
        </Outer>
      </div>
    </ExclusiveBottlesStyled>
  );
}

export default ExclusiveBottles;
