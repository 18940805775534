import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import imgOne from '../../assets/about-PB/pb01.jpg';
import imgTwo from '../../assets/about-PB/pb02.jpg';
import imgThree from '../../assets/about-PB/pb03.jpg';
import imgFour from '../../assets/about-PB/pb04.jpg';

import { AboutPBStyled, OuterCarousel } from './style';
import Header from '../Header';
import PBlogo from '../../assets/prime-barrel-logo.png';

function AboutPB() {
  const images = [
    {
      img: imgOne,
    },
    {
      img: imgTwo,
    },
    {
      img: imgThree,
    },
    {
      img: imgFour,
    },
    {
      img: 'https://assets.primebarrel.com/WebsiteImages/About+Prime+Barrel/About_Carousel1.webp',
    },
  ];
  const mobImages = [
    { img: imgOne },
    { img: imgTwo },
    { img: imgThree },
    { img: imgFour },
    {
      img: 'https://assets.primebarrel.com/WebsiteImages/About+Prime+Barrel/About_Carousel1.webp',
    },
  ];

  const mql = window.matchMedia('(min-width: 600px)');

  const webView = mql.matches;

  return (
    <AboutPBStyled>
      <Header
        headingOne='What is Prime Barrel'
        headingTwo='Welcome to the Prime Barrel Whiskey Club'
        headerWidth='823px'
        rowGap='25px'
      />
      <div className='sub-part'>
        <div className='sub-heading'>What Sets Us Apart</div>
        <div className='sub-content'>
          Delicious and diverse whiskey single barrel expression selections,
          in-person and virtual selection experiences, vibrant and active
          Discord whiskey enthusiasts community, local gatherings and “bottle
          shares”, sample giveaways are among many club perks that contribute to
          making lifetime friendships.
        </div>
      </div>
      <OuterCarousel>
        {webView ? (
          <Carousel showThumbs={false} showStatus={false} transitionTime={1000}>
            {images.map((i) => (
              <div className='image-container' key={i.img}>
                {/* <div className='image'> */}
                <img
                  src={i.img}
                  alt=''
                  className='image'
                  loading='lazy'
                />
                {/* </div> */}

                <div className='logo-image'>
                  <img src={PBlogo} alt='' />
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel showThumbs={false} showStatus={false} transitionTime={1000}>
            {mobImages.map((i) => (
              <div className='image-container' key={i.img}>
                <div className='image'>
                  <img src={i.img} loading='lazy' style={{ borderRadius: '25px' }} alt='' />
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </OuterCarousel>
    </AboutPBStyled>
  );
}

export default AboutPB;
