import styled from 'styled-components';

const AboutNFTsStyled = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 90px;

  .cta-btn {
    display: inline-block;
    padding: 10px 25px;
    background: #fd7835;
    color: #ffffff;
    border-radius: 12px;
    border: none;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }
    
  .contact-link {
    margin-left: 30px;
    margin-top: 54px;
    display: inline-block;
    font-family: 'Century Gothic BO';
    color: #4C0D18;
    text-decoration: underline;
  }

  .subtitle {
    margin: 0;
    font-family: 'Nielmaden';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.1;
    color: #0a3047;
      
      &.extra-bold {
        font-weight: 800;
      }
      &.regular {
          font-weight: 400;
      }
  }

  .text {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #0a3047;
    margin-top: 30px;
    margin-bottom: 0;

    ul {
        margin: 0;
        padding-left: 30px;
    }
  }

  .container {
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    flex-direction: column;
      
    .heading-content {
      width: 100%;
      max-width: 1270px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
      
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
        
      .subheading {
        margin-top: 85px;
        max-width: 1100px;
        text-align: center;
          
          .text {
              margin-top: 55px;
          }
      }
    }

    .column-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      row-gap: 75px;

      .two-column-content {
        display: flex;
        column-gap: 70px;
        margin-left: auto;
        align-items: center;
        padding-left: 20px;

        &.inverted {
          padding-left: 0;
          padding-right: 20px;
          flex-direction: row-reverse;
        }
        
        .text-content {
            max-width: 550px;
        }
          
        .underline {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-bottom: 1px solid #4C0D18;
            
            @media screen and (max-width: 800px) {
                border: none;
            }
        }
          
        .misc-content {
          line-height: 0;
            
          img {
            max-height: 465px;
          }
            
            &.blue-bg-text {
                background: #0A3047;
                max-width: 720px;
                padding: 90px 80px 90px 200px;
                
                .subtitle{
                  color: #ffffff;
                  font-size: 32px;
                  line-height: 140%;
                }
            }
        }

        &:nth-child(2) {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .container {
        
      .column-container {
        .two-column-content {
          gap: 45px;
            
          .misc-content {
            img {
              max-height: 330px;
            }

            &.blue-bg-text {
              padding: 60px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .container {
      padding: 0 20px;

      .content {
        .subheading {
          margin-top: 40px;

          .text {
            margin-top: 30px;
          }
        }
      }

      .column-container {
        .two-column-content {
          padding: 0;
          flex-direction: column;

          &.inverted {
            padding: 0;
            flex-direction: column;
          }

          .text-content {
            max-width: none;
          }
            
          .misc-content {
              img {
                  width: 100%;
                  max-height: none;
              }

              &.blue-bg-text {
                  padding: 60px;

                    .subtitle{
                        font-size: 24px;
                    }
              }
          }
        }
      }
    }
  }
`;

export default AboutNFTsStyled;
