/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useState } from 'react';
import { ContentStyled } from './style';
import { createShopifyTastingKitComponentSlider } from '../../utils/createShopifyComponent';
import { ShopifyJSContext } from '../../context/ShopifyJSContext';


function TastingKitsSubSlide({ subSlide }) {
  const [tastingKits, setTastingKits] = useState([]);
  const [pastTastingKits, setPastTastingKits] = useState([]);

  const { shopifyClient, shopifyUI } = useContext(ShopifyJSContext);

  const shopifyTastingKits = async () => {
    const ui = shopifyUI;

    const collectionId = 'gid://shopify/Collection/297730277558';
    const collection = await shopifyClient.collection.fetchWithProducts(
      collectionId, { productsFirst: 3 }
    );

    let currentTastingKits = [];
    let pastTastingKits = []

    if(subSlide.showTastingKits === 'current') {
      const currentTastingKit = collection.products.find((tastingKit) => {
        return tastingKit.availableForSale;
      });

      if (currentTastingKit !== undefined) {
        currentTastingKits = [currentTastingKit].map((p) => {
          const idSplit = p.id.split('/');
          return idSplit[idSplit.length - 1];
        });

        setTastingKits(currentTastingKits);
      } else {
        const currentTastingKit = collection.products[0];

        currentTastingKits = [currentTastingKit].map((p) => {
          const idSplit = p.id.split('/');
          return idSplit[idSplit.length - 1];
        });

        setTastingKits(currentTastingKits);
      }
    }

    if(subSlide.showTastingKits === 'past') {
      pastTastingKits = collection.products.find((tastingKit, index) => {
        if (index !== 0 && !tastingKit.availableForSale) {
          return tastingKit.id;
        }
      });

      if (pastTastingKits !== null && pastTastingKits !== undefined) {
        pastTastingKits = [pastTastingKits].map((p) => {
          const idSplit = p.id.split('/');
          return idSplit[idSplit.length - 1];
        });

        setPastTastingKits(pastTastingKits);
      }
    }

    setTimeout(() => {
      for (const kit of currentTastingKits) {
        createShopifyTastingKitComponentSlider(kit, ui, 'current');
      }
      for (const kit of pastTastingKits) {
        createShopifyTastingKitComponentSlider(kit, ui, 'past');
      }
    }, 500);
  };

  useEffect(() => {
    if (!!shopifyClient && !!shopifyUI) {
      void shopifyTastingKits();
    }
  }, [shopifyClient, shopifyUI]);

  return (
    <ContentStyled
      contentWidth={subSlide.contentWidth}
      fontSize={subSlide.fontSize}
      flexDirection='column'>

      {subSlide.showTastingKits === 'current' && tastingKits.map((id) => (
        <div
          key={id}
          id={`shopify-component-tastingkit-${id}`}
          className='shopify-tasting-kit-card'
        />
      ))}

      {subSlide.showTastingKits === 'past' && pastTastingKits.map((id) => (
        <div
          key={id}
          id={`shopify-component-tastingkit-${id}`}
          className='shopify-tasting-kit-card'
        />
      ))}
      
      {subSlide.pastTastingKitsBtn &&
        <a className='tasting-kits-button' target='_blank'
           href={subSlide.pastTastingKitsAddress}
           rel='noreferrer'>{subSlide.pastTastingKitsBtn}</a>}
    </ContentStyled>
  );
}

export default TastingKitsSubSlide;
