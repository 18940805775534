/* eslint-disable consistent-return */
/* eslint-disable no-debugger */
/* eslint-disable indent */
/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { makeAutoObservable } from 'mobx';
import Web3 from 'web3';
import Swal from 'sweetalert2';
import getCollectionData from '../../service/contract-details';
import getExaggeratedLimitInHex from '../../utils/web3-utils';

export default class ContractStore {
  rootStore;

  maxSupply;

  contractData = null;

  isSaleActive = false;

  mintedCount = 0;

  mintLimit = 0;

  contractJson;

  contractAddr;

  rpcURL;

  _userAddr;

  chainId;

  mintPriceTierOne;

  mintPriceTierTwo;

  mintPriceTierOnePlus;

  tierOneCount = 0;

  tierTwoCount = 0;

  tierOnePlusCount = 0;

  tokens = [];

  dollarRate = 0;

  currentProvider = null;

  mintResponse = null;

  mintReceipt = null;

  userMintedCount = 0;

  setUserMintedCount = (value) => {
    this.userMintedCount = value;
  };

  setMintResponse = (value) => {
    this.mintResponse = value;
  };

  setMintReceipt = (value) => {
    this.mintReceipt = value;
  };

  setCurrentProvider = (value) => {
    this.currentProvider = value;
  };

  setDollarRate = (value) => {
    this.dollarRate = value;
  };

  setChaindId(value) {
    this.chainId = value;
  }

  get userAddr() {
    return this._userAddr ?? this.rootStore.walletStore.userAccount;
  }

  set userAddr(value) {
    this._userAddr = value;
  }

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setSaleActive = (value) => {
    this.isSaleActive = value;
  };

  setMintedCount = (value) => {
    this.mintedCount = value;
  };

  setTierOnePlusCount = (value) => {
    this.tierOnePlusCount = value;
  };

  setTierOneCount = (value) => {
    this.tierOneCount = value;
  };

  setTierTwoCount = (value) => {
    this.tierTwoCount = value;
  };

  setMintLimit = (value) => {
    this.mintLimit = value;
  };

  setContractData = (value) => {
    this.contractData = value;
  };

  isSoldOut = () => this.mintedCount >= this.maxSupply;

  isExceededLimit = () => this.mintLimit <= 0;

  getReadableMintPrice(tier) {
    let price;
    if (tier === 'Tier 1') {
      price = this.mintPriceTierOne;
    } else if (tier === 'Tier 2') {
      price = this.mintPriceTierTwo;
    } else {
      price = this.mintPriceTierOnePlus;
    }
    return price / 1e18;
  }

  getCurrentProvider = () => this.currentProvider;

  getWeb3 = (useLocalProvider = false) => {
    let web3Provider;

    if (useLocalProvider) {
      web3Provider = new Web3(this.getCurrentProvider());
    } else {
      web3Provider = new Web3.providers.HttpProvider(this.rpcURL);
    }

    const web3Instance = new Web3(web3Provider);

    return web3Instance;
  };

  getContract = (contractJson, contractAddr, useLocalProvider = false) => {
    const web3Instance = this.getWeb3(useLocalProvider);
    web3Instance.eth.transactionBlockTimeout = 200;

    const contract = new web3Instance.eth.Contract(contractJson, contractAddr);
    return contract;
  };

  getTokensOfOwner = async (userAddr) => {
    if (userAddr) {
      const contractInstance = this.getMintContract(false);
      const tokens = await contractInstance.methods
        .tokensOfOwner(userAddr)
        .call();
      return tokens;
    }
    return [];
  };

  getMintContract = (useLocalProvider = false) =>
    this.getContract(this.contractJson, this.contractAddr, useLocalProvider);

  getMintLimit = async (contractInstance, contractData) => {
    if (contractData.max_per_wallet > 0) {
      return contractInstance.methods.getMintsLeft(this.userAddr).call();
    }
    return contractData.max_per_transaction;
  };

  getDataFromContract = async (contractData) => {
    const contractInstance = this.getMintContract(false);
    this.isSaleActive = await contractInstance.methods.isMintEnabled().call();
    this.mintLimit = await this.getMintLimit(contractInstance, contractData);
    this.mintedCount = parseInt(
      await contractInstance.methods.totalSupply().call(),
      10
    );

    [this.tierOneCount, this.tierTwoCount, this.tierOnePlusCount] =
      await contractInstance.methods.getTierCount().call();
    this.tokens = await contractInstance.methods
      .tokensOfOwner(this.userAddr)
      .call();

    this.userMintedCount = parseInt(
      await contractInstance.methods.userMintCount(this.userAddr).call()
    );
    this.setUserMintedCount(this.userMintedCount);
    this.fetchDollarRate();
  };

  getInitialData = async () => {
    const { contractData } = this;

    this.contractAddr = contractData.address;
    this.contractJson = contractData.abi;
    this.rpcURL = contractData.rpc_url;
    this.maxSupply = parseInt(contractData.total_supply, 10);

    await this.getDataFromContract(contractData);
  };

  fetchContractData = async (collection, successCb) => {
    let resp;
    try {
      if (collection === 'primebarrel') {
        resp = getCollectionData(collection);
      }
    } catch {
      return successCb(false);
    }

    if (resp.status === 200) {
      const { ...contract } = resp.data;
      this.mintPriceTierOne = parseInt(contract.mint_price_tier_one, 10);
      this.mintPriceTierTwo = parseInt(contract.mint_price_tier_two, 10);
      this.mintPriceTierOnePlus = parseInt(
        contract.mint_price_tier_one_plus,
        10
      );
      this.setContractData(contract);
      this.rootStore.walletStore.setChainId(this.contractData.chain);
      return successCb(true);
    }
    return successCb(false);
  };

  getTokens = () => [...this.tokens];

  isTransactionValid = (count) => {
    let message = null;
    if (!this.isSaleActive) {
      message = 'Sale is not active';
    } else if (this.mintedCount >= this.maxSupply) {
      message = 'All tokens are sold out.';
      // } else if (this.mintLimit === 0) {
      // message = 'You have exceeded your limit';
      // } else if (count > this.mintLimit) {
      // message = `Can't mint more than ${this.mintLimit} tokens`;
    } else if (count <= 0) {
      message = 'The selected amount should be atleast 1.';
    } else if (this.mintLimit + count > this.maxSupply) {
      message = 'Not enough dates to be claimed.';
    }

    return message;
  };

  doMint = (count, tier) => {
    this.getDataFromContract(this.contractData)
      .then(() => {
        this.doGassedMint(count, tier);
      })
      .catch((error) => {});
  };

  // GassedMint
  doGassedMint = (count, tier) => {
    if (this.userAddr) {
      const lWeb3Instance = this.getWeb3();

      const message = this.isTransactionValid(count);
      if (!message) {
        const contract = this.getMintContract(true);

        let estParams = {
          from: this.userAddr,
        };

        let mintPrice;
        if (tier === 'Tier 1') {
          mintPrice = this.mintPriceTierOne;
        } else if (tier === 'Tier 2') {
          mintPrice = this.mintPriceTierTwo;
        } else {
          mintPrice = this.mintPriceTierOnePlus;
        }
        let payableAmount;
        if (mintPrice) {
          payableAmount = (count * mintPrice).toString();
          estParams = {
            from: this.userAddr,
            value: payableAmount,
          };
        }
        let userMint;
        if (tier === 'Tier 1') {
          userMint = contract.methods.mintTier1;
        } else if (tier === 'Tier 2') {
          userMint = contract.methods.mintTier2;
        } else {
          userMint = contract.methods.mintTier1Plus;
        }
        userMint(this.userAddr)
          .estimateGas(estParams)
          .then((rawGaslimit) => {
            const exGasLimit = getExaggeratedLimitInHex(
              this.getCurrentProvider(),
              rawGaslimit
            );

            lWeb3Instance.eth.getGasPrice().then((rawGasPrice) => {
              // eslint-disable-next-line no-unused-vars
              const exGasPrice = getExaggeratedLimitInHex(
                this.getCurrentProvider(),
                rawGasPrice
              );

              let sendParams = {
                from: this.userAddr,
                gasLimit: exGasLimit,
                gasPrice: exGasPrice,
              };

              if (mintPrice) {
                sendParams = {
                  from: this.userAddr,
                  value: payableAmount,
                  gasLimit: exGasLimit,
                  gasPrice: exGasPrice,
                };
              }

              const transaction = userMint(this.userAddr).send(sendParams);

              transaction.on('error', (error, _receipt) => {
                // console.log({ error });
                const errorMessage = error.message.includes(
                  'User denied transaction signature.'
                )
                  ? 'Transaction failed: User denied transaction signature.'
                  : error.message;
                this.setMintResponse(errorMessage);
              });
              // eslint-disable-next-line no-unused-vars
              transaction.on('transactionHash', (txnHash) => {
                // console.log({ txnHash });
              });

              transaction.on('receipt', (receipt) => {
                // console.log({ receipt });
                this.setMintReceipt(receipt);
                this.getDataFromContract(this.contractData);
              });
            });
          })
          .catch((error) => {
            const errorMessage = error.message.includes('exceeds max mint')
              ? 'Transaction failed: You have reached the maximum limit of memberships for your account.'
              : error.message.includes('insufficient funds')
              ? 'Transaction failed: Insufficient Balance'
              : error.message.includes('not in waitlist')
              ? 'Transaction failed: not in waitlist'
              : error.message.includes("can't mint 0 nfts")
              ? "Transaction failed: can't mint 0 nfts"
              : error.message;
            // console.log(errorMessage);
            this.setMintResponse(errorMessage);
            this.getDataFromContract(this.contractData);
          });
      } else {
        // console.log({ message });
        this.setMintResponse(message);
        this.getDataFromContract(this.contractData);
      }
    }
  };

  // ETH to USD
  fetchDollarRate = () => {
    fetch(
      'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum'
    ).then((response) => {
      response.json().then((data) => {
        if (data[0].current_price) {
          this.setDollarRate(parseInt(data[0].current_price));
        }
      });
    });
  };
}
