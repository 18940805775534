/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

const YoutubePlayerStyled = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    font-family: 'Century Gothic BO', sans-serif;
    font-weight: 700;
    font-size: 12px;
    
    .player-container {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    iframe {
        margin: 0 !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100%;
    }
    
    img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    svg {
        width: 64px;
        height: 64px;
        top: 50%;
        left: 50%;
        pointer-events: none;
        transform: translate(-50%, -50%);
        position: absolute;
    }
`;

export { YoutubePlayerStyled };
