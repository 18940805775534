import MetamaskLogo from '../assets/metamask-icon.png';
import CoinbaseLogo from '../assets/coinbase.png';

const getAllProviders = () => {
  const { ethereum } = window;

  const providers = ethereum ? (ethereum.providers ? ethereum.providers : [ethereum]) : [];

  const providersWithNames = [
    {
      name: 'metamask',
      wagmiName: 'MetaMask',
      wallet: providers.find((p) => p.isMetaMask && !p.isBraveWallet),
      logo: MetamaskLogo,
      installed: !!providers.find((p) => p.isMetaMask && !p.isBraveWallet),
      installUrl: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
      androidUrl: 'https://play.google.com/store/apps/details?id=io.metamask&hl=en_IN&gl=US',
      iphoneUrl: 'https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202',
    },
    {
      name: 'coinbase',
      wagmiName: 'Coinbase Wallet',
      wallet: providers.find((p) => p.isCoinbaseWallet),
      logo: CoinbaseLogo,
      installed: !!providers.find((p) => p.isCoinbaseWallet),
      installUrl: 'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en',
      androidUrl: 'https://play.google.com/store/apps/details?id=org.toshi&hl=en_IN&gl=US',
      iphoneUrl: 'https://apps.apple.com/us/app/coinbase-wallet-nfts-crypto/id1278383455',
    },
    {
      name: 'coinbase',
      wagmiName: 'Injected',
      wallet: providers.find((p) => p.isCoinbaseWallet),
      logo: CoinbaseLogo,
      installed: !!providers.find((p) => p.isCoinbaseWallet),
      installUrl: 'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en',
      androidUrl: 'https://play.google.com/store/apps/details?id=org.toshi&hl=en_IN&gl=US',
      iphoneUrl: 'https://apps.apple.com/us/app/coinbase-wallet-nfts-crypto/id1278383455',
    },
  ];
  return providersWithNames;
};

const getProvider = (name) => {
  const providers = getAllProviders();
  const provider = providers.find((p) => p.name === name);
  return provider || null;
};

export { getAllProviders, getProvider };
