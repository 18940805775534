import React, { useRef } from 'react';
import { YoutubePlayerStyled } from './style';
import { ReactComponent as PlayIcon } from '../../assets/reelVideo/play.svg';

function VimeoPlayer({ videoId, className }) {
  const playerContainer = useRef(null);
  const [showPlayButton, setShowPlayButton] = React.useState(true);

  const loadScript = () => {
    if (!window.YT) { // If not, load the script asynchronously
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = loadVideo;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    } else { // If script is already there, load the video directly
      loadVideo();
    }
  }

  const loadVideo = () => {
    new window.YT.Player(playerContainer.current, {
      videoId: videoId,
      events: {
        onReady: onPlayerReady,
      },
    });
  };

  const onPlayerReady = event => {
    event.target.playVideo();
    setShowPlayButton(false);
  };

  return (
    <YoutubePlayerStyled>
      <div className={`player-container ${className}`}>
        <div ref={playerContainer}></div>
        {showPlayButton? (
          <>
            <PlayIcon />
            <img onClick={loadScript} src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} alt='Youtube video' />
          </>
        ) : null}
      </div>
    </YoutubePlayerStyled>
  )
    ;
}

export default VimeoPlayer
;
